.login_bg {
  background: url(https://www.wrappixel.com/demos/admin-templates/materialpro-bootstrap-latest/material-pro/src/assets/images/background/login-register.jpg)
    no-repeat center center;
  background-size: cover;
}
.social {
  display: flex;
  /* justify-content: space-evenly; */
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  filter: grayscale(80%);
}
.social:before,
.social:after {
  content: '';
  display: block;
}
.center-title {
  color: white;
  text-align: center;
}
.center-box {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #1e88e5;
  --indigo: #6610f2;
  --purple: #7460ee;
  --pink: #e83e8c;
  --red: #fc4b6c;
  --orange: #fb8c00;
  --yellow: #ffb22b;
  --green: #21c1d6;
  --teal: #20c997;
  --cyan: #26c6da;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --blue: #1e88e5;
  --indigo: #6610f2;
  --purple: #7460ee;
  --pink: #e83e8c;
  --red: #fc4b6c;
  --orange: #fb8c00;
  --yellow: #ffb22b;
  --green: #21c1d6;
  --teal: #20c997;
  --cyan: #26c6da;
  --white: #fff;
  --gray: #6c757d;
  --primary: #7460ee;
  --secondary: #6c757d;
  --success: #21c1d6;
  --info: #1e88e5;
  --warning: #ffb22b;
  --danger: #fc4b6c;
  --light: #f2f4f8;
  --dark: #343a40;
  --cyan: #26c6da;
  --orange: #fb8c00;
  --purple: #7460ee;
  --inverse: #2f3d4a;
  --megna: #01c0c8;
  --light-danger: #f9e7eb;
  --light-success: #e8fdeb;
  --light-warning: #fff8ec;
  --light-primary: #f1effd;
  --light-info: #cfecfe;
  --light-inverse: #f6f6f6;
  --light-megna: #e0f2f4;
  --dark-danger: #e6294b;
  --dark-success: #1eacbe;
  --dark-warning: #e9ab2e;
  --dark-primary: #6352ce;
  --dark-info: #028ee1;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1600px;
}

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: .875rem;
  font-weight: 300;
  line-height: 1.5;
  color: #67757c;
  text-align: left;
  background-color: #eef5f9;
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

/* button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
} */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5rem;
  font-weight: 400;
  line-height: 1.2;
  color: #455a64;
}

.h1,
h1 {
  font-size: 2.25rem;
}

.h2,
h2 {
  font-size: 1.5rem;
}

.h3,
h3 {
  font-size: 1.3125rem;
}

.h4,
h4 {
  font-size: 1.125rem;
}

.h5,
h5 {
  font-size: 1rem;
}

.h6,
h6 {
  font-size: .875rem;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1600px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #67757c;
}

.table td,
.table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #e8eef3;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e8eef3;
}

.table tbody + tbody {
  border-top: 2px solid #e8eef3;
}

.table-sm td,
.table-sm th {
  padding: .3rem;
}

.table-bordered {
  border: 1px solid #e8eef3;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #e8eef3;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.table-hover tbody tr:hover {
  color: #67757c;
  background-color: #f2f4f8;
}

.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #e3dffc;
}

.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #b7acf6;
}

.table-hover .table-primary:hover {
  background-color: #cfc8fa;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #cfc8fa;
}

.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #e2e3e5;
}

.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #d5d6d9;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #d5d6d9;
}

.table-success,
.table-success > td,
.table-success > th {
  background-color: #d3f3f7;
}

.table-success tbody + tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #8cdfea;
}

.table-hover .table-success:hover {
  background-color: #bdedf3;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #bdedf3;
}

.table-info,
.table-info > td,
.table-info > th {
  background-color: #d2e7fa;
}

.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #8ac1f1;
}

.table-hover .table-info:hover {
  background-color: #bbdbf7;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #bbdbf7;
}

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #fff0d5;
}

.table-warning tbody + tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #ffd791;
}

.table-hover .table-warning:hover {
  background-color: #ffe7bc;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe7bc;
}

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #fedbe2;
}

.table-danger tbody + tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #fda1b3;
}

.table-hover .table-danger:hover {
  background-color: #fdc2ce;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fdc2ce;
}

.table-light,
.table-light > td,
.table-light > th {
  background-color: #fcfdfe;
}

.table-light tbody + tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #f8f9fb;
}

.table-hover .table-light:hover {
  background-color: #e9f0f8;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e9f0f8;
}

.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #d6d8d9;
}

.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #c9cbcd;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #c9cbcd;
}

.table-cyan,
.table-cyan > td,
.table-cyan > th {
  background-color: #d4f4f8;
}

.table-cyan tbody + tbody,
.table-cyan td,
.table-cyan th,
.table-cyan thead th {
  border-color: #8ee1ec;
}

.table-hover .table-cyan:hover {
  background-color: #beeef4;
}

.table-hover .table-cyan:hover > td,
.table-hover .table-cyan:hover > th {
  background-color: #beeef4;
}

.table-orange,
.table-orange > td,
.table-orange > th {
  background-color: #fee8cc;
}

.table-orange tbody + tbody,
.table-orange td,
.table-orange th,
.table-orange thead th {
  border-color: #fdc37a;
}

.table-hover .table-orange:hover {
  background-color: #feddb3;
}

.table-hover .table-orange:hover > td,
.table-hover .table-orange:hover > th {
  background-color: #feddb3;
}

.table-purple,
.table-purple > td,
.table-purple > th {
  background-color: #e3dffc;
}

.table-purple tbody + tbody,
.table-purple td,
.table-purple th,
.table-purple thead th {
  border-color: #b7acf6;
}

.table-hover .table-purple:hover {
  background-color: #cfc8fa;
}

.table-hover .table-purple:hover > td,
.table-hover .table-purple:hover > th {
  background-color: #cfc8fa;
}

.table-inverse,
.table-inverse > td,
.table-inverse > th {
  background-color: #d5d8db;
}

.table-inverse tbody + tbody,
.table-inverse td,
.table-inverse th,
.table-inverse thead th {
  border-color: #939aa1;
}

.table-hover .table-inverse:hover {
  background-color: #c7cbcf;
}

.table-hover .table-inverse:hover > td,
.table-hover .table-inverse:hover > th {
  background-color: #c7cbcf;
}

.table-megna,
.table-megna > td,
.table-megna > th {
  background-color: #ccf2f4;
}

.table-megna tbody + tbody,
.table-megna td,
.table-megna th,
.table-megna thead th {
  border-color: #7bdee2;
}

.table-hover .table-megna:hover {
  background-color: #b7edef;
}

.table-hover .table-megna:hover > td,
.table-hover .table-megna:hover > th {
  background-color: #b7edef;
}

.table-light-danger,
.table-light-danger > td,
.table-light-danger > th {
  background-color: #fefafb;
}

.table-light-danger tbody + tbody,
.table-light-danger td,
.table-light-danger th,
.table-light-danger thead th {
  border-color: #fcf3f5;
}

.table-hover .table-light-danger:hover {
  background-color: #fae5ea;
}

.table-hover .table-light-danger:hover > td,
.table-hover .table-light-danger:hover > th {
  background-color: #fae5ea;
}

.table-light-success,
.table-light-success > td,
.table-light-success > th {
  background-color: #fafffb;
}

.table-light-success tbody + tbody,
.table-light-success td,
.table-light-success th,
.table-light-success thead th {
  border-color: #f3fef5;
}

.table-hover .table-light-success:hover {
  background-color: #e1ffe7;
}

.table-hover .table-light-success:hover > td,
.table-hover .table-light-success:hover > th {
  background-color: #e1ffe7;
}

.table-light-warning,
.table-light-warning > td,
.table-light-warning > th {
  background-color: #fffefb;
}

.table-light-warning tbody + tbody,
.table-light-warning td,
.table-light-warning th,
.table-light-warning thead th {
  border-color: #fffbf5;
}

.table-hover .table-light-warning:hover {
  background-color: #fff8e2;
}

.table-hover .table-light-warning:hover > td,
.table-hover .table-light-warning:hover > th {
  background-color: #fff8e2;
}

.table-light-primary,
.table-light-primary > td,
.table-light-primary > th {
  background-color: #fcfcff;
}

.table-light-primary tbody + tbody,
.table-light-primary td,
.table-light-primary th,
.table-light-primary thead th {
  border-color: #f8f7fe;
}

.table-hover .table-light-primary:hover {
  background-color: #e3e3ff;
}

.table-hover .table-light-primary:hover > td,
.table-hover .table-light-primary:hover > th {
  background-color: #e3e3ff;
}

.table-light-info,
.table-light-info > td,
.table-light-info > th {
  background-color: #f5fbff;
}

.table-light-info tbody + tbody,
.table-light-info td,
.table-light-info th,
.table-light-info thead th {
  border-color: #e6f5fe;
}

.table-hover .table-light-info:hover {
  background-color: #dcf1ff;
}

.table-hover .table-light-info:hover > td,
.table-hover .table-light-info:hover > th {
  background-color: #dcf1ff;
}

.table-light-inverse,
.table-light-inverse > td,
.table-light-inverse > th {
  background-color: #fdfdfd;
}

.table-light-inverse tbody + tbody,
.table-light-inverse td,
.table-light-inverse th,
.table-light-inverse thead th {
  border-color: #fafafa;
}

.table-hover .table-light-inverse:hover {
  background-color: #f0f0f0;
}

.table-hover .table-light-inverse:hover > td,
.table-hover .table-light-inverse:hover > th {
  background-color: #f0f0f0;
}

.table-light-megna,
.table-light-megna > td,
.table-light-megna > th {
  background-color: #f9fcfd;
}

.table-light-megna tbody + tbody,
.table-light-megna td,
.table-light-megna th,
.table-light-megna thead th {
  border-color: #eff8f9;
}

.table-hover .table-light-megna:hover {
  background-color: #e6f2f7;
}

.table-hover .table-light-megna:hover > td,
.table-hover .table-light-megna:hover > th {
  background-color: #e6f2f7;
}

.table-dark-danger,
.table-dark-danger > td,
.table-dark-danger > th {
  background-color: #fad4db;
}

.table-dark-danger tbody + tbody,
.table-dark-danger td,
.table-dark-danger th,
.table-dark-danger thead th {
  border-color: #f290a1;
}

.table-hover .table-dark-danger:hover {
  background-color: #f7bdc8;
}

.table-hover .table-dark-danger:hover > td,
.table-hover .table-dark-danger:hover > th {
  background-color: #f7bdc8;
}

.table-dark-success,
.table-dark-success > td,
.table-dark-success > th {
  background-color: #d2eef2;
}

.table-dark-success tbody + tbody,
.table-dark-success td,
.table-dark-success th,
.table-dark-success thead th {
  border-color: #8ad4dd;
}

.table-hover .table-dark-success:hover {
  background-color: #bee7ec;
}

.table-hover .table-dark-success:hover > td,
.table-hover .table-dark-success:hover > th {
  background-color: #bee7ec;
}

.table-dark-warning,
.table-dark-warning > td,
.table-dark-warning > th {
  background-color: #fbeed5;
}

.table-dark-warning tbody + tbody,
.table-dark-warning td,
.table-dark-warning th,
.table-dark-warning thead th {
  border-color: #f4d392;
}

.table-hover .table-dark-warning:hover {
  background-color: #f9e5be;
}

.table-hover .table-dark-warning:hover > td,
.table-hover .table-dark-warning:hover > th {
  background-color: #f9e5be;
}

.table-dark-primary,
.table-dark-primary > td,
.table-dark-primary > th {
  background-color: #e0dcf5;
}

.table-dark-primary tbody + tbody,
.table-dark-primary td,
.table-dark-primary th,
.table-dark-primary thead th {
  border-color: #aea5e6;
}

.table-hover .table-dark-primary:hover {
  background-color: #cec8ef;
}

.table-hover .table-dark-primary:hover > td,
.table-hover .table-dark-primary:hover > th {
  background-color: #cec8ef;
}

.table-dark-info,
.table-dark-info > td,
.table-dark-info > th {
  background-color: #cce8f9;
}

.table-dark-info tbody + tbody,
.table-dark-info td,
.table-dark-info th,
.table-dark-info thead th {
  border-color: #7bc4ef;
}

.table-hover .table-dark-info:hover {
  background-color: #b5def6;
}

.table-hover .table-dark-info:hover > td,
.table-hover .table-dark-info:hover > th {
  background-color: #b5def6;
}

.table-active,
.table-active > td,
.table-active > th {
  background-color: #f2f4f8;
}

.table-hover .table-active:hover {
  background-color: #e1e6ef;
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #e1e6ef;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #4f5467;
  background-color: #e9ecef;
  border-color: #e8eef3;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, .05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, .075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1599.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #54667a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #54667a;
}

.form-control:focus {
  color: #54667a;
  background-color: #fff;
  border-color: rgba(0, 0, 0, .25);
  outline: 0;
  box-shadow: transparent;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #54667a;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .76563rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: .375rem 0;
  margin-bottom: 0;
  font-size: .875rem;
  line-height: 1.5;
  color: #67757c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .76563rem;
  line-height: 1.5;
  border-radius: 1px;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 5px;
}

select.form-control[multiple],
select.form-control[size] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: .25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  color: #a1aab2;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #21c1d6;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .76563rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(33, 193, 214, .9);
  border-radius: 4px;
}

.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #21c1d6;
  padding-right: calc(1.5em + .75rem);
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #21c1d6;
  box-shadow: 0 0 0 .2rem rgba(33, 193, 214, .25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + .75rem);
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #21c1d6;
  box-shadow: 0 0 0 .2rem rgba(33, 193, 214, .25);
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #21c1d6;
}

.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #21c1d6;
}

.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: #21c1d6;
}

.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  border-color: #47d1e3;
  background-color: #47d1e3;
}

.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 .2rem rgba(33, 193, 214, .25);
}

.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before,
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #21c1d6;
}

.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #21c1d6;
}

.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #21c1d6;
  box-shadow: 0 0 0 .2rem rgba(33, 193, 214, .25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #fc4b6c;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .76563rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(252, 75, 108, .9);
  border-radius: 4px;
}

.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #fc4b6c;
  padding-right: calc(1.5em + .75rem);
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #fc4b6c;
  box-shadow: 0 0 0 .2rem rgba(252, 75, 108, .25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + .75rem);
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #fc4b6c;
  padding-right: calc(.75em + 2.3125rem);
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #fc4b6c;
  box-shadow: 0 0 0 .2rem rgba(252, 75, 108, .25);
}

.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #fc4b6c;
}

.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #fc4b6c;
}

.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: #fc4b6c;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
  border-color: #fd7d95;
  background-color: #fd7d95;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 .2rem rgba(252, 75, 108, .25);
}

.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #fc4b6c;
}

.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #fc4b6c;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #fc4b6c;
  box-shadow: 0 0 0 .2rem rgba(252, 75, 108, .25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #67757c;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 4px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #67757c;
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: transparent;
}

.btn.disabled,
.btn:disabled {
  opacity: .65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-primary:hover {
  color: #fff;
  background-color: #563dea;
  border-color: #4c32e9;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #563dea;
  border-color: #4c32e9;
  box-shadow: 0 0 0 .2rem rgba(137, 120, 241, .5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #4c32e9;
  border-color: #4226e8;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(137, 120, 241, .5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5);
}

.btn-success {
  color: #fff;
  background-color: #21c1d6;
  border-color: #21c1d6;
}

.btn-success:hover {
  color: #fff;
  background-color: #1ca3b5;
  border-color: #1a99aa;
}

.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #1ca3b5;
  border-color: #1a99aa;
  box-shadow: 0 0 0 .2rem rgba(66, 202, 220, .5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #21c1d6;
  border-color: #21c1d6;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1a99aa;
  border-color: #188f9f;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(66, 202, 220, .5);
}

.btn-info {
  color: #fff;
  background-color: #1e88e5;
  border-color: #1e88e5;
}

.btn-info:hover {
  color: #fff;
  background-color: #1774c6;
  border-color: #166dba;
}

.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #1774c6;
  border-color: #166dba;
  box-shadow: 0 0 0 .2rem rgba(64, 154, 233, .5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #1e88e5;
  border-color: #1e88e5;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #166dba;
  border-color: #1467af;
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(64, 154, 233, .5);
}

.btn-warning {
  color: #212529;
  background-color: #ffb22b;
  border-color: #ffb22b;
}

.btn-warning:hover {
  color: #212529;
  background-color: #ffa405;
  border-color: #f79d00;
}

.btn-warning.focus,
.btn-warning:focus {
  color: #212529;
  background-color: #ffa405;
  border-color: #f79d00;
  box-shadow: 0 0 0 .2rem rgba(222, 157, 43, .5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffb22b;
  border-color: #ffb22b;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #f79d00;
  border-color: #ea9500;
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(222, 157, 43, .5);
}

.btn-danger {
  color: #fff;
  background-color: #fc4b6c;
  border-color: #fc4b6c;
}

.btn-danger:hover {
  color: #fff;
  background-color: #fb254d;
  border-color: #fb1943;
}

.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #fb254d;
  border-color: #fb1943;
  box-shadow: 0 0 0 .2rem rgba(252, 102, 130, .5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #fc4b6c;
  border-color: #fc4b6c;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #fb1943;
  border-color: #fb0c39;
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(252, 102, 130, .5);
}

.btn-light {
  color: #212529;
  background-color: #f2f4f8;
  border-color: #f2f4f8;
}

.btn-light:hover {
  color: #212529;
  background-color: #d9dfeb;
  border-color: #d1d8e6;
}

.btn-light.focus,
.btn-light:focus {
  color: #212529;
  background-color: #d9dfeb;
  border-color: #d1d8e6;
  box-shadow: 0 0 0 .2rem rgba(211, 213, 217, .5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f2f4f8;
  border-color: #f2f4f8;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #d1d8e6;
  border-color: #c9d1e2;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(211, 213, 217, .5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark.focus,
.btn-dark:focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5);
}

.btn-cyan {
  color: #212529;
  background-color: #26c6da;
  border-color: #26c6da;
}

.btn-cyan:hover {
  color: #fff;
  background-color: #20a9ba;
  border-color: #1e9faf;
}

.btn-cyan.focus,
.btn-cyan:focus {
  color: #fff;
  background-color: #20a9ba;
  border-color: #1e9faf;
  box-shadow: 0 0 0 .2rem rgba(37, 174, 191, .5);
}

.btn-cyan.disabled,
.btn-cyan:disabled {
  color: #212529;
  background-color: #26c6da;
  border-color: #26c6da;
}

.btn-cyan:not(:disabled):not(.disabled).active,
.btn-cyan:not(:disabled):not(.disabled):active,
.show > .btn-cyan.dropdown-toggle {
  color: #fff;
  background-color: #1e9faf;
  border-color: #1c95a4;
}

.btn-cyan:not(:disabled):not(.disabled).active:focus,
.btn-cyan:not(:disabled):not(.disabled):active:focus,
.show > .btn-cyan.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(37, 174, 191, .5);
}

.btn-orange {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}

.btn-orange:hover {
  color: #fff;
  background-color: #d57700;
  border-color: #c87000;
}

.btn-orange.focus,
.btn-orange:focus {
  color: #fff;
  background-color: #d57700;
  border-color: #c87000;
  box-shadow: 0 0 0 .2rem rgba(218, 125, 6, .5);
}

.btn-orange.disabled,
.btn-orange:disabled {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}

.btn-orange:not(:disabled):not(.disabled).active,
.btn-orange:not(:disabled):not(.disabled):active,
.show > .btn-orange.dropdown-toggle {
  color: #fff;
  background-color: #c87000;
  border-color: #bb6800;
}

.btn-orange:not(:disabled):not(.disabled).active:focus,
.btn-orange:not(:disabled):not(.disabled):active:focus,
.show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(218, 125, 6, .5);
}

.btn-purple {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-purple:hover {
  color: #fff;
  background-color: #563dea;
  border-color: #4c32e9;
}

.btn-purple.focus,
.btn-purple:focus {
  color: #fff;
  background-color: #563dea;
  border-color: #4c32e9;
  box-shadow: 0 0 0 .2rem rgba(137, 120, 241, .5);
}

.btn-purple.disabled,
.btn-purple:disabled {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-purple:not(:disabled):not(.disabled).active,
.btn-purple:not(:disabled):not(.disabled):active,
.show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #4c32e9;
  border-color: #4226e8;
}

.btn-purple:not(:disabled):not(.disabled).active:focus,
.btn-purple:not(:disabled):not(.disabled):active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(137, 120, 241, .5);
}

.btn-inverse {
  color: #fff;
  background-color: #2f3d4a;
  border-color: #2f3d4a;
}

.btn-inverse:hover {
  color: #fff;
  background-color: #202a33;
  border-color: #1b232b;
}

.btn-inverse.focus,
.btn-inverse:focus {
  color: #fff;
  background-color: #202a33;
  border-color: #1b232b;
  box-shadow: 0 0 0 .2rem rgba(78, 90, 101, .5);
}

.btn-inverse.disabled,
.btn-inverse:disabled {
  color: #fff;
  background-color: #2f3d4a;
  border-color: #2f3d4a;
}

.btn-inverse:not(:disabled):not(.disabled).active,
.btn-inverse:not(:disabled):not(.disabled):active,
.show > .btn-inverse.dropdown-toggle {
  color: #fff;
  background-color: #1b232b;
  border-color: #161d23;
}

.btn-inverse:not(:disabled):not(.disabled).active:focus,
.btn-inverse:not(:disabled):not(.disabled):active:focus,
.show > .btn-inverse.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(78, 90, 101, .5);
}

.btn-megna {
  color: #fff;
  background-color: #01c0c8;
  border-color: #01c0c8;
}

.btn-megna:hover {
  color: #fff;
  background-color: #019ba2;
  border-color: #018f95;
}

.btn-megna.focus,
.btn-megna:focus {
  color: #fff;
  background-color: #019ba2;
  border-color: #018f95;
  box-shadow: 0 0 0 .2rem rgba(39, 201, 208, .5);
}

.btn-megna.disabled,
.btn-megna:disabled {
  color: #fff;
  background-color: #01c0c8;
  border-color: #01c0c8;
}

.btn-megna:not(:disabled):not(.disabled).active,
.btn-megna:not(:disabled):not(.disabled):active,
.show > .btn-megna.dropdown-toggle {
  color: #fff;
  background-color: #018f95;
  border-color: #018389;
}

.btn-megna:not(:disabled):not(.disabled).active:focus,
.btn-megna:not(:disabled):not(.disabled):active:focus,
.show > .btn-megna.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(39, 201, 208, .5);
}

.btn-light-danger {
  color: #212529;
  background-color: #f9e7eb;
  border-color: #f9e7eb;
}

.btn-light-danger:hover {
  color: #212529;
  background-color: #f1c8d2;
  border-color: #efbec9;
}

.btn-light-danger.focus,
.btn-light-danger:focus {
  color: #212529;
  background-color: #f1c8d2;
  border-color: #efbec9;
  box-shadow: 0 0 0 .2rem rgba(217, 202, 206, .5);
}

.btn-light-danger.disabled,
.btn-light-danger:disabled {
  color: #212529;
  background-color: #f9e7eb;
  border-color: #f9e7eb;
}

.btn-light-danger:not(:disabled):not(.disabled).active,
.btn-light-danger:not(:disabled):not(.disabled):active,
.show > .btn-light-danger.dropdown-toggle {
  color: #212529;
  background-color: #efbec9;
  border-color: #ecb4c1;
}

.btn-light-danger:not(:disabled):not(.disabled).active:focus,
.btn-light-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-light-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(217, 202, 206, .5);
}

.btn-light-success {
  color: #212529;
  background-color: #e8fdeb;
  border-color: #e8fdeb;
}

.btn-light-success:hover {
  color: #212529;
  background-color: #c5facc;
  border-color: #b9f9c2;
}

.btn-light-success.focus,
.btn-light-success:focus {
  color: #212529;
  background-color: #c5facc;
  border-color: #b9f9c2;
  box-shadow: 0 0 0 .2rem rgba(202, 221, 206, .5);
}

.btn-light-success.disabled,
.btn-light-success:disabled {
  color: #212529;
  background-color: #e8fdeb;
  border-color: #e8fdeb;
}

.btn-light-success:not(:disabled):not(.disabled).active,
.btn-light-success:not(:disabled):not(.disabled):active,
.show > .btn-light-success.dropdown-toggle {
  color: #212529;
  background-color: #b9f9c2;
  border-color: #adf8b8;
}

.btn-light-success:not(:disabled):not(.disabled).active:focus,
.btn-light-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-light-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(202, 221, 206, .5);
}

.btn-light-warning {
  color: #212529;
  background-color: #fff8ec;
  border-color: #fff8ec;
}

.btn-light-warning:hover {
  color: #212529;
  background-color: #ffeac6;
  border-color: #ffe5b9;
}

.btn-light-warning.focus,
.btn-light-warning:focus {
  color: #212529;
  background-color: #ffeac6;
  border-color: #ffe5b9;
  box-shadow: 0 0 0 .2rem rgba(222, 216, 207, .5);
}

.btn-light-warning.disabled,
.btn-light-warning:disabled {
  color: #212529;
  background-color: #fff8ec;
  border-color: #fff8ec;
}

.btn-light-warning:not(:disabled):not(.disabled).active,
.btn-light-warning:not(:disabled):not(.disabled):active,
.show > .btn-light-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffe5b9;
  border-color: #ffe1ac;
}

.btn-light-warning:not(:disabled):not(.disabled).active:focus,
.btn-light-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-light-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(222, 216, 207, .5);
}

.btn-light-primary {
  color: #212529;
  background-color: #f1effd;
  border-color: #f1effd;
}

.btn-light-primary:hover {
  color: #212529;
  background-color: #d3cdf9;
  border-color: #c9c2f7;
}

.btn-light-primary.focus,
.btn-light-primary:focus {
  color: #212529;
  background-color: #d3cdf9;
  border-color: #c9c2f7;
  box-shadow: 0 0 0 .2rem rgba(210, 209, 221, .5);
}

.btn-light-primary.disabled,
.btn-light-primary:disabled {
  color: #212529;
  background-color: #f1effd;
  border-color: #f1effd;
}

.btn-light-primary:not(:disabled):not(.disabled).active,
.btn-light-primary:not(:disabled):not(.disabled):active,
.show > .btn-light-primary.dropdown-toggle {
  color: #212529;
  background-color: #c9c2f7;
  border-color: #bfb6f6;
}

.btn-light-primary:not(:disabled):not(.disabled).active:focus,
.btn-light-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-light-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(210, 209, 221, .5);
}

.btn-light-info {
  color: #212529;
  background-color: #cfecfe;
  border-color: #cfecfe;
}

.btn-light-info:hover {
  color: #212529;
  background-color: #aaddfd;
  border-color: #9dd8fd;
}

.btn-light-info.focus,
.btn-light-info:focus {
  color: #212529;
  background-color: #aaddfd;
  border-color: #9dd8fd;
  box-shadow: 0 0 0 .2rem rgba(181, 206, 222, .5);
}

.btn-light-info.disabled,
.btn-light-info:disabled {
  color: #212529;
  background-color: #cfecfe;
  border-color: #cfecfe;
}

.btn-light-info:not(:disabled):not(.disabled).active,
.btn-light-info:not(:disabled):not(.disabled):active,
.show > .btn-light-info.dropdown-toggle {
  color: #212529;
  background-color: #9dd8fd;
  border-color: #91d3fd;
}

.btn-light-info:not(:disabled):not(.disabled).active:focus,
.btn-light-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-light-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(181, 206, 222, .5);
}

.btn-light-inverse {
  color: #212529;
  background-color: #f6f6f6;
  border-color: #f6f6f6;
}

.btn-light-inverse:hover {
  color: #212529;
  background-color: #e3e3e3;
  border-color: #ddd;
}

.btn-light-inverse.focus,
.btn-light-inverse:focus {
  color: #212529;
  background-color: #e3e3e3;
  border-color: #ddd;
  box-shadow: 0 0 0 .2rem rgba(214, 215, 215, .5);
}

.btn-light-inverse.disabled,
.btn-light-inverse:disabled {
  color: #212529;
  background-color: #f6f6f6;
  border-color: #f6f6f6;
}

.btn-light-inverse:not(:disabled):not(.disabled).active,
.btn-light-inverse:not(:disabled):not(.disabled):active,
.show > .btn-light-inverse.dropdown-toggle {
  color: #212529;
  background-color: #ddd;
  border-color: #d6d6d6;
}

.btn-light-inverse:not(:disabled):not(.disabled).active:focus,
.btn-light-inverse:not(:disabled):not(.disabled):active:focus,
.show > .btn-light-inverse.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(214, 215, 215, .5);
}

.btn-light-megna {
  color: #212529;
  background-color: #e0f2f4;
  border-color: #e0f2f4;
}

.btn-light-megna:hover {
  color: #212529;
  background-color: #c4e6ea;
  border-color: #bae2e7;
}

.btn-light-megna.focus,
.btn-light-megna:focus {
  color: #212529;
  background-color: #c4e6ea;
  border-color: #bae2e7;
  box-shadow: 0 0 0 .2rem rgba(195, 211, 214, .5);
}

.btn-light-megna.disabled,
.btn-light-megna:disabled {
  color: #212529;
  background-color: #e0f2f4;
  border-color: #e0f2f4;
}

.btn-light-megna:not(:disabled):not(.disabled).active,
.btn-light-megna:not(:disabled):not(.disabled):active,
.show > .btn-light-megna.dropdown-toggle {
  color: #212529;
  background-color: #bae2e7;
  border-color: #b1dee3;
}

.btn-light-megna:not(:disabled):not(.disabled).active:focus,
.btn-light-megna:not(:disabled):not(.disabled):active:focus,
.show > .btn-light-megna.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(195, 211, 214, .5);
}

.btn-dark-danger {
  color: #fff;
  background-color: #e6294b;
  border-color: #e6294b;
}

.btn-dark-danger:hover {
  color: #fff;
  background-color: #d01839;
  border-color: #c51736;
}

.btn-dark-danger.focus,
.btn-dark-danger:focus {
  color: #fff;
  background-color: #d01839;
  border-color: #c51736;
  box-shadow: 0 0 0 .2rem rgba(234, 73, 102, .5);
}

.btn-dark-danger.disabled,
.btn-dark-danger:disabled {
  color: #fff;
  background-color: #e6294b;
  border-color: #e6294b;
}

.btn-dark-danger:not(:disabled):not(.disabled).active,
.btn-dark-danger:not(:disabled):not(.disabled):active,
.show > .btn-dark-danger.dropdown-toggle {
  color: #fff;
  background-color: #c51736;
  border-color: #ba1633;
}

.btn-dark-danger:not(:disabled):not(.disabled).active:focus,
.btn-dark-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(234, 73, 102, .5);
}

.btn-dark-success {
  color: #fff;
  background-color: #1eacbe;
  border-color: #1eacbe;
}

.btn-dark-success:hover {
  color: #fff;
  background-color: #198e9d;
  border-color: #178492;
}

.btn-dark-success.focus,
.btn-dark-success:focus {
  color: #fff;
  background-color: #198e9d;
  border-color: #178492;
  box-shadow: 0 0 0 .2rem rgba(64, 184, 200, .5);
}

.btn-dark-success.disabled,
.btn-dark-success:disabled {
  color: #fff;
  background-color: #1eacbe;
  border-color: #1eacbe;
}

.btn-dark-success:not(:disabled):not(.disabled).active,
.btn-dark-success:not(:disabled):not(.disabled):active,
.show > .btn-dark-success.dropdown-toggle {
  color: #fff;
  background-color: #178492;
  border-color: #157a87;
}

.btn-dark-success:not(:disabled):not(.disabled).active:focus,
.btn-dark-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(64, 184, 200, .5);
}

.btn-dark-warning {
  color: #212529;
  background-color: #e9ab2e;
  border-color: #e9ab2e;
}

.btn-dark-warning:hover {
  color: #212529;
  background-color: #da9917;
  border-color: #ce9116;
}

.btn-dark-warning.focus,
.btn-dark-warning:focus {
  color: #212529;
  background-color: #da9917;
  border-color: #ce9116;
  box-shadow: 0 0 0 .2rem rgba(203, 151, 45, .5);
}

.btn-dark-warning.disabled,
.btn-dark-warning:disabled {
  color: #212529;
  background-color: #e9ab2e;
  border-color: #e9ab2e;
}

.btn-dark-warning:not(:disabled):not(.disabled).active,
.btn-dark-warning:not(:disabled):not(.disabled):active,
.show > .btn-dark-warning.dropdown-toggle {
  color: #fff;
  background-color: #ce9116;
  border-color: #c38915;
}

.btn-dark-warning:not(:disabled):not(.disabled).active:focus,
.btn-dark-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(203, 151, 45, .5);
}

.btn-dark-primary {
  color: #fff;
  background-color: #6352ce;
  border-color: #6352ce;
}

.btn-dark-primary:hover {
  color: #fff;
  background-color: #4a37c3;
  border-color: #4634b9;
}

.btn-dark-primary.focus,
.btn-dark-primary:focus {
  color: #fff;
  background-color: #4a37c3;
  border-color: #4634b9;
  box-shadow: 0 0 0 .2rem rgba(122, 108, 213, .5);
}

.btn-dark-primary.disabled,
.btn-dark-primary:disabled {
  color: #fff;
  background-color: #6352ce;
  border-color: #6352ce;
}

.btn-dark-primary:not(:disabled):not(.disabled).active,
.btn-dark-primary:not(:disabled):not(.disabled):active,
.show > .btn-dark-primary.dropdown-toggle {
  color: #fff;
  background-color: #4634b9;
  border-color: #4331af;
}

.btn-dark-primary:not(:disabled):not(.disabled).active:focus,
.btn-dark-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(122, 108, 213, .5);
}

.btn-dark-info {
  color: #fff;
  background-color: #028ee1;
  border-color: #028ee1;
}

.btn-dark-info:hover {
  color: #fff;
  background-color: #0276bb;
  border-color: #026eae;
}

.btn-dark-info.focus,
.btn-dark-info:focus {
  color: #fff;
  background-color: #0276bb;
  border-color: #026eae;
  box-shadow: 0 0 0 .2rem rgba(40, 159, 230, .5);
}

.btn-dark-info.disabled,
.btn-dark-info:disabled {
  color: #fff;
  background-color: #028ee1;
  border-color: #028ee1;
}

.btn-dark-info:not(:disabled):not(.disabled).active,
.btn-dark-info:not(:disabled):not(.disabled):active,
.show > .btn-dark-info.dropdown-toggle {
  color: #fff;
  background-color: #026eae;
  border-color: #0166a2;
}

.btn-dark-info:not(:disabled):not(.disabled).active:focus,
.btn-dark-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(40, 159, 230, .5);
}

.btn-outline-primary {
  color: #7460ee;
  border-color: #7460ee;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 .2rem rgba(116, 96, 238, .5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #7460ee;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(116, 96, 238, .5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5);
}

.btn-outline-success {
  color: #21c1d6;
  border-color: #21c1d6;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #21c1d6;
  border-color: #21c1d6;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 .2rem rgba(33, 193, 214, .5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #21c1d6;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #21c1d6;
  border-color: #21c1d6;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(33, 193, 214, .5);
}

.btn-outline-info {
  color: #1e88e5;
  border-color: #1e88e5;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #1e88e5;
  border-color: #1e88e5;
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 .2rem rgba(30, 136, 229, .5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #1e88e5;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #1e88e5;
  border-color: #1e88e5;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(30, 136, 229, .5);
}

.btn-outline-warning {
  color: #ffb22b;
  border-color: #ffb22b;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffb22b;
  border-color: #ffb22b;
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 178, 43, .5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffb22b;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffb22b;
  border-color: #ffb22b;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 178, 43, .5);
}

.btn-outline-danger {
  color: #fc4b6c;
  border-color: #fc4b6c;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #fc4b6c;
  border-color: #fc4b6c;
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 .2rem rgba(252, 75, 108, .5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #fc4b6c;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #fc4b6c;
  border-color: #fc4b6c;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(252, 75, 108, .5);
}

.btn-outline-light {
  color: #f2f4f8;
  border-color: #f2f4f8;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f2f4f8;
  border-color: #f2f4f8;
}

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 .2rem rgba(242, 244, 248, .5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f2f4f8;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f2f4f8;
  border-color: #f2f4f8;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(242, 244, 248, .5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5);
}

.btn-outline-cyan {
  color: #26c6da;
  border-color: #26c6da;
}

.btn-outline-cyan:hover {
  color: #212529;
  background-color: #26c6da;
  border-color: #26c6da;
}

.btn-outline-cyan.focus,
.btn-outline-cyan:focus {
  box-shadow: 0 0 0 .2rem rgba(38, 198, 218, .5);
}

.btn-outline-cyan.disabled,
.btn-outline-cyan:disabled {
  color: #26c6da;
  background-color: transparent;
}

.btn-outline-cyan:not(:disabled):not(.disabled).active,
.btn-outline-cyan:not(:disabled):not(.disabled):active,
.show > .btn-outline-cyan.dropdown-toggle {
  color: #212529;
  background-color: #26c6da;
  border-color: #26c6da;
}

.btn-outline-cyan:not(:disabled):not(.disabled).active:focus,
.btn-outline-cyan:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-cyan.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(38, 198, 218, .5);
}

.btn-outline-orange {
  color: #fb8c00;
  border-color: #fb8c00;
}

.btn-outline-orange:hover {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}

.btn-outline-orange.focus,
.btn-outline-orange:focus {
  box-shadow: 0 0 0 .2rem rgba(251, 140, 0, .5);
}

.btn-outline-orange.disabled,
.btn-outline-orange:disabled {
  color: #fb8c00;
  background-color: transparent;
}

.btn-outline-orange:not(:disabled):not(.disabled).active,
.btn-outline-orange:not(:disabled):not(.disabled):active,
.show > .btn-outline-orange.dropdown-toggle {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}

.btn-outline-orange:not(:disabled):not(.disabled).active:focus,
.btn-outline-orange:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(251, 140, 0, .5);
}

.btn-outline-purple {
  color: #7460ee;
  border-color: #7460ee;
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-outline-purple.focus,
.btn-outline-purple:focus {
  box-shadow: 0 0 0 .2rem rgba(116, 96, 238, .5);
}

.btn-outline-purple.disabled,
.btn-outline-purple:disabled {
  color: #7460ee;
  background-color: transparent;
}

.btn-outline-purple:not(:disabled):not(.disabled).active,
.btn-outline-purple:not(:disabled):not(.disabled):active,
.show > .btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #7460ee;
  border-color: #7460ee;
}

.btn-outline-purple:not(:disabled):not(.disabled).active:focus,
.btn-outline-purple:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(116, 96, 238, .5);
}

.btn-outline-inverse {
  color: #2f3d4a;
  border-color: #2f3d4a;
}

.btn-outline-inverse:hover {
  color: #fff;
  background-color: #2f3d4a;
  border-color: #2f3d4a;
}

.btn-outline-inverse.focus,
.btn-outline-inverse:focus {
  box-shadow: 0 0 0 .2rem rgba(47, 61, 74, .5);
}

.btn-outline-inverse.disabled,
.btn-outline-inverse:disabled {
  color: #2f3d4a;
  background-color: transparent;
}

.btn-outline-inverse:not(:disabled):not(.disabled).active,
.btn-outline-inverse:not(:disabled):not(.disabled):active,
.show > .btn-outline-inverse.dropdown-toggle {
  color: #fff;
  background-color: #2f3d4a;
  border-color: #2f3d4a;
}

.btn-outline-inverse:not(:disabled):not(.disabled).active:focus,
.btn-outline-inverse:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-inverse.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(47, 61, 74, .5);
}

.btn-outline-megna {
  color: #01c0c8;
  border-color: #01c0c8;
}

.btn-outline-megna:hover {
  color: #fff;
  background-color: #01c0c8;
  border-color: #01c0c8;
}

.btn-outline-megna.focus,
.btn-outline-megna:focus {
  box-shadow: 0 0 0 .2rem rgba(1, 192, 200, .5);
}

.btn-outline-megna.disabled,
.btn-outline-megna:disabled {
  color: #01c0c8;
  background-color: transparent;
}

.btn-outline-megna:not(:disabled):not(.disabled).active,
.btn-outline-megna:not(:disabled):not(.disabled):active,
.show > .btn-outline-megna.dropdown-toggle {
  color: #fff;
  background-color: #01c0c8;
  border-color: #01c0c8;
}

.btn-outline-megna:not(:disabled):not(.disabled).active:focus,
.btn-outline-megna:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-megna.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(1, 192, 200, .5);
}

.btn-outline-light-danger {
  color: #f9e7eb;
  border-color: #f9e7eb;
}

.btn-outline-light-danger:hover {
  color: #212529;
  background-color: #f9e7eb;
  border-color: #f9e7eb;
}

.btn-outline-light-danger.focus,
.btn-outline-light-danger:focus {
  box-shadow: 0 0 0 .2rem rgba(249, 231, 235, .5);
}

.btn-outline-light-danger.disabled,
.btn-outline-light-danger:disabled {
  color: #f9e7eb;
  background-color: transparent;
}

.btn-outline-light-danger:not(:disabled):not(.disabled).active,
.btn-outline-light-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-light-danger.dropdown-toggle {
  color: #212529;
  background-color: #f9e7eb;
  border-color: #f9e7eb;
}

.btn-outline-light-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-light-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(249, 231, 235, .5);
}

.btn-outline-light-success {
  color: #e8fdeb;
  border-color: #e8fdeb;
}

.btn-outline-light-success:hover {
  color: #212529;
  background-color: #e8fdeb;
  border-color: #e8fdeb;
}

.btn-outline-light-success.focus,
.btn-outline-light-success:focus {
  box-shadow: 0 0 0 .2rem rgba(232, 253, 235, .5);
}

.btn-outline-light-success.disabled,
.btn-outline-light-success:disabled {
  color: #e8fdeb;
  background-color: transparent;
}

.btn-outline-light-success:not(:disabled):not(.disabled).active,
.btn-outline-light-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-light-success.dropdown-toggle {
  color: #212529;
  background-color: #e8fdeb;
  border-color: #e8fdeb;
}

.btn-outline-light-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-light-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(232, 253, 235, .5);
}

.btn-outline-light-warning {
  color: #fff8ec;
  border-color: #fff8ec;
}

.btn-outline-light-warning:hover {
  color: #212529;
  background-color: #fff8ec;
  border-color: #fff8ec;
}

.btn-outline-light-warning.focus,
.btn-outline-light-warning:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 248, 236, .5);
}

.btn-outline-light-warning.disabled,
.btn-outline-light-warning:disabled {
  color: #fff8ec;
  background-color: transparent;
}

.btn-outline-light-warning:not(:disabled):not(.disabled).active,
.btn-outline-light-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-light-warning.dropdown-toggle {
  color: #212529;
  background-color: #fff8ec;
  border-color: #fff8ec;
}

.btn-outline-light-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-light-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 248, 236, .5);
}

.btn-outline-light-primary {
  color: #f1effd;
  border-color: #f1effd;
}

.btn-outline-light-primary:hover {
  color: #212529;
  background-color: #f1effd;
  border-color: #f1effd;
}

.btn-outline-light-primary.focus,
.btn-outline-light-primary:focus {
  box-shadow: 0 0 0 .2rem rgba(241, 239, 253, .5);
}

.btn-outline-light-primary.disabled,
.btn-outline-light-primary:disabled {
  color: #f1effd;
  background-color: transparent;
}

.btn-outline-light-primary:not(:disabled):not(.disabled).active,
.btn-outline-light-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-light-primary.dropdown-toggle {
  color: #212529;
  background-color: #f1effd;
  border-color: #f1effd;
}

.btn-outline-light-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-light-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(241, 239, 253, .5);
}

.btn-outline-light-info {
  color: #cfecfe;
  border-color: #cfecfe;
}

.btn-outline-light-info:hover {
  color: #212529;
  background-color: #cfecfe;
  border-color: #cfecfe;
}

.btn-outline-light-info.focus,
.btn-outline-light-info:focus {
  box-shadow: 0 0 0 .2rem rgba(207, 236, 254, .5);
}

.btn-outline-light-info.disabled,
.btn-outline-light-info:disabled {
  color: #cfecfe;
  background-color: transparent;
}

.btn-outline-light-info:not(:disabled):not(.disabled).active,
.btn-outline-light-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-light-info.dropdown-toggle {
  color: #212529;
  background-color: #cfecfe;
  border-color: #cfecfe;
}

.btn-outline-light-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-light-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(207, 236, 254, .5);
}

.btn-outline-light-inverse {
  color: #f6f6f6;
  border-color: #f6f6f6;
}

.btn-outline-light-inverse:hover {
  color: #212529;
  background-color: #f6f6f6;
  border-color: #f6f6f6;
}

.btn-outline-light-inverse.focus,
.btn-outline-light-inverse:focus {
  box-shadow: 0 0 0 .2rem rgba(246, 246, 246, .5);
}

.btn-outline-light-inverse.disabled,
.btn-outline-light-inverse:disabled {
  color: #f6f6f6;
  background-color: transparent;
}

.btn-outline-light-inverse:not(:disabled):not(.disabled).active,
.btn-outline-light-inverse:not(:disabled):not(.disabled):active,
.show > .btn-outline-light-inverse.dropdown-toggle {
  color: #212529;
  background-color: #f6f6f6;
  border-color: #f6f6f6;
}

.btn-outline-light-inverse:not(:disabled):not(.disabled).active:focus,
.btn-outline-light-inverse:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light-inverse.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(246, 246, 246, .5);
}

.btn-outline-light-megna {
  color: #e0f2f4;
  border-color: #e0f2f4;
}

.btn-outline-light-megna:hover {
  color: #212529;
  background-color: #e0f2f4;
  border-color: #e0f2f4;
}

.btn-outline-light-megna.focus,
.btn-outline-light-megna:focus {
  box-shadow: 0 0 0 .2rem rgba(224, 242, 244, .5);
}

.btn-outline-light-megna.disabled,
.btn-outline-light-megna:disabled {
  color: #e0f2f4;
  background-color: transparent;
}

.btn-outline-light-megna:not(:disabled):not(.disabled).active,
.btn-outline-light-megna:not(:disabled):not(.disabled):active,
.show > .btn-outline-light-megna.dropdown-toggle {
  color: #212529;
  background-color: #e0f2f4;
  border-color: #e0f2f4;
}

.btn-outline-light-megna:not(:disabled):not(.disabled).active:focus,
.btn-outline-light-megna:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light-megna.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(224, 242, 244, .5);
}

.btn-outline-dark-danger {
  color: #e6294b;
  border-color: #e6294b;
}

.btn-outline-dark-danger:hover {
  color: #fff;
  background-color: #e6294b;
  border-color: #e6294b;
}

.btn-outline-dark-danger.focus,
.btn-outline-dark-danger:focus {
  box-shadow: 0 0 0 .2rem rgba(230, 41, 75, .5);
}

.btn-outline-dark-danger.disabled,
.btn-outline-dark-danger:disabled {
  color: #e6294b;
  background-color: transparent;
}

.btn-outline-dark-danger:not(:disabled):not(.disabled).active,
.btn-outline-dark-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark-danger.dropdown-toggle {
  color: #fff;
  background-color: #e6294b;
  border-color: #e6294b;
}

.btn-outline-dark-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(230, 41, 75, .5);
}

.btn-outline-dark-success {
  color: #1eacbe;
  border-color: #1eacbe;
}

.btn-outline-dark-success:hover {
  color: #fff;
  background-color: #1eacbe;
  border-color: #1eacbe;
}

.btn-outline-dark-success.focus,
.btn-outline-dark-success:focus {
  box-shadow: 0 0 0 .2rem rgba(30, 172, 190, .5);
}

.btn-outline-dark-success.disabled,
.btn-outline-dark-success:disabled {
  color: #1eacbe;
  background-color: transparent;
}

.btn-outline-dark-success:not(:disabled):not(.disabled).active,
.btn-outline-dark-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark-success.dropdown-toggle {
  color: #fff;
  background-color: #1eacbe;
  border-color: #1eacbe;
}

.btn-outline-dark-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(30, 172, 190, .5);
}

.btn-outline-dark-warning {
  color: #e9ab2e;
  border-color: #e9ab2e;
}

.btn-outline-dark-warning:hover {
  color: #212529;
  background-color: #e9ab2e;
  border-color: #e9ab2e;
}

.btn-outline-dark-warning.focus,
.btn-outline-dark-warning:focus {
  box-shadow: 0 0 0 .2rem rgba(233, 171, 46, .5);
}

.btn-outline-dark-warning.disabled,
.btn-outline-dark-warning:disabled {
  color: #e9ab2e;
  background-color: transparent;
}

.btn-outline-dark-warning:not(:disabled):not(.disabled).active,
.btn-outline-dark-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark-warning.dropdown-toggle {
  color: #212529;
  background-color: #e9ab2e;
  border-color: #e9ab2e;
}

.btn-outline-dark-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(233, 171, 46, .5);
}

.btn-outline-dark-primary {
  color: #6352ce;
  border-color: #6352ce;
}

.btn-outline-dark-primary:hover {
  color: #fff;
  background-color: #6352ce;
  border-color: #6352ce;
}

.btn-outline-dark-primary.focus,
.btn-outline-dark-primary:focus {
  box-shadow: 0 0 0 .2rem rgba(99, 82, 206, .5);
}

.btn-outline-dark-primary.disabled,
.btn-outline-dark-primary:disabled {
  color: #6352ce;
  background-color: transparent;
}

.btn-outline-dark-primary:not(:disabled):not(.disabled).active,
.btn-outline-dark-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark-primary.dropdown-toggle {
  color: #fff;
  background-color: #6352ce;
  border-color: #6352ce;
}

.btn-outline-dark-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(99, 82, 206, .5);
}

.btn-outline-dark-info {
  color: #028ee1;
  border-color: #028ee1;
}

.btn-outline-dark-info:hover {
  color: #fff;
  background-color: #028ee1;
  border-color: #028ee1;
}

.btn-outline-dark-info.focus,
.btn-outline-dark-info:focus {
  box-shadow: 0 0 0 .2rem rgba(2, 142, 225, .5);
}

.btn-outline-dark-info.disabled,
.btn-outline-dark-info:disabled {
  color: #028ee1;
  background-color: transparent;
}

.btn-outline-dark-info:not(:disabled):not(.disabled).active,
.btn-outline-dark-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark-info.dropdown-toggle {
  color: #fff;
  background-color: #028ee1;
  border-color: #028ee1;
}

.btn-outline-dark-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(2, 142, 225, .5);
}

.btn-link {
  font-weight: 400;
  color: #7460ee;
  text-decoration: none;
}

.btn-link:hover {
  color: #381be7;
  text-decoration: underline;
}

.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: .5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 5px;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: .25rem .5rem;
  font-size: .76563rem;
  line-height: 1.5;
  border-radius: 1px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: .5rem;
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
  width: 100%;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: " ";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: .875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid #e9ecef;
  border-radius: 4px;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1600px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: " ";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: .125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: " ";
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: .125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: " ";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .255em;
  content: " ";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .65rem 1rem;
  clear: both;
  font-weight: 400;
  color: #6c757d;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f2f4f8;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1e88e5;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: .5rem 1rem;
  margin-bottom: 0;
  font-size: .76563rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: .65rem 1rem;
  color: #6c757d;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group-vertical > .btn:hover,
.btn-group > .btn:hover {
  z-index: 1;
}

.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: .5625rem;
  padding-left: .5625rem;
}

.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: .75rem;
  padding-left: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .form-control {
  margin-left: -1px;
}

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-append,
.input-group-prepend {
  display: flex;
}

.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  z-index: 3;
}

.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #54667a;
  text-align: center;
  white-space: nowrap;
  background-color: #f2f4f8;
  border: 1px solid #e9ecef;
  border-radius: 4px;
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
  margin-top: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: .5rem 1rem;
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #4f5467;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 4px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1e88e5;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: .33594rem;
  padding-bottom: .33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: " ";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1599.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1600px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, .9);
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, .9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, .5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, .7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, .3);
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, .9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, .5);
  border-color: rgba(0, 0, 0, .1);
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, .5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, .9);
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, .9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, .25);
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: rgba(255, 255, 255, .1);
}

.navbar-dark .navbar-text {
  color: #fff;
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 1.5rem;
  list-style: none;
  background-color: #eef5f9;
  border-radius: 4px;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  color: #6c757d;
  content: "/ ";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 5px;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 5px 0;
  }

  to {
    background-position: 0 0;
  }
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 500;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: .875rem;
  background-color: rgba(255, 255, 255, .85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .1);
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: .25rem;
}

.toast:not(:last-child) {
  margin-bottom: .75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: .25rem .75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, .85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, .05);
}

.toast-body {
  padding: .75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: " ";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 5px;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.modal-footer > * {
  margin: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1600px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .76563rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: " ";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
  padding: .4rem 0;
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #000;
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
  padding: 0 .4rem;
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: .4rem;
  height: .8rem;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
  padding: .4rem 0;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
  padding: 0 .4rem;
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: .4rem;
  height: .8rem;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .76563rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 5px;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem;
  margin: 0 5px;
}

.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: " ";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto[x-placement^=top],
.bs-popover-top {
  margin-bottom: .5rem;
}

.bs-popover-auto[x-placement^=top] > .arrow,
.bs-popover-top > .arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^=top] > .arrow::before,
.bs-popover-top > .arrow::before {
  bottom: 0;
  border-width: .5rem .5rem 0;
  border-top-color: rgba(0, 0, 0, .25);
}

.bs-popover-auto[x-placement^=top] > .arrow::after,
.bs-popover-top > .arrow::after {
  bottom: 1px;
  border-width: .5rem .5rem 0;
  border-top-color: #fff;
}

.bs-popover-auto[x-placement^=right],
.bs-popover-right {
  margin-left: .5rem;
}

.bs-popover-auto[x-placement^=right] > .arrow,
.bs-popover-right > .arrow {
  left: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem;
  margin: 5px 0;
}

.bs-popover-auto[x-placement^=right] > .arrow::before,
.bs-popover-right > .arrow::before {
  left: 0;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: rgba(0, 0, 0, .25);
}

.bs-popover-auto[x-placement^=right] > .arrow::after,
.bs-popover-right > .arrow::after {
  left: 1px;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff;
}

.bs-popover-auto[x-placement^=bottom],
.bs-popover-bottom {
  margin-top: .5rem;
}

.bs-popover-auto[x-placement^=bottom] > .arrow,
.bs-popover-bottom > .arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^=bottom] > .arrow::before,
.bs-popover-bottom > .arrow::before {
  top: 0;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: rgba(0, 0, 0, .25);
}

.bs-popover-auto[x-placement^=bottom] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  top: 1px;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: #fff;
}

.bs-popover-auto[x-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: " ";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-auto[x-placement^=left],
.bs-popover-left {
  margin-right: .5rem;
}

.bs-popover-auto[x-placement^=left] > .arrow,
.bs-popover-left > .arrow {
  right: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem;
  margin: 5px 0;
}

.bs-popover-auto[x-placement^=left] > .arrow::before,
.bs-popover-left > .arrow::before {
  right: 0;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: rgba(0, 0, 0, .25);
}

.bs-popover-auto[x-placement^=left] > .arrow::after,
.bs-popover-left > .arrow::after {
  right: 1px;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: #455a64;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: .5rem .75rem;
  color: #67757c;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: " ";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  transform: translateX(100%);
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  transform: translateX(-100%);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 64px;
  height: 64px;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 64px;
  height: 64px;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #7460ee !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #4c32e9 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #21c1d6 !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1a99aa !important;
}

.bg-info {
  background-color: #1e88e5 !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #166dba !important;
}

.bg-warning {
  background-color: #ffb22b !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #f79d00 !important;
}

.bg-danger {
  background-color: #fc4b6c !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #fb1943 !important;
}

.bg-light {
  background-color: #f2f4f8 !important;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #d1d8e6 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-cyan {
  background-color: #26c6da !important;
}

a.bg-cyan:focus,
a.bg-cyan:hover,
button.bg-cyan:focus,
button.bg-cyan:hover {
  background-color: #1e9faf !important;
}

.bg-orange {
  background-color: #fb8c00 !important;
}

a.bg-orange:focus,
a.bg-orange:hover,
button.bg-orange:focus,
button.bg-orange:hover {
  background-color: #c87000 !important;
}

.bg-purple {
  background-color: #7460ee !important;
}

a.bg-purple:focus,
a.bg-purple:hover,
button.bg-purple:focus,
button.bg-purple:hover {
  background-color: #4c32e9 !important;
}

.bg-inverse {
  background-color: #2f3d4a !important;
}

a.bg-inverse:focus,
a.bg-inverse:hover,
button.bg-inverse:focus,
button.bg-inverse:hover {
  background-color: #1b232b !important;
}

.bg-megna {
  background-color: #01c0c8 !important;
}

a.bg-megna:focus,
a.bg-megna:hover,
button.bg-megna:focus,
button.bg-megna:hover {
  background-color: #018f95 !important;
}

.bg-light-danger {
  background-color: #f9e7eb !important;
}

a.bg-light-danger:focus,
a.bg-light-danger:hover,
button.bg-light-danger:focus,
button.bg-light-danger:hover {
  background-color: #efbec9 !important;
}

.bg-light-success {
  background-color: #e8fdeb !important;
}

a.bg-light-success:focus,
a.bg-light-success:hover,
button.bg-light-success:focus,
button.bg-light-success:hover {
  background-color: #b9f9c2 !important;
}

.bg-light-warning {
  background-color: #fff8ec !important;
}

a.bg-light-warning:focus,
a.bg-light-warning:hover,
button.bg-light-warning:focus,
button.bg-light-warning:hover {
  background-color: #ffe5b9 !important;
}

.bg-light-primary {
  background-color: #f1effd !important;
}

a.bg-light-primary:focus,
a.bg-light-primary:hover,
button.bg-light-primary:focus,
button.bg-light-primary:hover {
  background-color: #c9c2f7 !important;
}

.bg-light-info {
  background-color: #cfecfe !important;
}

a.bg-light-info:focus,
a.bg-light-info:hover,
button.bg-light-info:focus,
button.bg-light-info:hover {
  background-color: #9dd8fd !important;
}

.bg-light-inverse {
  background-color: #f6f6f6 !important;
}

a.bg-light-inverse:focus,
a.bg-light-inverse:hover,
button.bg-light-inverse:focus,
button.bg-light-inverse:hover {
  background-color: #ddd !important;
}

.bg-light-megna {
  background-color: #e0f2f4 !important;
}

a.bg-light-megna:focus,
a.bg-light-megna:hover,
button.bg-light-megna:focus,
button.bg-light-megna:hover {
  background-color: #bae2e7 !important;
}

.bg-dark-danger {
  background-color: #e6294b !important;
}

a.bg-dark-danger:focus,
a.bg-dark-danger:hover,
button.bg-dark-danger:focus,
button.bg-dark-danger:hover {
  background-color: #c51736 !important;
}

.bg-dark-success {
  background-color: #1eacbe !important;
}

a.bg-dark-success:focus,
a.bg-dark-success:hover,
button.bg-dark-success:focus,
button.bg-dark-success:hover {
  background-color: #178492 !important;
}

.bg-dark-warning {
  background-color: #e9ab2e !important;
}

a.bg-dark-warning:focus,
a.bg-dark-warning:hover,
button.bg-dark-warning:focus,
button.bg-dark-warning:hover {
  background-color: #ce9116 !important;
}

.bg-dark-primary {
  background-color: #6352ce !important;
}

a.bg-dark-primary:focus,
a.bg-dark-primary:hover,
button.bg-dark-primary:focus,
button.bg-dark-primary:hover {
  background-color: #4634b9 !important;
}

.bg-dark-info {
  background-color: #028ee1 !important;
}

a.bg-dark-info:focus,
a.bg-dark-info:hover,
button.bg-dark-info:focus,
button.bg-dark-info:hover {
  background-color: #026eae !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e9ecef !important;
}

.border-top {
  border-top: 1px solid #e9ecef !important;
}

.border-right {
  border-right: 1px solid #e9ecef !important;
}

.border-bottom {
  border-bottom: 1px solid #e9ecef !important;
}

.border-left {
  border-left: 1px solid #e9ecef !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #7460ee !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #21c1d6 !important;
}

.border-info {
  border-color: #1e88e5 !important;
}

.border-warning {
  border-color: #ffb22b !important;
}

.border-danger {
  border-color: #fc4b6c !important;
}

.border-light {
  border-color: #f2f4f8 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-cyan {
  border-color: #26c6da !important;
}

.border-orange {
  border-color: #fb8c00 !important;
}

.border-purple {
  border-color: #7460ee !important;
}

.border-inverse {
  border-color: #2f3d4a !important;
}

.border-megna {
  border-color: #01c0c8 !important;
}

.border-light-danger {
  border-color: #f9e7eb !important;
}

.border-light-success {
  border-color: #e8fdeb !important;
}

.border-light-warning {
  border-color: #fff8ec !important;
}

.border-light-primary {
  border-color: #f1effd !important;
}

.border-light-info {
  border-color: #cfecfe !important;
}

.border-light-inverse {
  border-color: #f6f6f6 !important;
}

.border-light-megna {
  border-color: #e0f2f4 !important;
}

.border-dark-danger {
  border-color: #e6294b !important;
}

.border-dark-success {
  border-color: #1eacbe !important;
}

.border-dark-warning {
  border-color: #e9ab2e !important;
}

.border-dark-primary {
  border-color: #6352ce !important;
}

.border-dark-info {
  border-color: #028ee1 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 1px !important;
}

.rounded {
  border-radius: 4px !important;
}

.rounded-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.rounded-right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rounded-left {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rounded-lg {
  border-radius: 5px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: " ";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1600px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: " ";
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1600px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1600px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 2px 9px 0 rgba(169, 184, 200, .2) !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: " ";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1,
.my-1 {
  margin-top: .25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: .25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: .25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2,
.my-2 {
  margin-top: .5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: .5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: .5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1,
.py-1 {
  padding-top: .25rem !important;
}

.pr-1,
.px-1 {
  padding-right: .25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: .25rem !important;
}

.pl-1,
.px-1 {
  padding-left: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2,
.py-2 {
  padding-top: .5rem !important;
}

.pr-2,
.px-2 {
  padding-right: .5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: .5rem !important;
}

.pl-2,
.px-2 {
  padding-left: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: .25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: .25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: .25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: .5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: .5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: .5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: .25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: .25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: .5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: .5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: .25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: .25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: .25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: .5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: .5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: .5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: .25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: .25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: .25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: .5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: .5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: .5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: .25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: .25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: .25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: .5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: .5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: .5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: .25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: .25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: .5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: .5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1600px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: .25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: .25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: .25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: .5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: .5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: .5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: .25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: .25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: .5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: .5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1600px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #7460ee !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #381be7 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #494f54 !important;
}

.text-success {
  color: #21c1d6 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #178594 !important;
}

.text-info {
  color: #1e88e5 !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #1360a4 !important;
}

.text-warning {
  color: #ffb22b !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #de8d00 !important;
}

.text-danger {
  color: #fc4b6c !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #f60431 !important;
}

.text-light {
  color: #f2f4f8 !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #c0cadd !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #121416 !important;
}

.text-cyan {
  color: #26c6da !important;
}

a.text-cyan:focus,
a.text-cyan:hover {
  color: #1a8b99 !important;
}

.text-orange {
  color: #fb8c00 !important;
}

a.text-orange:focus,
a.text-orange:hover {
  color: #af6100 !important;
}

.text-purple {
  color: #7460ee !important;
}

a.text-purple:focus,
a.text-purple:hover {
  color: #381be7 !important;
}

.text-inverse {
  color: #2f3d4a !important;
}

a.text-inverse:focus,
a.text-inverse:hover {
  color: #11161b !important;
}

.text-megna {
  color: #01c0c8 !important;
}

a.text-megna:focus,
a.text-megna:hover {
  color: #01777c !important;
}

.text-light-danger {
  color: #f9e7eb !important;
}

a.text-light-danger:focus,
a.text-light-danger:hover {
  color: #eaaab8 !important;
}

.text-light-success {
  color: #e8fdeb !important;
}

a.text-light-success:focus,
a.text-light-success:hover {
  color: #a2f7ae !important;
}

.text-light-warning {
  color: #fff8ec !important;
}

a.text-light-warning:focus,
a.text-light-warning:hover {
  color: #ffdca0 !important;
}

.text-light-primary {
  color: #f1effd !important;
}

a.text-light-primary:focus,
a.text-light-primary:hover {
  color: #b6abf5 !important;
}

.text-light-info {
  color: #cfecfe !important;
}

a.text-light-info:focus,
a.text-light-info:hover {
  color: #84cefc !important;
}

.text-light-inverse {
  color: #f6f6f6 !important;
}

a.text-light-inverse:focus,
a.text-light-inverse:hover {
  color: #d0d0d0 !important;
}

.text-light-megna {
  color: #e0f2f4 !important;
}

a.text-light-megna:focus,
a.text-light-megna:hover {
  color: #a8dae0 !important;
}

.text-dark-danger {
  color: #e6294b !important;
}

a.text-dark-danger:focus,
a.text-dark-danger:hover {
  color: #ae1430 !important;
}

.text-dark-success {
  color: #1eacbe !important;
}

a.text-dark-success:focus,
a.text-dark-success:hover {
  color: #14707c !important;
}

.text-dark-warning {
  color: #e9ab2e !important;
}

a.text-dark-warning:focus,
a.text-dark-warning:hover {
  color: #b78113 !important;
}

.text-dark-primary {
  color: #6352ce !important;
}

a.text-dark-primary:focus,
a.text-dark-primary:hover {
  color: #3f2fa5 !important;
}

.text-dark-info {
  color: #028ee1 !important;
}

a.text-dark-info:focus,
a.text-dark-info:hover {
  color: #015e95 !important;
}

.text-body {
  color: #67757c !important;
}

.text-muted {
  color: #a1aab2 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, .5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, .5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote,
  pre {
    border: 1px solid #a1aab2;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #e8eef3;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #e8eef3;
  }
}

.error-box {
  height: 100%;
  width: 100%;
}

#main-wrapper[data-layout=vertical][data-sidebartype=iconbar] .sidebar-nav ul .sidebar-item .sidebar-link,
.f-icon,
.if-icon,
.m-icon,
.sl-icon,
.t-icon,
.w-icon {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.error-box .error-title {
  font-size: 210px;
  font-weight: 900;
  text-shadow: 4px 4px 0 #fff, 6px 6px 0 #343a40;
  line-height: 210px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .error-box .error-title {
    font-size: 150px;
    line-height: 150px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .error-box .error-title {
    font-size: 70px;
    line-height: 70px;
  }
}

.sidebar-nav .has-arrow::after {
  -webkit-transform: rotate(-45deg) translate(0, -50%);
  -ms-transform: rotate(-45deg) translate(0, -50%);
  -o-transform: rotate(-45deg) translate(0, -50%);
  transform: rotate(-45deg) translate(0, -50%);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.sidebar-nav .has-arrow[aria-expanded=true]::after,
.sidebar-nav li.active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after {
  -webkit-transform: rotate(-135deg) translate(0, -50%);
  -ms-transform: rotate(-135deg) translate(0, -50%);
  -o-transform: rotate(-135deg) translate(0, -50%);
  transform: rotate(-135deg) translate(0, -50%);
}

@-webkit-keyframes blow {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, .5);
    transform: scale3d(1, 1, .5);
  }

  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, .1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, .5);
    transform: scale3d(1, 1, .5);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, .1);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, .5);
    transform: scale3d(1, 1, .5);
  }
}

@keyframes blow {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, .5);
    transform: scale3d(1, 1, .5);
  }

  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, .1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, .5);
    transform: scale3d(1, 1, .5);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, .1);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, .5);
    transform: scale3d(1, 1, .5);
  }
}

.topbar .mega-dropdown .dropdown-menu {
  border-radius: 0;
}

.topbar .dropdown-menu .with-arrow > span {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
}

.btn-facebook {
  background: #3b5998;
  color: #fff;
}

.btn-facebook:hover {
  background: #30487b;
  color: #fff;
}

.btn-facebook:active {
  background: #17233c;
  color: #fff;
}

.btn-twitter {
  background: #55acee;
  color: #fff;
}

.btn-twitter:hover {
  background: #309aea;
  color: #fff;
}

.btn-twitter:active {
  background: #126db2;
  color: #fff;
}

.btn-linkedin {
  background: #007bb6;
  color: #fff;
}

.btn-linkedin:hover {
  background: #005f8d;
  color: #fff;
}

.btn-linkedin:active {
  background: #002537;
  color: #fff;
}

.btn-dribbble {
  background: #ea4c89;
  color: #fff;
}

.btn-dribbble:hover {
  background: #e62771;
  color: #fff;
}

.btn-dribbble:active {
  background: #a3134b;
  color: #fff;
}

.btn-googleplus {
  background: #dd4b39;
  color: #fff;
}

.btn-googleplus:hover {
  background: #ca3523;
  color: #fff;
}

.btn-googleplus:active {
  background: #802216;
  color: #fff;
}

.btn-instagram {
  background: #3f729b;
  color: #fff;
}

.btn-instagram:hover {
  background: #335d7e;
  color: #fff;
}

.btn-instagram:active {
  background: #1a2f40;
  color: #fff;
}

.btn-pinterest {
  background: #cb2027;
  color: #fff;
}

.btn-pinterest:hover {
  background: #a81a20;
  color: #fff;
}

.btn-pinterest:active {
  background: #5d0f12;
  color: #fff;
}

.btn-dropbox {
  background: #007ee5;
  color: #fff;
}

.btn-dropbox:hover {
  background: #0068bc;
  color: #fff;
}

.btn-dropbox:active {
  background: #003866;
  color: #fff;
}

.btn-flickr {
  background: #ff0084;
  color: #fff;
}

.btn-flickr:hover {
  background: #d6006f;
  color: #fff;
}

.btn-flickr:active {
  background: #800042;
  color: #fff;
}

.btn-tumblr {
  background: #32506d;
  color: #fff;
}

.btn-tumblr:hover {
  background: #253b51;
  color: #fff;
}

.btn-tumblr:active {
  background: #0a1016;
  color: #fff;
}

.btn-skype {
  background: #00aff0;
  color: #fff;
}

.btn-skype:hover {
  background: #0091c7;
  color: #fff;
}

.btn-skype:active {
  background: #005271;
  color: #fff;
}

.btn-youtube {
  background: #b00;
  color: #fff;
}

.btn-youtube:hover {
  background: #920000;
  color: #fff;
}

.btn-youtube:active {
  background: #3c0000;
  color: #fff;
}

.btn-github {
  background: #171515;
  color: #fff;
}

.btn-github:hover {
  background: #020202;
  color: #fff;
}

.btn-github:active {
  background: #000;
  color: #fff;
}

.btn-facebook {
  background: #3b5998;
  color: #fff;
}

.btn-facebook:hover {
  background: #30487b;
  color: #fff;
}

.btn-facebook:active {
  background: #17233c;
  color: #fff;
}

.btn-twitter {
  background: #55acee;
  color: #fff;
}

.btn-twitter:hover {
  background: #309aea;
  color: #fff;
}

.btn-twitter:active {
  background: #126db2;
  color: #fff;
}

.btn-linkedin {
  background: #007bb6;
  color: #fff;
}

.btn-linkedin:hover {
  background: #005f8d;
  color: #fff;
}

.btn-linkedin:active {
  background: #002537;
  color: #fff;
}

.btn-dribbble {
  background: #ea4c89;
  color: #fff;
}

.btn-dribbble:hover {
  background: #e62771;
  color: #fff;
}

.btn-dribbble:active {
  background: #a3134b;
  color: #fff;
}

.btn-googleplus {
  background: #dd4b39;
  color: #fff;
}

.btn-googleplus:hover {
  background: #ca3523;
  color: #fff;
}

.btn-googleplus:active {
  background: #802216;
  color: #fff;
}

.btn-instagram {
  background: #3f729b;
  color: #fff;
}

.btn-instagram:hover {
  background: #335d7e;
  color: #fff;
}

.btn-instagram:active {
  background: #1a2f40;
  color: #fff;
}

.btn-pinterest {
  background: #cb2027;
  color: #fff;
}

.btn-pinterest:hover {
  background: #a81a20;
  color: #fff;
}

.btn-pinterest:active {
  background: #5d0f12;
  color: #fff;
}

.btn-dropbox {
  background: #007ee5;
  color: #fff;
}

.btn-dropbox:hover {
  background: #0068bc;
  color: #fff;
}

.btn-dropbox:active {
  background: #003866;
  color: #fff;
}

.btn-flickr {
  background: #ff0084;
  color: #fff;
}

.btn-flickr:hover {
  background: #d6006f;
  color: #fff;
}

.btn-flickr:active {
  background: #800042;
  color: #fff;
}

.btn-tumblr {
  background: #32506d;
  color: #fff;
}

.btn-tumblr:hover {
  background: #253b51;
  color: #fff;
}

.btn-tumblr:active {
  background: #0a1016;
  color: #fff;
}

.btn-skype {
  background: #00aff0;
  color: #fff;
}

.btn-skype:hover {
  background: #0091c7;
  color: #fff;
}

.btn-skype:active {
  background: #005271;
  color: #fff;
}

.btn-youtube {
  background: #b00;
  color: #fff;
}

.btn-youtube:hover {
  background: #920000;
  color: #fff;
}

.btn-youtube:active {
  background: #3c0000;
  color: #fff;
}

.btn-github {
  background: #171515;
  color: #fff;
}

.btn-github:hover {
  background: #020202;
  color: #fff;
}

.btn-github:active {
  background: #000;
  color: #fff;
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

#main-wrapper {
  width: 100%;
  overflow: hidden;
}

.page-wrapper {
  position: relative;
  background: #eef5f9;
  display: none;
  transition: .2s ease-in;
}

.page-wrapper > .container-fluid,
.page-wrapper > .container-lg,
.page-wrapper > .container-md,
.page-wrapper > .container-sm,
.page-wrapper > .container-xl {
  padding: 20px 30px;
  min-height: calc(100vh - 180px);
}

.footer {
  background: #fff;
  border-top: 1px solid #e9ecef;
  padding: 17px 15px;
}

.left-part {
  position: absolute;
  height: 100%;
  width: 260px;
  border-right: 1px solid #e9ecef;
}

.left-part.fixed-left-part {
  position: fixed;
  top: 0;
  padding-top: 10px;
}

.left-part .show-left-part {
  position: absolute;
  top: 45%;
  right: -41px;
}

.right-part {
  width: calc(100% - 260px);
  height: calc(100vh - 126px);
  margin-left: 260px;
}

.reverse-mode .left-part {
  right: 0;
  border-left: 1px solid #e9ecef;
}

.reverse-mode .show-left-part {
  right: auto;
  left: -41px;
}

.reverse-mode .right-part {
  margin-left: 0;
  margin-right: 260px;
}

#main-wrapper[data-layout=vertical] .topbar .top-navbar .navbar-header {
  transition: .2s ease-in;
}

#main-wrapper[data-layout=vertical][data-sidebartype=full] .page-wrapper {
  margin-left: 240px;
}

#main-wrapper[data-layout=vertical][data-sidebartype=iconbar] .page-wrapper {
  margin-left: 180px;
}

#main-wrapper[data-layout=vertical][data-sidebartype=iconbar] .navbar-brand .logo-text {
  display: none;
}

#main-wrapper[data-layout=vertical][data-sidebartype=iconbar] .left-sidebar,
#main-wrapper[data-layout=vertical][data-sidebartype=iconbar] .left-sidebar .sidebar-footer,
#main-wrapper[data-layout=vertical][data-sidebartype=iconbar] .topbar .top-navbar .navbar-header {
  width: 180px;
}

#main-wrapper[data-layout=vertical][data-sidebartype=iconbar] .sidebar-nav ul .sidebar-item {
  width: calc(180px - 30px);
}

#main-wrapper[data-layout=vertical][data-sidebartype=iconbar] .sidebar-nav ul .sidebar-item .first-level {
  padding-left: 0;
}

#main-wrapper[data-layout=vertical][data-sidebartype=iconbar] .sidebar-nav ul .sidebar-item .sidebar-link {
  display: block;
  white-space: nowrap;
  text-align: center;
}

#main-wrapper[data-layout=vertical][data-sidebartype=iconbar] .sidebar-nav ul .sidebar-item .sidebar-link i {
  display: block;
  width: auto;
}

#main-wrapper[data-layout=vertical][data-sidebartype=overlay] .topbar .top-navbar .navbar-header {
  width: 240px;
}

#main-wrapper[data-layout=vertical][data-sidebartype=overlay] .left-sidebar {
  left: -240px;
}

#main-wrapper[data-layout=vertical][data-sidebartype=overlay].show-sidebar .left-sidebar {
  left: 0;
}

#main-wrapper[data-layout=vertical][data-sidebar-position=fixed] .topbar .top-navbar .navbar-header {
  position: fixed;
  z-index: 10;
}

#main-wrapper[data-layout=vertical][data-sidebar-position=fixed] .left-sidebar {
  position: fixed;
}

#main-wrapper[data-layout=vertical][data-header-position=fixed] .topbar {
  position: fixed;
  width: 100%;
}

#main-wrapper[data-layout=vertical][data-header-position=fixed] .page-wrapper {
  padding-top: 70px;
}

#main-wrapper[data-layout=vertical][data-boxed-layout=boxed] {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  box-shadow: 1px 0 80px rgba(0, 0, 0, .2);
}

#main-wrapper[data-layout=vertical][data-boxed-layout=boxed][data-header-position=fixed] .topbar {
  max-width: 1200px;
}

@media (min-width: 768px) {
  #main-wrapper[data-layout=vertical][data-sidebartype=full] .topbar .top-navbar .navbar-header {
    width: 240px;
  }

  #main-wrapper[data-layout=vertical][data-sidebar-position=fixed][data-sidebartype=full]
    .topbar
    .top-navbar
    .navbar-collapse,
  #main-wrapper[data-layout=vertical][data-sidebar-position=fixed][data-sidebartype=overlay]
    .topbar
    .top-navbar
    .navbar-collapse {
    margin-left: 240px;
  }

  #main-wrapper[data-layout=vertical][data-sidebar-position=fixed][data-sidebartype=mini-sidebar]
    .topbar
    .top-navbar
    .navbar-collapse {
    margin-left: 65px;
  }

  #main-wrapper[data-layout=vertical][data-sidebar-position=fixed][data-sidebartype=iconbar]
    .topbar
    .top-navbar
    .navbar-collapse {
    margin-left: 180px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .topbar .top-navbar .navbar-header {
    width: 65px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .topbar .top-navbar .navbar-header .logo-text {
    display: none;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .topbar .top-navbar .navbar-header.expand-logo {
    width: 240px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar]
    .topbar
    .top-navbar
    .navbar-header.expand-logo
    .logo-text {
    display: block;
  }

  #main-wrapper[data-layout=vertical][data-sidebar-position=fixed][data-sidebartype=mini-sidebar]
    .topbar
    .top-navbar
    .navbar-collapse {
    margin-left: 65px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .page-wrapper {
    margin-left: 65px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .user-profile .profile-img {
    margin-left: 9px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .user-profile .profile-text {
    display: none;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .sidebar-nav ul {
    padding: 7px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .sidebar-nav .has-arrow:after,
  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .sidebar-nav .hide-menu {
    display: none;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .sidebar-nav .nav-small-cap {
    justify-content: center;
    padding: 14px 14px 14px 14px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .sidebar-nav .nav-small-cap i {
    display: block;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .sidebar-nav ul .sidebar-item .sidebar-link {
    padding: 14px 12px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .sidebar-nav ul .sidebar-item .first-level {
    padding: 0 0 10px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar]
    .sidebar-nav
    ul
    .sidebar-item
    .first-level
    .sidebar-item
    .sidebar-link {
    padding: 10px 12px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar]
    .sidebar-nav
    ul
    .sidebar-item
    .first-level
    .sidebar-item
    .sidebar-link
    i {
    display: block;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .left-sidebar {
    width: 65px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .left-sidebar .sidebar-footer {
    display: none;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .left-sidebar:hover {
    width: 240px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .left-sidebar:hover .user-profile .profile-img {
    margin-left: 30px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .left-sidebar:hover .user-profile .profile-text {
    display: block;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .left-sidebar:hover .sidebar-nav .has-arrow:after,
  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .left-sidebar:hover .sidebar-nav .hide-menu {
    display: block;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .left-sidebar:hover .sidebar-nav .nav-small-cap {
    justify-content: flex-start;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .left-sidebar:hover .sidebar-nav .nav-small-cap i {
    display: none;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar]
    .left-sidebar:hover
    .sidebar-nav
    .user-profile
    .profile-img {
    width: 50px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar]
    .left-sidebar:hover
    .sidebar-nav
    .user-profile
    .profile-img::before {
    width: 50px;
    height: 50px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar]
    .left-sidebar:hover
    .sidebar-nav
    .user-profile
    .u-dropdown {
    display: block;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar]
    .left-sidebar:hover
    .sidebar-nav
    ul
    .sidebar-item
    .sidebar-link {
    padding: 8px 35px 8px 8px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar]
    .left-sidebar:hover
    .sidebar-nav
    ul
    .sidebar-item
    .first-level {
    padding: 10px 0 0 5px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar]
    .left-sidebar:hover
    .sidebar-nav
    ul
    .sidebar-item
    .first-level
    .sidebar-item
    .sidebar-link {
    padding: 10px 35px 10px 20px;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar]
    .left-sidebar:hover
    .sidebar-nav
    ul
    .sidebar-item
    .first-level
    .sidebar-item
    .sidebar-link
    i {
    display: none;
  }

  #main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .left-sidebar:hover .sidebar-footer {
    display: flex;
  }
}

@media (max-width: 766px) {
  #main-wrapper[data-sidebartype=mini-sidebar] .left-sidebar,
  #main-wrapper[data-sidebartype=mini-sidebar] .left-sidebar .sidebar-footer {
    left: -240px;
  }

  #main-wrapper.show-sidebar .left-sidebar,
  #main-wrapper.show-sidebar .left-sidebar .sidebar-footer {
    left: 0;
  }

  #main-wrapper[data-layout=vertical][data-header-position=fixed][data-sidebar-position=fixed]
    .topbar
    .top-navbar
    .navbar-collapse,
  #main-wrapper[data-layout=vertical][data-sidebar-position=fixed] .topbar .top-navbar .navbar-collapse {
    position: relative;
    top: 70px;
  }

  .left-part {
    position: fixed;
    left: -260px;
    background: #eef5f9;
    z-index: 1;
    transition: .1s ease-in;
  }

  .left-part.show-panel {
    left: 0;
  }

  .reverse-mode .left-part {
    right: -260px;
    left: auto;
  }

  .reverse-mode .left-part.show-panel {
    right: 0;
  }

  .right-part {
    width: 100%;
    margin-left: 0;
  }
}

.preloader {
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  z-index: 99999;
  background: #fff;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
}

.lds-ripple .lds-pos {
  position: absolute;
  border: 2px solid #009efb;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, .1, .5, 1) infinite;
}

.lds-ripple .lds-pos:nth-child(2) {
  animation-delay: -.5s;
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.notify {
  position: relative;
  top: -35px;
  right: -8px;
}

.notify .heartbit {
  position: absolute;
  top: -20px;
  right: -4px;
  height: 25px;
  width: 25px;
  z-index: 10;
  border: 5px solid #fc4b6c;
  border-radius: 70px;
  -moz-animation: heartbit 1s ease-out;
  -moz-animation-iteration-count: infinite;
  -o-animation: heartbit 1s ease-out;
  -o-animation-iteration-count: infinite;
  -webkit-animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.notify .point {
  width: 6px;
  height: 6px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #fc4b6c;
  position: absolute;
  right: 6px;
  top: -10px;
}

@-moz-keyframes heartbit {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }

  25% {
    -moz-transform: scale(.1);
    opacity: .1;
  }

  50% {
    -moz-transform: scale(.5);
    opacity: .3;
  }

  75% {
    -moz-transform: scale(.8);
    opacity: .5;
  }

  100% {
    -moz-transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(.1);
    opacity: .1;
  }

  50% {
    -webkit-transform: scale(.5);
    opacity: .3;
  }

  75% {
    -webkit-transform: scale(.8);
    opacity: .5;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

.topbar {
  position: relative;
  height: 70px;
  background: #fff;
  z-index: 50;
  transition: .2s ease-in;
  box-shadow: 1px 0 7px rgba(0, 0, 0, .05);
}

.topbar .navbar-collapse {
  padding: 0 10px 0 0;
}

.topbar .top-navbar {
  min-height: 70px;
  padding: 0;
}

.topbar .top-navbar .dropdown-toggle::after {
  display: none;
}

.topbar .top-navbar .navbar-header {
  line-height: 70px;
}

.topbar .top-navbar .navbar-header .navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin: 0;
}

.topbar .top-navbar .navbar-header .navbar-brand .dark-logo {
  display: none;
}

.topbar .top-navbar .navbar-header .navbar-brand .logo-icon {
  margin-right: 5px;
}

.topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
  line-height: 70px;
  height: 70px;
  padding: 0 .75rem;
  font-size: 19px;
}

.topbar .top-navbar .navbar-nav > .nav-item {
  transition: .2s ease-in;
}

.topbar .top-navbar .navbar-nav > .nav-item:hover {
  background: rgba(0, 0, 0, .05);
}

.topbar .mailbox,
.topbar .user-dd {
  width: 300px;
}

.topbar .nav-toggler,
.topbar .topbartoggler {
  color: #fff;
  padding: 0 .75rem;
}

.search-box .app-search {
  position: absolute;
  margin: 0;
  display: block;
  z-index: 110;
  width: 100%;
  top: -1px;
  box-shadow: 2px 0 10px rgba(0, 0, 0, .2);
  display: none;
  left: 0;
}

.search-box .app-search input {
  width: 100%;
  padding: 25px 40px 25px 20px;
  border-radius: 0;
  font-size: 17px;
  transition: .5s ease-in;
  height: 70px;
}

.search-box .app-search .srh-btn {
  position: absolute;
  top: 23px;
  right: 20px;
  cursor: pointer;
}

.topbar .mega-dropdown {
  position: static;
}

.topbar .mega-dropdown .dropdown-menu {
  padding: 30px;
  width: 100%;
  max-height: 480px;
  overflow: auto;
}

.dropdown-user .user-list:hover {
  background: #f2f4f8;
}

.topbar .dropdown-menu {
  padding-top: 0;
  border: 0;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, .1);
}

.topbar .dropdown-menu .with-arrow {
  position: absolute;
  top: -10px;
  width: 40px;
  height: 10px;
  overflow: hidden;
}

.topbar .dropdown-menu .with-arrow > span {
  width: 15px;
  height: 15px;
  position: absolute;
  content: " ";
  top: 3px;
  left: 15px;
  background-color: #fff;
  border-radius: 6px 0 0 0;
}

.topbar .dropdown-menu.dropdown-menu-right .with-arrow {
  right: 0;
}

.topbar .dropdown-menu.dropdown-menu-right .with-arrow > span {
  right: 20px;
  left: auto;
}

@media (max-width: 767.98px) {
  .topbar .top-navbar .navbar-collapse.collapsing,
  .topbar .top-navbar .navbar-collapse.show {
    display: block;
    width: 100%;
    border-top: 1px solid #e9ecef;
  }

  .topbar .top-navbar .navbar-nav {
    flex-direction: row;
  }

  .topbar .top-navbar .navbar-nav .dropdown {
    position: static;
  }

  .topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
    padding: 0 10px;
  }

  .topbar .top-navbar .navbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .topbar .top-navbar .dropdown-menu {
    position: absolute;
    width: 100%;
  }

  .topbar .top-navbar .dropdown-menu .with-arrow {
    display: none;
  }
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
  .mailbox .message-center {
    height: 110px !important;
  }
}

.left-sidebar {
  position: absolute;
  width: 240px;
  height: 100%;
  top: 0;
  padding-top: 70px;
  z-index: 10;
  background: #fff;
  box-shadow: 1px 0 20px rgba(0, 0, 0, .08);
  transition: .2s ease-in;
}

.left-sidebar .sidebar-footer {
  position: fixed;
  bottom: 0;
  width: 240px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e9ecef;
  z-index: 99;
  transition: .2s ease-in;
}

.left-sidebar .sidebar-footer a {
  padding: 15px;
  width: 33.333337%;
  color: #fff;
  text-align: center;
  font-size: 18px;
}

.scroll-sidebar {
  position: relative;
  height: calc(100% - 50px);
}

.sidebar-nav > ul > .sidebar-item > .sidebar-link.active {
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  background-color: #343a40;
  color: #fff;
  opacity: 1;
  font-weight: 400;
}

.sidebar-nav ul {
  padding: 15px;
}

.sidebar-nav ul .sidebar-item {
  width: 100%;
  margin-bottom: 10px;
}

.sidebar-nav ul .sidebar-item .sidebar-link {
  padding: 8px 35px 8px 8px;
  display: flex;
  border-radius: 4px;
  white-space: nowrap;
  align-items: center;
  position: relative;
  line-height: 25px;
  font-size: 15px;
  opacity: .6;
  color: #fff;
}

.sidebar-nav ul .sidebar-item .sidebar-link i {
  font-style: normal;
  line-height: 25px;
  font-size: 21px;
  color: #fff;
  text-align: center;
  width: 30px;
  display: inline-block;
}

.sidebar-nav ul .sidebar-item .sidebar-link .hide-menu {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar-nav ul .sidebar-item .sidebar-link.active,
.sidebar-nav ul .sidebar-item .sidebar-link:hover {
  opacity: 1;
}

.sidebar-nav ul .sidebar-item .sidebar-link.active {
  font-weight: 500;
}

.sidebar-nav ul .sidebar-item.selected > .sidebar-link {
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  background-color: #343a40;
  color: #fff;
  opacity: 1;
}

.sidebar-nav ul .sidebar-item .first-level {
  padding: 10px 0 0 5px;
}

.sidebar-nav ul .sidebar-item .first-level .sidebar-item {
  margin-bottom: 0;
}

.sidebar-nav ul .sidebar-item .first-level .sidebar-item.active .sidebar-link {
  opacity: 1;
}

.sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link {
  padding: 10px 35px 10px 20px;
}

.sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link i {
  display: none;
  font-size: 14px;
}

.sidebar-nav ul .nav-small-cap {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
  opacity: 1;
  text-transform: uppercase;
  font-weight: 500;
  padding: 14px 14px 14px 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.sidebar-nav ul .nav-small-cap i {
  line-height: 30px;
  margin: 0 5px;
  display: none;
}

.sidebar-nav ul .nav-devider {
  background: rgba(255, 255, 255, .1);
  height: 1px;
  margin: 14px 0;
  display: block;
}

.sidebar-nav ul {
  margin: 0;
}

.sidebar-nav ul li {
  list-style: none;
}

.collapse.in {
  display: block;
}

.sidebar-nav .has-arrow {
  position: relative;
}

.sidebar-nav .has-arrow::after {
  position: absolute;
  content: " ";
  width: .55em;
  height: .55em;
  margin-left: 10px;
  top: 20px;
  right: 15px;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #fff;
}

.user-profile .profile-text > a {
  padding: 6px 30px;
  background: rgba(0, 0, 0, .5);
}

.user-profile .profile-text > a:after {
  position: absolute;
  right: 20px;
  top: 15px;
}

.user-profile .profile-img {
  width: 50px;
  margin-left: 30px;
  padding: 35px 0;
}

.user-profile .profile-img::before {
  -webkit-animation: 2.5s blow 0s linear infinite;
  animation: 2.5s blow 0s linear infinite;
  position: absolute;
  content: " ";
  width: 50px;
  height: 50px;
  top: 35px;
  border-radius: 50%;
  z-index: 0;
}

@-webkit-keyframes blow {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, .5);
    transform: scale3d(1, 1, .5);
  }

  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, .1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, .5);
    transform: scale3d(1, 1, .5);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, .1);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, .5);
    transform: scale3d(1, 1, .5);
  }
}

@keyframes blow {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, .5);
    transform: scale3d(1, 1, .5);
  }

  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, .1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, .5);
    transform: scale3d(1, 1, .5);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, .1);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, .5);
    transform: scale3d(1, 1, .5);
  }
}

.user-profile .dropdown-menu {
  left: 0;
  right: 0;
  top: unset !important;
  width: 180px;
  margin: 0 auto;
}

.customizer {
  position: fixed;
  top: 0;
  right: -280px;
  width: 280px;
  height: 100%;
  background: #fff;
  z-index: 100;
  box-shadow: 1px 0 20px rgba(0, 0, 0, .08);
  transition: .3s ease-in;
}

.customizer .service-panel-toggle {
  position: absolute;
  bottom: 27px;
  left: -75px;
  color: #fff;
  background: #26c6da;
  border-radius: 100%;
  padding: 17px 20px;
}

.customizer.show-service-panel {
  position: fixed;
  right: 0;
}

.customizer.show-service-panel .service-panel-toggle {
  border-radius: 30px 0 0 30px;
  left: -54px;
}

.customizer .customizer-body {
  position: relative;
  height: 100%;
}

.customizer .customizer-tab {
  display: flex;
}

.customizer .customizer-tab .nav-item {
  width: 33.33%;
  text-align: center;
}

.customizer .customizer-tab .nav-item .nav-link {
  color: #67757c;
  border-bottom: 3px solid transparent;
  padding: 15px 12px;
}

.customizer .customizer-tab .nav-item .nav-link.active,
.customizer .customizer-tab .nav-item .nav-link:hover {
  color: #009efb;
  border-bottom: 3px solid #009efb;
}

.custom-control-label::after,
.custom-control-label::before {
  top: .15rem;
}

.theme-color .theme-item .theme-link {
  width: 20px;
  height: 20px;
}

.theme-color .theme-item .theme-link[data-logobg=skin1],
.theme-color .theme-item .theme-link[data-navbarbg=skin1],
.theme-color .theme-item .theme-link[data-sidebarbg=skin1] {
  background: #1e88e5;
}

.theme-color .theme-item .theme-link[data-logobg=skin2],
.theme-color .theme-item .theme-link[data-navbarbg=skin2],
.theme-color .theme-item .theme-link[data-sidebarbg=skin2] {
  background: #00acc1;
}

.theme-color .theme-item .theme-link[data-logobg=skin3],
.theme-color .theme-item .theme-link[data-navbarbg=skin3],
.theme-color .theme-item .theme-link[data-sidebarbg=skin3] {
  background: #fc4b6c;
}

.theme-color .theme-item .theme-link[data-logobg=skin4],
.theme-color .theme-item .theme-link[data-navbarbg=skin4],
.theme-color .theme-item .theme-link[data-sidebarbg=skin4] {
  background: #7460ee;
}

.theme-color .theme-item .theme-link[data-logobg=skin5],
.theme-color .theme-item .theme-link[data-navbarbg=skin5],
.theme-color .theme-item .theme-link[data-sidebarbg=skin5] {
  background: #1d2126;
}

.theme-color .theme-item .theme-link[data-logobg=skin6],
.theme-color .theme-item .theme-link[data-navbarbg=skin6],
.theme-color .theme-item .theme-link[data-sidebarbg=skin6] {
  background: #e9ecef;
}

#main-wrapper[data-layout=horizontal] .page-titles {
  background: 0 0;
  box-shadow: none;
  margin-bottom: 0;
  padding: 15px 30px 0;
}

#main-wrapper[data-layout=horizontal] .footer {
  background: 0 0;
  border-top: 0;
  text-align: center;
}

#main-wrapper[data-layout=horizontal] .right-part {
  height: calc(100vh - 180px);
}

#main-wrapper[data-layout=horizontal] .chat-container .chat-box {
  height: calc(100vh - 365px) !important;
}

#main-wrapper[data-layout=horizontal][data-header-position=fixed] .topbar {
  position: fixed;
  width: 100%;
}

#main-wrapper[data-layout=horizontal][data-header-position=fixed] .left-sidebar {
  padding-top: 70px;
}

#main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .topbar {
  position: fixed;
  width: 100%;
}

#main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .left-sidebar {
  position: fixed;
  padding-top: 70px;
}

#main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .page-wrapper {
  padding-top: 78px;
}

@media (min-width: 992px) and (max-width: 1599.98px) {
  #main-wrapper[data-layout=horizontal][data-boxed-layout=boxed] .page-breadcrumb {
    padding: 25px 0 0;
  }

  #main-wrapper[data-layout=horizontal][data-boxed-layout=boxed] .page-wrapper > .container-fluid,
  #main-wrapper[data-layout=horizontal][data-boxed-layout=boxed] .page-wrapper > .container-lg,
  #main-wrapper[data-layout=horizontal][data-boxed-layout=boxed] .page-wrapper > .container-md,
  #main-wrapper[data-layout=horizontal][data-boxed-layout=boxed] .page-wrapper > .container-sm,
  #main-wrapper[data-layout=horizontal][data-boxed-layout=boxed] .page-wrapper > .container-xl {
    padding: 20px 10px;
  }
}

@media (min-width: 992px) {
  #main-wrapper[data-layout=horizontal] {
    background: #eef5f9;
  }

  #main-wrapper[data-layout=horizontal] .page-titles {
    padding: 15px 10px 0;
  }

  #main-wrapper[data-layout=horizontal] .topbar {
    transition: 0s;
  }

  #main-wrapper[data-layout=horizontal][data-boxed-layout=boxed] .page-wrapper,
  #main-wrapper[data-layout=horizontal][data-boxed-layout=boxed] .scroll-sidebar,
  #main-wrapper[data-layout=horizontal][data-boxed-layout=boxed] .top-navbar {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }

  #main-wrapper[data-layout=horizontal][data-boxed-layout=boxed] .sidebar-nav #sidebarnav {
    flex-wrap: wrap;
  }

  #main-wrapper[data-layout=horizontal][data-boxed-layout=boxed]
    .sidebar-nav
    #sidebarnav
    > .sidebar-item
    > .has-arrow:after {
    display: block;
  }

  #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .page-wrapper {
    padding-top: 125px;
  }

  #main-wrapper[data-layout=horizontal] .mega-dropdown .first-level {
    max-height: 400px;
    overflow: auto;
  }

  #main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-header {
    width: 200px;
  }

  #main-wrapper[data-layout=horizontal] .topbar .sidebartoggler {
    display: none;
  }

  #main-wrapper[data-layout=horizontal] .left-sidebar {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 0;
    z-index: 45;
    transition: 0s;
  }

  #main-wrapper[data-layout=horizontal] .left-sidebar .scroll-sidebar {
    height: 54px;
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav ul {
    display: flex;
  }

  #main-wrapper[data-layout=horizontal] .scroll-sidebar {
    height: auto;
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav {
    display: flex;
    width: 100%;
    padding: 6px;
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav > .sidebar-item {
    margin-right: 6px;
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav > .sidebar-item:last-child {
    margin-right: 0;
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav > .sidebar-item > .sidebar-link {
    padding: 8px 32px 8px 5px;
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav .sidebar-item {
    position: relative;
    width: auto;
    margin-bottom: 0;
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav .sidebar-item .first-level {
    padding: 0;
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav > .sidebar-item > .has-arrow:after {
    transform: rotate(-135deg) translate(0, -50%);
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav > .sidebar-item:last-child > .first-level {
    right: 0;
    left: auto;
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav > .sidebar-item > .two-column + .first-level {
    width: 400px;
  }

  #main-wrapper[data-layout=horizontal]
    .sidebar-nav
    #sidebarnav
    > .sidebar-item
    > .two-column
    + .first-level
    > .sidebar-item {
    float: left;
    vertical-align: top;
    width: 50%;
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav > .sidebar-item ul {
    position: absolute;
    left: 0;
    top: auto;
    width: 220px;
    padding-bottom: 0;
    display: none;
    z-index: 100;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, .1);
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav > .mega-dropdown {
    position: static;
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav > .mega-dropdown .first-level {
    width: 100%;
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav > .mega-dropdown .first-level > li {
    width: 25%;
    float: left;
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav > .sidebar-item:hover {
    background: rgba(0, 0, 0, .035);
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav > .sidebar-item:hover ul.first-level,
  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav > .sidebar-item:hover ul.first-level.collapse {
    display: block;
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav > .sidebar-item:hover ul.first-level.collapse > li,
  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav > .sidebar-item:hover ul.first-level > li {
    background: rgba(0, 0, 0, .035);
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav > .sidebar-item ul.second-level {
    left: 220px;
    top: 0;
  }

  #main-wrapper[data-layout=horizontal]
    .sidebar-nav
    #sidebarnav
    > .sidebar-item
    .first-level
    .right-side-dd
    ul.second-level,
  #main-wrapper[data-layout=horizontal]
    .sidebar-nav
    #sidebarnav
    > .sidebar-item:last-child
    > .first-level
    ul.second-level {
    right: 220px;
    top: 0;
    left: auto;
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav ul.first-level > .sidebar-item:hover ul.second-level {
    display: block;
  }

  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav .badge,
  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav .nav-small-cap,
  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav .sidebar-footer,
  #main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav .user-pro {
    display: none;
  }
}

@media (max-width: 1599.98px) {
  #main-wrapper[data-layout=horizontal][data-sidebartype=mini-sidebar] .left-sidebar,
  #main-wrapper[data-layout=horizontal][data-sidebartype=mini-sidebar] .left-sidebar .sidebar-footer {
    left: -240px;
  }

  #main-wrapper[data-layout=horizontal].show-sidebar .left-sidebar,
  #main-wrapper[data-layout=horizontal].show-sidebar .left-sidebar .sidebar-footer {
    left: 0;
  }

  #main-wrapper[data-layout=horizontal][data-layout=vertical][data-header-position=fixed][data-sidebar-position=fixed]
    .topbar
    .top-navbar
    .navbar-collapse,
  #main-wrapper[data-layout=horizontal][data-layout=vertical][data-sidebar-position=fixed]
    .topbar
    .top-navbar
    .navbar-collapse {
    position: relative;
    top: 70px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  #main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-collapse.collapsing,
  #main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-collapse.show {
    display: block;
    width: 100%;
    border-top: 1px solid #e9ecef;
  }

  #main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-nav {
    flex-direction: row;
  }

  #main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-nav .dropdown {
    position: static;
  }

  #main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
    padding: 0 10px;
  }

  #main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  #main-wrapper[data-layout=horizontal] .topbar .top-navbar .dropdown-menu {
    position: absolute;
    width: 100%;
  }

  #main-wrapper[data-layout=horizontal] .topbar .top-navbar .dropdown-menu .with-arrow {
    display: none;
  }
}

html[dir=rtl] .float-left {
  float: right !important;
}

html[dir=rtl] .float-right {
  float: left !important;
}

html[dir=rtl] .float-none {
  float: none !important;
}

@media (min-width: 576px) {
  html[dir=rtl] .float-sm-left {
    float: right !important;
  }

  html[dir=rtl] .float-sm-right {
    float: left !important;
  }

  html[dir=rtl] .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  html[dir=rtl] .float-md-left {
    float: right !important;
  }

  html[dir=rtl] .float-md-right {
    float: left !important;
  }

  html[dir=rtl] .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  html[dir=rtl] .float-lg-left {
    float: right !important;
  }

  html[dir=rtl] .float-lg-right {
    float: left !important;
  }

  html[dir=rtl] .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1600px) {
  html[dir=rtl] .float-xl-left {
    float: right !important;
  }

  html[dir=rtl] .float-xl-right {
    float: left !important;
  }

  html[dir=rtl] .float-xl-none {
    float: none !important;
  }
}

html[dir=rtl] .mr-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

html[dir=rtl] .ml-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

html[dir=rtl] .mr-1 {
  margin-left: .25rem !important;
  margin-right: 0 !important;
}

html[dir=rtl] .ml-1 {
  margin-right: .25rem !important;
  margin-left: 0 !important;
}

html[dir=rtl] .mr-2 {
  margin-left: .5rem !important;
  margin-right: 0 !important;
}

html[dir=rtl] .ml-2 {
  margin-right: .5rem !important;
  margin-left: 0 !important;
}

html[dir=rtl] .mr-3 {
  margin-left: 1rem !important;
  margin-right: 0 !important;
}

html[dir=rtl] .ml-3 {
  margin-right: 1rem !important;
  margin-left: 0 !important;
}

html[dir=rtl] .mr-4 {
  margin-left: 1.5rem !important;
  margin-right: 0 !important;
}

html[dir=rtl] .ml-4 {
  margin-right: 1.5rem !important;
  margin-left: 0 !important;
}

html[dir=rtl] .mr-5 {
  margin-left: 3rem !important;
  margin-right: 0 !important;
}

html[dir=rtl] .ml-5 {
  margin-right: 3rem !important;
  margin-left: 0 !important;
}

html[dir=rtl] .pr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

html[dir=rtl] .pl-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

html[dir=rtl] .pr-1 {
  padding-left: .25rem !important;
  padding-right: 0 !important;
}

html[dir=rtl] .pl-1 {
  padding-right: .25rem !important;
  padding-left: 0 !important;
}

html[dir=rtl] .pr-2 {
  padding-left: .5rem !important;
  padding-right: 0 !important;
}

html[dir=rtl] .pl-2 {
  padding-right: .5rem !important;
  padding-left: 0 !important;
}

html[dir=rtl] .pr-3 {
  padding-left: 1rem !important;
  padding-right: 0 !important;
}

html[dir=rtl] .pl-3 {
  padding-right: 1rem !important;
  padding-left: 0 !important;
}

html[dir=rtl] .pr-4 {
  padding-left: 1.5rem !important;
  padding-right: 0 !important;
}

html[dir=rtl] .pl-4 {
  padding-right: 1.5rem !important;
  padding-left: 0 !important;
}

html[dir=rtl] .pr-5 {
  padding-left: 3rem !important;
  padding-right: 0 !important;
}

html[dir=rtl] .pl-5 {
  padding-right: 3rem !important;
  padding-left: 0 !important;
}

html[dir=rtl] .mr-auto {
  margin-left: auto !important;
  margin-right: 0 !important;
}

html[dir=rtl] .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}

@media (min-width: 576px) {
  html[dir=rtl] .mr-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-sm-1 {
    margin-left: .25rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-sm-1 {
    margin-right: .25rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-sm-2 {
    margin-left: .5rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-sm-2 {
    margin-right: .5rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-sm-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-sm-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-sm-5 {
    margin-left: 3rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-sm-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .pr-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-sm-1 {
    padding-left: .25rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-sm-1 {
    padding-right: .25rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-sm-2 {
    padding-left: .5rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-sm-2 {
    padding-right: .5rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-sm-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-sm-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-sm-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-sm-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .mr-sm-auto {
    margin-left: auto !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
}

@media (min-width: 768px) {
  html[dir=rtl] .mr-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-md-1 {
    margin-left: .25rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-md-1 {
    margin-right: .25rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-md-2 {
    margin-left: .5rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-md-2 {
    margin-right: .5rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-md-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-md-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-md-5 {
    margin-left: 3rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-md-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .pr-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-md-1 {
    padding-left: .25rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-md-1 {
    padding-right: .25rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-md-2 {
    padding-left: .5rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-md-2 {
    padding-right: .5rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-md-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-md-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-md-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-md-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .mr-md-auto {
    margin-left: auto !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
}

@media (min-width: 992px) {
  html[dir=rtl] .mr-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-lg-1 {
    margin-left: .25rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-lg-1 {
    margin-right: .25rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-lg-2 {
    margin-left: .5rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-lg-2 {
    margin-right: .5rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-lg-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-lg-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-lg-5 {
    margin-left: 3rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-lg-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .pr-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-lg-1 {
    padding-left: .25rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-lg-1 {
    padding-right: .25rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-lg-2 {
    padding-left: .5rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-lg-2 {
    padding-right: .5rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-lg-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-lg-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-lg-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-lg-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .mr-lg-auto {
    margin-left: auto !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
}

@media (min-width: 1600px) {
  html[dir=rtl] .mr-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-xl-1 {
    margin-left: .25rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-xl-1 {
    margin-right: .25rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-xl-2 {
    margin-left: .5rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-xl-2 {
    margin-right: .5rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-xl-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-xl-3 {
    margin-right: 1rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .mr-xl-5 {
    margin-left: 3rem !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-xl-5 {
    margin-right: 3rem !important;
    margin-left: 0 !important;
  }

  html[dir=rtl] .pr-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-xl-1 {
    padding-left: .25rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-xl-1 {
    padding-right: .25rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-xl-2 {
    padding-left: .5rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-xl-2 {
    padding-right: .5rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-xl-3 {
    padding-left: 1rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-xl-3 {
    padding-right: 1rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .pr-xl-5 {
    padding-left: 3rem !important;
    padding-right: 0 !important;
  }

  html[dir=rtl] .pl-xl-5 {
    padding-right: 3rem !important;
    padding-left: 0 !important;
  }

  html[dir=rtl] .mr-xl-auto {
    margin-left: auto !important;
    margin-right: 0 !important;
  }

  html[dir=rtl] .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
}

html[dir=rtl] .text-left {
  text-align: right !important;
}

html[dir=rtl] .text-right {
  text-align: left !important;
}

html[dir=rtl] .text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  html[dir=rtl] .text-sm-left {
    text-align: right !important;
  }

  html[dir=rtl] .text-sm-right {
    text-align: left !important;
  }

  html[dir=rtl] .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  html[dir=rtl] .text-md-left {
    text-align: right !important;
  }

  html[dir=rtl] .text-md-right {
    text-align: left !important;
  }

  html[dir=rtl] .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  html[dir=rtl] .text-lg-left {
    text-align: right !important;
  }

  html[dir=rtl] .text-lg-right {
    text-align: left !important;
  }

  html[dir=rtl] .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1600px) {
  html[dir=rtl] .text-xl-left {
    text-align: right !important;
  }

  html[dir=rtl] .text-xl-right {
    text-align: left !important;
  }

  html[dir=rtl] .text-xl-center {
    text-align: center !important;
  }
}

html[dir=rtl] .border-left {
  border-right: 1px solid #e9ecef !important;
  border-left: 0 !important;
}

html[dir=rtl] .border-right {
  border-left: 1px solid #e9ecef !important;
  border-right: 0 !important;
}

html[dir=rtl] .border-right-0 {
  border-left: 0 !important;
}

html[dir=rtl] .border-left-0 {
  border-right: 0 !important;
}

html[dir=rtl] .rounded-right {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

html[dir=rtl] .rounded-left {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

html[dir=rtl] .input-group > .custom-select:not(:last-child),
html[dir=rtl] .input-group > .form-control:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

html[dir=rtl] .input-group > .custom-select:not(:first-child),
html[dir=rtl] .input-group > .form-control:not(:first-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

html[dir=rtl] .input-group > .custom-file {
  display: flex;
  align-items: center;
}

html[dir=rtl] .input-group > .custom-file:not(:last-child) .custom-file-label,
html[dir=rtl] .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

html[dir=rtl] .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

html[dir=rtl] .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
html[dir=rtl] .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
html[dir=rtl] .input-group > .input-group-append:not(:last-child) > .btn,
html[dir=rtl] .input-group > .input-group-append:not(:last-child) > .input-group-text,
html[dir=rtl] .input-group > .input-group-prepend > .btn,
html[dir=rtl] .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

html[dir=rtl] .input-group > .input-group-prepend:not(:first-child) > .btn,
html[dir=rtl] .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

html[dir=rtl] .input-group .form-control:not(:first-child),
html[dir=rtl] .input-group .form-control:not(:last-child) {
  border-radius: 0 !important;
}

html[dir=rtl] .input-group > .input-group-append > .btn,
html[dir=rtl] .input-group > .input-group-append > .input-group-text,
html[dir=rtl] .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
html[dir=rtl] .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

html[dir=rtl] body .m-t-5 {
  margin-top: 5px;
}

html[dir=rtl] body .m-b-5 {
  margin-bottom: 5px;
}

html[dir=rtl] body .m-r-5 {
  margin-left: 5px;
}

html[dir=rtl] body .m-l-5 {
  margin-right: 5px;
}

html[dir=rtl] body .p-t-5 {
  padding-top: 5px;
}

html[dir=rtl] body .p-b-5 {
  padding-bottom: 5px;
}

html[dir=rtl] body .p-r-5 {
  padding-left: 5px;
}

html[dir=rtl] body .p-l-5 {
  padding-right: 5px;
}

html[dir=rtl] body .p-5 {
  padding: 5px;
}

html[dir=rtl] body .m-5 {
  margin: 5px;
}

html[dir=rtl] body .m-t-10 {
  margin-top: 10px;
}

html[dir=rtl] body .m-b-10 {
  margin-bottom: 10px;
}

html[dir=rtl] body .m-r-10 {
  margin-left: 10px;
}

html[dir=rtl] body .m-l-10 {
  margin-right: 10px;
}

html[dir=rtl] body .p-t-10 {
  padding-top: 10px;
}

html[dir=rtl] body .p-b-10 {
  padding-bottom: 10px;
}

html[dir=rtl] body .p-r-10 {
  padding-left: 10px;
}

html[dir=rtl] body .p-l-10 {
  padding-right: 10px;
}

html[dir=rtl] body .p-10 {
  padding: 10px;
}

html[dir=rtl] body .m-10 {
  margin: 10px;
}

html[dir=rtl] body .m-t-15 {
  margin-top: 15px;
}

html[dir=rtl] body .m-b-15 {
  margin-bottom: 15px;
}

html[dir=rtl] body .m-r-15 {
  margin-left: 15px;
}

html[dir=rtl] body .m-l-15 {
  margin-right: 15px;
}

html[dir=rtl] body .p-t-15 {
  padding-top: 15px;
}

html[dir=rtl] body .p-b-15 {
  padding-bottom: 15px;
}

html[dir=rtl] body .p-r-15 {
  padding-left: 15px;
}

html[dir=rtl] body .p-l-15 {
  padding-right: 15px;
}

html[dir=rtl] body .p-15 {
  padding: 15px;
}

html[dir=rtl] body .m-15 {
  margin: 15px;
}

html[dir=rtl] body .m-t-20 {
  margin-top: 20px;
}

html[dir=rtl] body .m-b-20 {
  margin-bottom: 20px;
}

html[dir=rtl] body .m-r-20 {
  margin-left: 20px;
}

html[dir=rtl] body .m-l-20 {
  margin-right: 20px;
}

html[dir=rtl] body .p-t-20 {
  padding-top: 20px;
}

html[dir=rtl] body .p-b-20 {
  padding-bottom: 20px;
}

html[dir=rtl] body .p-r-20 {
  padding-left: 20px;
}

html[dir=rtl] body .p-l-20 {
  padding-right: 20px;
}

html[dir=rtl] body .p-20 {
  padding: 20px;
}

html[dir=rtl] body .m-20 {
  margin: 20px;
}

html[dir=rtl] body .m-t-25 {
  margin-top: 25px;
}

html[dir=rtl] body .m-b-25 {
  margin-bottom: 25px;
}

html[dir=rtl] body .m-r-25 {
  margin-left: 25px;
}

html[dir=rtl] body .m-l-25 {
  margin-right: 25px;
}

html[dir=rtl] body .p-t-25 {
  padding-top: 25px;
}

html[dir=rtl] body .p-b-25 {
  padding-bottom: 25px;
}

html[dir=rtl] body .p-r-25 {
  padding-left: 25px;
}

html[dir=rtl] body .p-l-25 {
  padding-right: 25px;
}

html[dir=rtl] body .p-25 {
  padding: 25px;
}

html[dir=rtl] body .m-25 {
  margin: 25px;
}

html[dir=rtl] body .m-t-30 {
  margin-top: 30px;
}

html[dir=rtl] body .m-b-30 {
  margin-bottom: 30px;
}

html[dir=rtl] body .m-r-30 {
  margin-left: 30px;
}

html[dir=rtl] body .m-l-30 {
  margin-right: 30px;
}

html[dir=rtl] body .p-t-30 {
  padding-top: 30px;
}

html[dir=rtl] body .p-b-30 {
  padding-bottom: 30px;
}

html[dir=rtl] body .p-r-30 {
  padding-left: 30px;
}

html[dir=rtl] body .p-l-30 {
  padding-right: 30px;
}

html[dir=rtl] body .p-30 {
  padding: 30px;
}

html[dir=rtl] body .m-30 {
  margin: 30px;
}

html[dir=rtl] body .m-t-40 {
  margin-top: 40px;
}

html[dir=rtl] body .m-b-40 {
  margin-bottom: 40px;
}

html[dir=rtl] body .m-r-40 {
  margin-left: 40px;
}

html[dir=rtl] body .m-l-40 {
  margin-right: 40px;
}

html[dir=rtl] body .p-t-40 {
  padding-top: 40px;
}

html[dir=rtl] body .p-b-40 {
  padding-bottom: 40px;
}

html[dir=rtl] body .p-r-40 {
  padding-left: 40px;
}

html[dir=rtl] body .p-l-40 {
  padding-right: 40px;
}

html[dir=rtl] body .p-40 {
  padding: 40px;
}

html[dir=rtl] body .m-40 {
  margin: 40px;
}

html[dir=rtl] body .m-0-0 {
  margin: 0;
}

html[dir=rtl] body .m-t-0 {
  margin-top: 0;
}

html[dir=rtl] body .m-r-0 {
  margin-left: 0;
}

html[dir=rtl] body .m-b-0 {
  margin-bottom: 0;
}

html[dir=rtl] body .m-l-0 {
  margin-right: 0;
}

html[dir=rtl] body .p-0-0 {
  padding: 0;
}

html[dir=rtl] body .p-t-0 {
  padding-top: 0;
}

html[dir=rtl] body .p-r-0 {
  padding-left: 0;
}

html[dir=rtl] body .p-b-0 {
  padding-bottom: 0;
}

html[dir=rtl] body .p-l-0 {
  padding-right: 0;
}

html[dir=rtl] body {
  text-align: right;
  direction: rtl;
}

html[dir=rtl] .custom-control {
  padding-left: 0;
  padding-right: 1.5rem;
}

html[dir=rtl] .custom-control-label::after,
html[dir=rtl] .custom-control-label::before {
  left: auto;
  right: -25px;
}

html[dir=rtl] .list-group {
  padding: 0;
}

html[dir=rtl] .close {
  float: left;
}

html[dir=rtl] .custom-file-label {
  left: auto;
  right: 0;
  width: 100%;
}

html[dir=rtl] .custom-file-label::after {
  right: auto;
  left: 0;
}

html[dir=rtl] .checkbox {
  padding-left: 0;
  padding-right: 20px;
}

html[dir=rtl] .checkbox input[type=checkbox] {
  right: 0;
  left: auto;
}

html[dir=rtl] .checkbox label {
  padding-left: 0;
  padding-right: 5px;
}

html[dir=rtl] .checkbox label::before {
  left: auto;
  right: 0;
}

html[dir=rtl] .checkbox label::after {
  right: 3px;
  left: auto;
}

html[dir=rtl] .radio {
  padding-left: 0;
  padding-right: 20px;
}

html[dir=rtl] .radio input[type=radio] {
  right: 0;
  left: auto;
}

html[dir=rtl] .radio label {
  padding-left: 0;
  padding-right: 5px;
}

html[dir=rtl] .radio label::before {
  left: auto;
  right: 0;
}

html[dir=rtl] .radio label::after {
  right: 5px;
  left: auto;
}

html[dir=rtl] .radio label::after,
html[dir=rtl] .radio label::before {
  margin-left: 0;
  margin-right: -20px;
}

html[dir=rtl] .modal-header .close {
  float: left;
  margin: -1rem auto -1rem -1rem;
}

html[dir=rtl] .modal-footer > :not(:last-child) {
  margin-left: .25rem;
  margin-right: 0;
}

html[dir=rtl] .alert-dismissible {
  padding-left: 3.8125rem;
  padding-right: 1.25rem;
}

html[dir=rtl] .alert-dismissible .close {
  left: 0;
  right: auto;
}

html[dir=rtl] .dropdown-menu-right {
  right: auto !important;
  left: 0 !important;
}

@media (min-width: 768px) {
  html[dir=rtl] .topbar .dropdown-menu {
    left: unset;
    right: 0;
  }
}

html[dir=rtl] .dropdown-menu {
  text-align: right;
}

html[dir=rtl] .list-unstyled,
html[dir=rtl] .nav,
html[dir=rtl] .navbar-nav,
html[dir=rtl] .pagination {
  padding-right: 0;
}

html[dir=rtl] .topbar .navbar-collapse {
  padding: 0 0 0 10px;
}

html[dir=rtl] .user-profile .profile-img {
  margin-right: 30px;
  margin-left: auto;
}

html[dir=rtl] .user-profile .profile-text > a:after {
  left: 20px;
  right: auto;
}

html[dir=rtl] .topbar .top-navbar .navbar-header .navbar-brand .logo-icon {
  margin-right: 0;
}

html[dir=rtl] .topbar .dropdown-menu.dropdown-menu-right .with-arrow {
  left: 0;
  right: auto;
}

html[dir=rtl] .search-box .app-search .srh-btn,
html[dir=rtl] .topbar .dropdown-menu.dropdown-menu-right .with-arrow > span {
  left: 20px;
  right: auto;
}

html[dir=rtl] .sidebar-nav .has-arrow::after {
  margin-left: 0;
  margin-right: 10px;
  right: auto;
  left: 15px;
}

html[dir=rtl] .sidebar-nav ul .sidebar-item .sidebar-link {
  padding: 14px 8px 14px 35px;
}

html[dir=rtl] .sidebar-nav ul .sidebar-item .sidebar-link .badge {
  left: 34px;
  right: auto;
}

html[dir=rtl] .customizer {
  left: -280px;
  right: auto;
}

html[dir=rtl] .customizer.show-service-panel {
  left: 0;
  right: auto;
}

html[dir=rtl] .customizer.show-service-panel .service-panel-toggle {
  border-radius: 0 30px 30px 0;
  right: -54px;
}

html[dir=rtl] .customizer .service-panel-toggle {
  right: -75px;
  left: auto;
}

html[dir=rtl] .message-box .message-widget a .user-img {
  margin: 0 0 15px 10px;
}

html[dir=rtl] .mailbox .message-center .message-item .mail-contnet {
  padding-right: 10px;
  padding-left: 0;
}

html[dir=rtl] .feed-widget .feed-body .feed-item > .feed-icon {
  margin-right: 0;
  margin-left: 10px;
}

html[dir=rtl] .left-part {
  border-left: 1px solid #e9ecef;
  border-right: none;
}

html[dir=rtl] .left-part .show-left-part {
  left: -41px;
  right: auto;
}

html[dir=rtl] .right-part {
  margin-right: 260px;
  margin-left: 0;
}

html[dir=rtl] .reverse-mode .left-part {
  right: auto;
  left: 0;
}

html[dir=rtl] .reverse-mode .show-left-part {
  left: auto;
  right: -41px;
}

html[dir=rtl] .reverse-mode .right-part {
  margin-right: 0;
  margin-left: 260px;
}

html[dir=rtl] .profiletimeline {
  padding-right: 40px;
  padding-left: 0;
  margin: 40px 30px 0 10px;
  border-right: 1px solid #e9ecef;
  border-left: none;
}

html[dir=rtl] .profiletimeline .sl-left {
  margin-right: -60px !important;
  margin-left: 15px;
  float: right;
}

html[dir=rtl] div.dataTables_wrapper div.dataTables_filter {
  text-align: left;
}

html[dir=rtl] table.table-bordered.dataTable td,
html[dir=rtl] table.table-bordered.dataTable th {
  border-left-width: 1px;
}

html[dir=rtl] div.dataTables_wrapper div.dataTables_filter input {
  margin-right: .5rem;
  margin-left: 0;
}

html[dir=rtl] div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
  padding-left: 1px;
}

html[dir=rtl] .lobilists .lobilist-actions {
  right: auto;
  left: 8px;
}

html[dir=rtl] .lobilists .lobilist-item .todo-actions {
  right: auto;
  left: 4px;
}

html[dir=rtl] .lobilists .lobilist-check {
  left: auto;
  right: 12px;
}

html[dir=rtl] .lobilists .lobilist-item,
html[dir=rtl] .lobilists .lobilist-item-placeholder {
  padding-left: 0;
  padding-right: 35px;
}

html[dir=rtl] .lobilists .lobilist-item .drag-handler {
  left: auto;
  right: 0;
}

html[dir=rtl] .lobilists .lobilist-placeholder,
html[dir=rtl] .lobilists .lobilist-wrapper {
  margin-left: 16px;
  margin-right: 0;
}

html[dir=rtl] .datepicker {
  direction: rtl;
}

html[dir=rtl] .minicolors .minicolors-grid {
  right: 22px;
  left: auto;
}

html[dir=rtl] .wizard > .actions > ul > li,
html[dir=rtl] .wizard > .steps > ul > li {
  float: right;
}

html[dir=rtl] .wizard-content .wizard > .steps > ul > li:after {
  right: auto;
  left: 0;
}

html[dir=rtl] .wizard-content .wizard > .steps > ul > li:before {
  left: auto;
  right: 0;
}

html[dir=rtl] .wizard-content .wizard.vertical > .steps {
  float: right;
}

html[dir=rtl] .css-bar > i {
  margin-left: 0;
  margin-right: 5px;
}

html[dir=rtl] .treeview span.icon {
  margin-left: 5px;
  margin-right: 0;
}

html[dir=rtl] .dz-hidden-input {
  display: none;
}

html[dir=rtl] .sidebar-nav ul .sidebar-item .sidebar-link {
  padding: 8px 8px 8px 35px;
}

html[dir=rtl] .sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link {
  padding: 10px 20px 10px 35px;
}

html[dir=rtl] #main-wrapper[data-sidebartype=full] .page-wrapper {
  margin-right: 240px;
  margin-left: 0;
}

html[dir=rtl] #main-wrapper[data-sidebartype=iconbar] .page-wrapper {
  margin-right: 180px;
  margin-left: 0;
}

html[dir=rtl] #main-wrapper[data-sidebartype=overlay] .left-sidebar {
  right: -240px;
  left: auto;
}

html[dir=rtl] #main-wrapper[data-sidebartype=overlay].show-sidebar .left-sidebar {
  right: 0;
  left: auto;
}

@media (min-width: 768px) {
  html[dir=rtl] #main-wrapper[data-sidebar-position=fixed][data-sidebartype=full] .topbar .top-navbar .navbar-collapse,
  html[dir=rtl]
    #main-wrapper[data-sidebar-position=fixed][data-sidebartype=overlay]
    .topbar
    .top-navbar
    .navbar-collapse {
    margin-right: 240px;
    margin-left: 0;
  }

  html[dir=rtl]
    #main-wrapper[data-sidebar-position=fixed][data-sidebartype=mini-sidebar]
    .topbar
    .top-navbar
    .navbar-collapse {
    margin-right: 65px;
    margin-left: 0;
  }

  html[dir=rtl]
    #main-wrapper[data-sidebar-position=fixed][data-sidebartype=iconbar]
    .topbar
    .top-navbar
    .navbar-collapse {
    margin-right: 180px;
    margin-left: 0;
  }

  html[dir=rtl] #main-wrapper[data-sidebartype=mini-sidebar] .page-wrapper {
    margin-left: 0;
    margin-right: 65px;
  }

  html[dir=rtl] #main-wrapper[data-sidebartype=mini-sidebar] .user-profile .profile-img {
    margin-right: 9px;
    margin-left: 0;
  }

  html[dir=rtl]
    #main-wrapper[data-sidebartype=mini-sidebar]
    .left-sidebar:hover
    .sidebar-nav
    ul
    .sidebar-item
    .sidebar-link {
    padding: 8px 8px 8px 35px;
  }

  html[dir=rtl]
    #main-wrapper[data-sidebartype=mini-sidebar]
    .left-sidebar:hover
    .sidebar-nav
    ul
    .sidebar-item
    .first-level
    .sidebar-item
    .sidebar-link {
    padding: 10px 20px 10px 35px;
  }
}

@media (max-width: 767.98px) {
  html[dir=rtl] #main-wrapper[data-sidebartype=mini-sidebar] .left-sidebar {
    right: -240px;
    left: auto;
  }

  html[dir=rtl] #main-wrapper.show-sidebar .left-sidebar {
    right: 0;
    left: auto;
  }

  html[dir=rtl] #main-wrapper.show-sidebar .left-sidebar .sidebar-footer {
    right: 0;
    left: auto;
  }
}

@media (max-width: 767.98px) {
  html[dir=rtl] .left-part {
    right: -260px;
    left: auto;
  }

  html[dir=rtl] .left-part.show-panel {
    right: 0;
    left: auto;
  }

  html[dir=rtl] .right-part {
    margin-right: 0;
  }
}

html[dir=rtl] .stylish-table tbody tr.active,
html[dir=rtl] .stylish-table tbody tr:hover {
  border-left: 0;
  border-right: 4px solid #1e88e5;
}

html[dir=rtl] .comment-widgets .comment-row {
  border-left: 0;
  border-right: 3px solid transparent;
}

html[dir=rtl] .comment-widgets .comment-row:hover {
  border-left: 0;
  border-right: 3px solid #1e88e5;
}

html[dir=rtl] .add-ct-btn {
  left: 4px;
  right: auto;
}

html[dir=rtl] .card .card-actions {
  float: left;
}

html[dir=rtl] #main-wrapper[data-layout=horizontal] .sidebar-nav ul .sidebar-item .sidebar-link {
  border-right: 0;
}

html[dir=rtl] #main-wrapper[data-layout=horizontal] .sidebar-nav ul .sidebar-item.selected > .sidebar-link {
  border-right: 0;
}

html[dir=rtl]
  #main-wrapper[data-layout=horizontal]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link {
  border-left: 0 !important;
}

html[dir=rtl]
  #main-wrapper[data-layout=horizontal]
  #main-wrapper[data-sidebartype=full][data-layout=horizontal]
  .page-wrapper {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  html[dir=rtl]
    #main-wrapper[data-sidebartype=full][data-layout=horizontal]
    .sidebar-nav
    #sidebarnav
    > .sidebar-item
    ul {
    right: 0;
    left: auto;
  }

  html[dir=rtl]
    #main-wrapper[data-sidebartype=full][data-layout=horizontal]
    .sidebar-nav
    #sidebarnav
    > .sidebar-item
    ul
    .second-level {
    right: 220px;
    left: auto;
  }

  html[dir=rtl]
    #main-wrapper[data-sidebartype=full][data-layout=horizontal]
    .sidebar-nav
    #sidebarnav
    > .sidebar-item:last-child
    .second-level {
    left: 220px;
    right: auto;
  }

  html[dir=rtl] #main-wrapper[data-layout=horizontal][data-boxed-layout=boxed] .page-wrapper,
  html[dir=rtl] #main-wrapper[data-layout=horizontal][data-boxed-layout=boxed] .scroll-sidebar,
  html[dir=rtl] #main-wrapper[data-layout=horizontal][data-boxed-layout=boxed] .top-navbar {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }

  html[dir=rtl] #main-wrapper[data-layout=horizontal][data-boxed-layout=full] .page-wrapper {
    margin-right: 0;
  }

  html[dir=rtl]
    #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed][data-sidebartype=full]
    .topbar
    .top-navbar
    .navbar-collapse {
    margin-right: 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  html[dir=rtl]
    #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed][data-sidebartype=mini-sidebar]
    .topbar
    .top-navbar
    .navbar-collapse {
    margin-right: 0;
  }
}

@media (max-width: 991.98px) {
  html[dir=rtl] #main-wrapper[data-layout=horizontal][data-sidebartype=mini-sidebar] .left-sidebar {
    right: -240px;
    left: auto;
  }

  html[dir=rtl] #main-wrapper[data-layout=horizontal].show-sidebar .left-sidebar {
    right: 0;
    left: auto;
  }

  html[dir=rtl] #main-wrapper[data-layout=horizontal].show-sidebar .left-sidebar .sidebar-footer {
    right: 0;
    left: auto;
  }
}

body[data-theme=dark] {
  color: #b2b9bf;
  background: #212529;
}

body[data-theme=dark] .h1,
body[data-theme=dark] .h2,
body[data-theme=dark] .h3,
body[data-theme=dark] .h4,
body[data-theme=dark] .h5,
body[data-theme=dark] .h6,
body[data-theme=dark] h1,
body[data-theme=dark] h2,
body[data-theme=dark] h3,
body[data-theme=dark] h4,
body[data-theme=dark] h5,
body[data-theme=dark] h6 {
  color: #b2b9bf;
}

body[data-theme=dark] a.link {
  color: #e9ecef;
}

body[data-theme=dark] a.link:focus,
body[data-theme=dark] a.link:hover {
  color: #009efb;
}

body[data-theme=dark] .bg-white {
  background-color: #000 !important;
}

body[data-theme=dark] .bg-dark {
  background-color: #6c757d !important;
}

body[data-theme=dark] .bg-light-info {
  background-color: #213b5f !important;
}

body[data-theme=dark] .bg-light-success {
  background-color: #1d3c3b !important;
}

body[data-theme=dark] .bg-light-primary {
  background-color: #212d5f !important;
}

body[data-theme=dark] .bg-light-inverse {
  background-color: #020510 !important;
}

body[data-theme=dark] .text-dark {
  color: #e9ecef !important;
}

body[data-theme=dark] .badge-light {
  background-color: #343a40;
  color: #b2b9bf;
}

body[data-theme=dark] .btn-light {
  background-color: #343a40;
  border-color: #343a40;
  color: #b2b9bf;
}

body[data-theme=dark] .custom-control-label::before {
  background-color: #6c757d;
}

body[data-theme=dark] .floating-labels label {
  color: #e9ecef !important;
}

body[data-theme=dark] .card,
body[data-theme=dark] .css-bar:after,
body[data-theme=dark] .custom-file-label,
body[data-theme=dark] .custom-select,
body[data-theme=dark] .dtp > .dtp-content,
body[data-theme=dark] .form-control,
body[data-theme=dark] .jsgrid-header-row > .jsgrid-header-cell,
body[data-theme=dark] .jsgrid-row > .jsgrid-cell,
body[data-theme=dark] .list-group-item,
body[data-theme=dark] .lobilists .lobilist,
body[data-theme=dark] .modal-content,
body[data-theme=dark] .nav-tabs .nav-item.show .nav-link,
body[data-theme=dark] .nav-tabs .nav-link.active,
body[data-theme=dark] .note-editor.note-frame .note-editing-area .note-editable,
body[data-theme=dark] .note-editor.note-frame .note-statusbar,
body[data-theme=dark] .page-item.disabled .page-link,
body[data-theme=dark] .page-link,
body[data-theme=dark] .select2-container--classic .select2-selection--single,
body[data-theme=dark] .select2-container--default .select2-selection--multiple,
body[data-theme=dark] .select2-container--default .select2-selection--single {
  background-color: #272b34;
}

body[data-theme=dark] .mailbox .message-center .message-item.active,
body[data-theme=dark] .mailbox .message-center .message-item:hover {
  background: #323743;
}

body[data-theme=dark] .jvectormap-container {
  background-color: #272b34 !important;
}

body[data-theme=dark] .dropdown-item:focus,
body[data-theme=dark] .dropdown-item:hover,
body[data-theme=dark] .dropzone,
body[data-theme=dark] .footer,
body[data-theme=dark] .mailbox .message-center .message-item:hover,
body[data-theme=dark] .picker__button--clear,
body[data-theme=dark] .picker__button--close,
body[data-theme=dark] .picker__button--today {
  background: #272b34;
}

body[data-theme=dark] .footer {
  border-top: 1px solid #4f5467;
}

body[data-theme=dark] .card-footer,
body[data-theme=dark] .left-part,
body[data-theme=dark] .right-part.mail-compose,
body[data-theme=dark] .right-part.mail-details,
body[data-theme=dark] .right-part.mail-list,
body[data-theme=dark] .select2-dropdown,
body[data-theme=dark] .typeahead.form-control {
  background-color: #272b34 !important;
}

body[data-theme=dark] .page-titles {
  background: #323743;
}

body[data-theme=dark] #main-wrapper,
body[data-theme=dark] .breadcrumb,
body[data-theme=dark] .cke_toolbar_separator,
body[data-theme=dark] .dropdown-menu,
body[data-theme=dark] .jumbotron,
body[data-theme=dark] .page-wrapper,
body[data-theme=dark] .progress,
body[data-theme=dark] .wizard-content .wizard > .steps .step,
body[data-theme=dark] .wizard:not(.wizard-circle) > .actions .disabled a,
body[data-theme=dark] .wizard:not(.wizard-circle) > .actions .disabled a:active,
body[data-theme=dark] .wizard:not(.wizard-circle) > .actions .disabled a:hover,
body[data-theme=dark] .wizard:not(.wizard-circle) > .content,
body[data-theme=dark] .wizard:not(.wizard-circle) > .steps .disabled a,
body[data-theme=dark] .wizard:not(.wizard-circle) > .steps .disabled a:active,
body[data-theme=dark] .wizard:not(.wizard-circle) > .steps .disabled a:hover {
  background-color: #323743;
}

body[data-theme=dark] .fc td,
body[data-theme=dark] .fc th {
  background: #323743 !important;
  border-color: #272b34 !important;
}

body[data-theme=dark] .fc-state-default {
  text-shadow: none;
}

body[data-theme=dark] .daterangepicker,
body[data-theme=dark] .daterangepicker .calendar-table,
body[data-theme=dark] .fc-toolbar .fc-button.fc-state-active,
body[data-theme=dark] .fc-unthemed td.fc-today,
body[data-theme=dark] .picker--time .picker__box,
body[data-theme=dark] .picker__box,
body[data-theme=dark] .picker__list-item {
  background: #343a40;
}

body[data-theme=dark] .fc-unthemed .fc-divider,
body[data-theme=dark] .fc-unthemed .fc-list-heading td,
body[data-theme=dark] .fc-unthemed .fc-popover .fc-header {
  background: #b2b9bf;
}

body[data-theme=dark] .daterangepicker td.off,
body[data-theme=dark] .daterangepicker td.off.end-date,
body[data-theme=dark] .daterangepicker td.off.in-range,
body[data-theme=dark] .daterangepicker td.off.start-date {
  background: #000;
}

body[data-theme=dark] .timeline:before {
  background-color: #343a40;
}

body[data-theme=dark] .cd-horizontal-timeline .events {
  background: #343a40;
}

body[data-theme=dark] .bootstrap-switch .bootstrap-switch-label,
body[data-theme=dark] .cd-horizontal-timeline .events a::after,
body[data-theme=dark] .cke_top,
body[data-theme=dark] .css-bar > i,
body[data-theme=dark] .dp-off,
body[data-theme=dark] .f-icon:hover,
body[data-theme=dark] .feeds .feed-item:hover,
body[data-theme=dark] .footable-odd,
body[data-theme=dark] .if-icon:hover,
body[data-theme=dark] .jsgrid-alt-row > .jsgrid-cell,
body[data-theme=dark] .jsgrid-filter-row > .jsgrid-cell,
body[data-theme=dark] .m-icon:hover,
body[data-theme=dark] .mce-menu-item.mce-disabled,
body[data-theme=dark] .mce-menu-item.mce-disabled:hover,
body[data-theme=dark] .message-box .message-widget a:hover,
body[data-theme=dark] .noUi-target,
body[data-theme=dark] .noUi-tooltip,
body[data-theme=dark] .sl-icon:hover,
body[data-theme=dark] .striped-rows .row:nth-child(odd),
body[data-theme=dark] .t-icon:hover,
body[data-theme=dark] .w-icon:hover,
body[data-theme=dark] div.mce-edit-area {
  background: #212529 !important;
}

body[data-theme=dark] .bg-light,
body[data-theme=dark] .chat-list .chat-item .chat-content .box.bg-light-info,
body[data-theme=dark] .custom-file-label::after,
body[data-theme=dark] .customizer,
body[data-theme=dark] .input-group-text,
body[data-theme=dark] .mce-panel,
body[data-theme=dark] .myadmin-dd .dd-list .dd-item .dd-handle,
body[data-theme=dark] .myadmin-dd-empty .dd-list .dd3-content,
body[data-theme=dark] .myadmin-dd-empty .dd-list .dd3-handle,
body[data-theme=dark] .table .thead-light th,
body[data-theme=dark] .table-hover tbody tr:hover,
body[data-theme=dark] pre[class*=language-] {
  background-color: #272c33 !important;
}

body[data-theme=dark] .mce-menubar .mce-menubtn.mce-active,
body[data-theme=dark] .mce-menubar .mce-menubtn:focus,
body[data-theme=dark] .mce-menubar .mce-menubtn:hover {
  background: #343a40;
  border-color: #4f5467;
}

body[data-theme=dark] .mce-btn {
  background: #343a40;
  text-shadow: none;
}

body[data-theme=dark] .mce-btn button {
  color: #b2b9bf;
}

body[data-theme=dark] .breadcrumb-item.active,
body[data-theme=dark] .chat-list .chat-item .chat-content .box.bg-light-info,
body[data-theme=dark] .cke_reset_all,
body[data-theme=dark] .cke_reset_all *,
body[data-theme=dark] .cke_reset_all a,
body[data-theme=dark] .cke_reset_all textarea,
body[data-theme=dark] .custom-file-label::after,
body[data-theme=dark] .custom-select,
body[data-theme=dark] .dropdown-item,
body[data-theme=dark] .dropdown-menu,
body[data-theme=dark] .dtp .dtp-picker-time > a,
body[data-theme=dark] .dtp table.dtp-picker-days tr > td > a,
body[data-theme=dark] .form-control,
body[data-theme=dark] .input-group-text,
body[data-theme=dark] .lobilists .btn-link,
body[data-theme=dark] .mce-ico,
body[data-theme=dark] .mce-menubar .mce-menubtn button span,
body[data-theme=dark] .message-box .message-widget a .mail-contnet .mail-desc,
body[data-theme=dark] .message-box .message-widget a .mail-contnet .time,
body[data-theme=dark] .nav-tabs .nav-item.show .nav-link,
body[data-theme=dark] .nav-tabs .nav-link.active,
body[data-theme=dark] .nav-tabs .nav-link:focus,
body[data-theme=dark] .nav-tabs .nav-link:hover,
body[data-theme=dark] .noUi-tooltip,
body[data-theme=dark] .note-editor.note-frame .note-editing-area .note-editable,
body[data-theme=dark] .page-wrapper,
body[data-theme=dark] .picker,
body[data-theme=dark] .select2-container--classic .select2-selection--single,
body[data-theme=dark] .select2-container--default .select2-selection--multiple,
body[data-theme=dark] .select2-container--default .select2-selection--single,
body[data-theme=dark] .select2-container--default .select2-selection--single .select2-selection__arrow,
body[data-theme=dark] .select2-container--default .select2-selection--single .select2-selection__rendered,
body[data-theme=dark] .table,
body[data-theme=dark] .table .thead-light th,
body[data-theme=dark] ul.list-style-none li a {
  color: #b2b9bf;
}

body[data-theme=dark] .lobilists .lobilist-item-title,
body[data-theme=dark] .mailbox .message-center .message-item .message-title {
  color: #fff;
}

body[data-theme=dark] .email-app .email-table .selected {
  background: #213b5f;
}

body[data-theme=dark] .grid-structure .grid-container {
  background-color: #323743;
}

body[data-theme=dark] .customtab li a.nav-link.active,
body[data-theme=dark] .profile-tab li a.nav-link.active {
  color: #1e88e5 !important;
  border-bottom: 2px solid #1e88e5 !important;
}

body[data-theme=dark] .b-form .row,
body[data-theme=dark] .bootstrap-switch,
body[data-theme=dark] .border,
body[data-theme=dark] .border-bottom,
body[data-theme=dark] .border-left,
body[data-theme=dark] .border-right,
body[data-theme=dark] .border-top,
body[data-theme=dark] .card-group .card,
body[data-theme=dark] .cd-horizontal-timeline .events a::after,
body[data-theme=dark] .cd-timeline-navigation a,
body[data-theme=dark] .cke_chrome,
body[data-theme=dark] .cke_toolgroup a.cke_button.cke_button_disabled:hover:last-child:after,
body[data-theme=dark] .cke_toolgroup a.cke_button:last-child:after,
body[data-theme=dark] .custom-file-label,
body[data-theme=dark] .custom-file-label::after,
body[data-theme=dark] .custom-select,
body[data-theme=dark] .datepaginator .pagination li a,
body[data-theme=dark] .datepaginator-lg .pagination li a,
body[data-theme=dark] .datepaginator-sm .pagination li a,
body[data-theme=dark] .daterangepicker,
body[data-theme=dark] .daterangepicker .calendar-table,
body[data-theme=dark] .daterangepicker .drp-buttons,
body[data-theme=dark] .dropdown-divider,
body[data-theme=dark] .dropdown-menu,
body[data-theme=dark] .dropzone,
body[data-theme=dark] .e-campaign .c-cost,
body[data-theme=dark] .fixed-table-container,
body[data-theme=dark] .form-control,
body[data-theme=dark] .input-group-text,
body[data-theme=dark] .jsgrid-edit-row > .jsgrid-cell,
body[data-theme=dark] .jsgrid-filter-row > .jsgrid-cell,
body[data-theme=dark] .jsgrid-grid-body,
body[data-theme=dark] .jsgrid-grid-header,
body[data-theme=dark] .jsgrid-header-row > .jsgrid-header-cell,
body[data-theme=dark] .jsgrid-insert-row > .jsgrid-cell,
body[data-theme=dark] .jsgrid-pager-current-page,
body[data-theme=dark] .jsgrid-pager-nav-button a,
body[data-theme=dark] .jsgrid-pager-page a,
body[data-theme=dark] .left-part,
body[data-theme=dark] .list-group-item,
body[data-theme=dark] .lobilists .lobilist,
body[data-theme=dark] .lobilists .lobilist-footer,
body[data-theme=dark] .lobilists .lobilist-form-footer,
body[data-theme=dark] .lobilists .lobilist-item,
body[data-theme=dark] .lobilists .lobilist-item .drag-handler,
body[data-theme=dark] .mailbox .message-center .message-item,
body[data-theme=dark] .mailbox .nav-link,
body[data-theme=dark] .mce-btn-group:not(:first-child),
body[data-theme=dark] .mce-btn:active,
body[data-theme=dark] .mce-btn:hover,
body[data-theme=dark] .mce-menubar,
body[data-theme=dark] .mce-panel,
body[data-theme=dark] .mce-splitbtn:hover .mce-open,
body[data-theme=dark] .modal-footer,
body[data-theme=dark] .modal-header,
body[data-theme=dark] .nav-tabs,
body[data-theme=dark] .nav-tabs .nav-item.show .nav-link,
body[data-theme=dark] .nav-tabs .nav-link.active,
body[data-theme=dark] .nav-tabs .nav-link:focus,
body[data-theme=dark] .nav-tabs .nav-link:hover,
body[data-theme=dark] .noUi-tooltip,
body[data-theme=dark] .note-editor.note-frame,
body[data-theme=dark] .note-editor.note-frame .note-statusbar,
body[data-theme=dark] .page-link,
body[data-theme=dark] .picker__button--clear,
body[data-theme=dark] .picker__button--close,
body[data-theme=dark] .picker__button--today,
body[data-theme=dark] .picker__list-item,
body[data-theme=dark] .profiletimeline,
body[data-theme=dark] .ql-container.ql-snow,
body[data-theme=dark] .ql-toolbar.ql-snow,
body[data-theme=dark] .r-separator .form-group,
body[data-theme=dark] .select2-container--classic .select2-selection--single,
body[data-theme=dark] .select2-container--default .select2-selection--multiple,
body[data-theme=dark] .select2-container--default .select2-selection--single,
body[data-theme=dark] .select2-dropdown,
body[data-theme=dark] .table td,
body[data-theme=dark] .table th,
body[data-theme=dark] .table-bordered,
body[data-theme=dark] .table-bordered td,
body[data-theme=dark] .table-bordered th,
body[data-theme=dark] .timeline > .timeline-item > .timeline-panel,
body[data-theme=dark] .wizard-content .wizard.wizard-circle > .steps .step,
body[data-theme=dark] blockquote,
body[data-theme=dark] pre[class*=language-],
body[data-theme=dark] table.footable,
body[data-theme=dark] table.footable-details {
  border-color: #4f5467 !important;
}

body[data-theme=dark] .wizard-content .wizard > .steps > ul > li.current .step {
  border-color: #009efb !important;
  background-color: #000;
}

body[data-theme=dark] .wizard-content .wizard > .steps > ul > li.current > a {
  color: #fff;
}

body[data-theme=dark] .wizard-content .wizard.wizard-circle > .steps > ul > li.current:after,
body[data-theme=dark] .wizard-content .wizard.wizard-circle > .steps > ul > li.current ~ li:after,
body[data-theme=dark] .wizard-content .wizard.wizard-circle > .steps > ul > li.current ~ li:before {
  background-color: #4f5467;
}

body[data-theme=dark] .wizard-content .wizard.vertical > .steps > ul > li.current:after,
body[data-theme=dark] .wizard-content .wizard.vertical > .steps > ul > li.current:before,
body[data-theme=dark] .wizard-content .wizard.vertical > .steps > ul > li.current ~ li:after,
body[data-theme=dark] .wizard-content .wizard.vertical > .steps > ul > li.current ~ li:before,
body[data-theme=dark] .wizard-content .wizard.vertical > .steps > ul > li:after,
body[data-theme=dark] .wizard-content .wizard.vertical > .steps > ul > li:before {
  background-color: transparent;
}

body[data-theme=dark] .custom-select.is-valid,
body[data-theme=dark] .form-control.is-valid,
body[data-theme=dark] .was-validated .custom-select:valid,
body[data-theme=dark] .was-validated .form-control:valid {
  border-color: #21c1d6 !important;
}

body[data-theme=dark] .custom-select.is-invalid,
body[data-theme=dark] .form-control.is-invalid,
body[data-theme=dark] .was-validated .custom-select:invalid,
body[data-theme=dark] .was-validated .form-control:invalid {
  border-color: #fc4b6c !important;
}

body[data-theme=dark] .ct-label {
  fill: #b2b9bf;
  color: #b2b9bf;
}

body[data-theme=dark] .ct-grid {
  stroke: #272c33;
}

body[data-theme=dark] .table-hover tbody tr:hover {
  color: #b2b9bf;
}

body[data-theme=dark] .form-material .form-control,
body[data-theme=dark] .form-material .form-control.focus,
body[data-theme=dark] .form-material .form-control:focus {
  background-image: linear-gradient(#1e88e5, #1e88e5), linear-gradient(#4f5467, #4f5467);
}

body[data-theme=dark] .message-box .message-widget a {
  border-bottom: 1px solid #4f5467;
}

body[data-theme=dark] .steamline {
  border-left: 1px solid #4f5467;
}

body[data-theme=dark] .steamline .sl-item {
  border-bottom: 1px solid #4f5467;
}

body[data-theme=dark] .has-success .form-control {
  border-color: #21c1d6 !important;
}

body[data-theme=dark] .has-warning .form-control {
  border-color: #ffb22b !important;
}

body[data-theme=dark] .has-danger .form-control {
  border-color: #fc4b6c !important;
}

body[data-theme=dark] .footable .pagination li a {
  border-color: #323743;
  background-color: #323743;
}

body[data-theme=dark] .footable .pagination li.active a {
  border-color: #1e88e5;
  background-color: #1e88e5;
}

#main-wrapper .topbar .top-navbar .navbar-header {
  background: #1e88e5;
}

#main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-header[data-logobg=skin1],
#main-wrapper[data-layout=vertical] .topbar .top-navbar .navbar-header[data-logobg=skin1] {
  background: #1e88e5;
}

#main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-header[data-logobg=skin2],
#main-wrapper[data-layout=vertical] .topbar .top-navbar .navbar-header[data-logobg=skin2] {
  background: #00acc1;
}

#main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-header[data-logobg=skin3],
#main-wrapper[data-layout=vertical] .topbar .top-navbar .navbar-header[data-logobg=skin3] {
  background: #fc4b6c;
}

#main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-header[data-logobg=skin4],
#main-wrapper[data-layout=vertical] .topbar .top-navbar .navbar-header[data-logobg=skin4] {
  background: #7460ee;
}

#main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-header[data-logobg=skin5],
#main-wrapper[data-layout=vertical] .topbar .top-navbar .navbar-header[data-logobg=skin5] {
  background: #1d2126;
}

#main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-header[data-logobg=skin6],
#main-wrapper[data-layout=vertical] .topbar .top-navbar .navbar-header[data-logobg=skin6] {
  background: #fff;
}

#main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-header[data-logobg=skin6] .navbar-brand .dark-logo,
#main-wrapper[data-layout=vertical] .topbar .top-navbar .navbar-header[data-logobg=skin6] .navbar-brand .dark-logo {
  display: inline;
}

#main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-header[data-logobg=skin6] .navbar-brand .light-logo,
#main-wrapper[data-layout=vertical] .topbar .top-navbar .navbar-header[data-logobg=skin6] .navbar-brand .light-logo {
  display: none;
}

#main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-header[data-logobg=skin6] .nav-toggler,
#main-wrapper[data-layout=horizontal] .topbar .top-navbar .navbar-header[data-logobg=skin6] .topbartoggler,
#main-wrapper[data-layout=vertical] .topbar .top-navbar .navbar-header[data-logobg=skin6] .nav-toggler,
#main-wrapper[data-layout=vertical] .topbar .top-navbar .navbar-header[data-logobg=skin6] .topbartoggler {
  color: #67757c;
}

#main-wrapper[data-layout=horizontal] .topbar .navbar-collapse[data-navbarbg=skin1],
#main-wrapper[data-layout=horizontal] .topbar[data-navbarbg=skin1],
#main-wrapper[data-layout=vertical] .topbar .navbar-collapse[data-navbarbg=skin1],
#main-wrapper[data-layout=vertical] .topbar[data-navbarbg=skin1] {
  background: #1e88e5;
}

#main-wrapper[data-layout=horizontal] .topbar .navbar-collapse[data-navbarbg=skin2],
#main-wrapper[data-layout=horizontal] .topbar[data-navbarbg=skin2],
#main-wrapper[data-layout=vertical] .topbar .navbar-collapse[data-navbarbg=skin2],
#main-wrapper[data-layout=vertical] .topbar[data-navbarbg=skin2] {
  background: #00acc1;
}

#main-wrapper[data-layout=horizontal] .topbar .navbar-collapse[data-navbarbg=skin3],
#main-wrapper[data-layout=horizontal] .topbar[data-navbarbg=skin3],
#main-wrapper[data-layout=vertical] .topbar .navbar-collapse[data-navbarbg=skin3],
#main-wrapper[data-layout=vertical] .topbar[data-navbarbg=skin3] {
  background: #fc4b6c;
}

#main-wrapper[data-layout=horizontal] .topbar .navbar-collapse[data-navbarbg=skin4],
#main-wrapper[data-layout=horizontal] .topbar[data-navbarbg=skin4],
#main-wrapper[data-layout=vertical] .topbar .navbar-collapse[data-navbarbg=skin4],
#main-wrapper[data-layout=vertical] .topbar[data-navbarbg=skin4] {
  background: #7460ee;
}

#main-wrapper[data-layout=horizontal] .topbar .navbar-collapse[data-navbarbg=skin5],
#main-wrapper[data-layout=horizontal] .topbar[data-navbarbg=skin5],
#main-wrapper[data-layout=vertical] .topbar .navbar-collapse[data-navbarbg=skin5],
#main-wrapper[data-layout=vertical] .topbar[data-navbarbg=skin5] {
  background: #1d2126;
}

#main-wrapper[data-layout=horizontal] .topbar .navbar-collapse[data-navbarbg=skin6],
#main-wrapper[data-layout=horizontal] .topbar[data-navbarbg=skin6],
#main-wrapper[data-layout=vertical] .topbar .navbar-collapse[data-navbarbg=skin6],
#main-wrapper[data-layout=vertical] .topbar[data-navbarbg=skin6] {
  background: #fff;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin1],
#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin1] ul,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin1],
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin1] ul {
  background: #1e88e5;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin1] .sidebar-footer,
#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin1] ul .sidebar-footer,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin1] .sidebar-footer,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin1] ul .sidebar-footer {
  background: #1e88e5;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin2],
#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin2] ul,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin2],
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin2] ul {
  background: #00acc1;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin2] .sidebar-footer,
#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin2] ul .sidebar-footer,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin2] .sidebar-footer,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin2] ul .sidebar-footer {
  background: #00acc1;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin3],
#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin3] ul,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin3],
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin3] ul {
  background: #fc4b6c;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin3] .sidebar-footer,
#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin3] ul .sidebar-footer,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin3] .sidebar-footer,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin3] ul .sidebar-footer {
  background: #fc4b6c;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin4],
#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin4] ul,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin4],
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin4] ul {
  background: #7460ee;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin4] .sidebar-footer,
#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin4] ul .sidebar-footer,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin4] .sidebar-footer,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin4] ul .sidebar-footer {
  background: #7460ee;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin5],
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin5] {
  background: #1d2126;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin5] .sidebar-footer,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin5] .sidebar-footer {
  background: #1d2126;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin5] .sidebar-nav ul,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin5] .sidebar-nav ul {
  background: #1d2126;
}

#main-wrapper[data-layout=horizontal]
  .left-sidebar[data-sidebarbg=skin5]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link,
#main-wrapper[data-layout=vertical]
  .left-sidebar[data-sidebarbg=skin5]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link {
  background-color: #21c1d6;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin6],
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin6] {
  background: #fff;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin6] .sidebar-footer,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin6] .sidebar-footer {
  background: #f2f4f8;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin6] .sidebar-footer a,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin6] .sidebar-footer a {
  color: #67757c;
}

#main-wrapper[data-layout=horizontal]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  > ul
  > .sidebar-item
  > .sidebar-link.active,
#main-wrapper[data-layout=vertical]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  > ul
  > .sidebar-item
  > .sidebar-link.active {
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  background-color: #26c6da;
  color: #fff !important;
  opacity: 1;
  font-weight: 400;
}

#main-wrapper[data-layout=horizontal]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  > ul
  > .sidebar-item
  > .sidebar-link.active
  i,
#main-wrapper[data-layout=vertical]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  > ul
  > .sidebar-item
  > .sidebar-link.active
  i {
  color: #fff !important;
}

#main-wrapper[data-layout=horizontal]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  > ul
  > .sidebar-item
  > .sidebar-link.active:after,
#main-wrapper[data-layout=vertical]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  > ul
  > .sidebar-item
  > .sidebar-link.active:after {
  border-color: #fff;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin6] .sidebar-nav ul,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin6] .sidebar-nav ul {
  background: #fff;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin6] .sidebar-nav ul .sidebar-item .sidebar-link,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin6] .sidebar-nav ul .sidebar-item .sidebar-link {
  color: #607d8b;
  opacity: 1;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin6] .sidebar-nav ul .sidebar-item .sidebar-link i,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin6] .sidebar-nav ul .sidebar-item .sidebar-link i {
  color: #99abb4;
}

#main-wrapper[data-layout=horizontal]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link:hover,
#main-wrapper[data-layout=vertical]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link:hover {
  color: #343a40;
}

#main-wrapper[data-layout=horizontal]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link:hover
  i,
#main-wrapper[data-layout=vertical]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link:hover
  i {
  color: #343a40;
}

#main-wrapper[data-layout=horizontal]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link.active,
#main-wrapper[data-layout=vertical]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link.active {
  color: #212529;
}

#main-wrapper[data-layout=horizontal]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link,
#main-wrapper[data-layout=vertical]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link {
  color: #fff;
  background-color: #26c6da;
}

#main-wrapper[data-layout=horizontal]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link
  i,
#main-wrapper[data-layout=vertical]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link
  i {
  color: #fff;
}

#main-wrapper[data-layout=horizontal]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link.has-arrow::after,
#main-wrapper[data-layout=vertical]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link.has-arrow::after {
  border-color: #fff;
}

#main-wrapper[data-layout=horizontal]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link:hover
  i,
#main-wrapper[data-layout=vertical]
  .left-sidebar[data-sidebarbg=skin6]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link:hover
  i {
  color: #fff;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin6] .sidebar-nav ul .nav-small-cap,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin6] .sidebar-nav ul .nav-small-cap {
  color: #99abb4;
}

#main-wrapper[data-layout=horizontal] .left-sidebar[data-sidebarbg=skin6] .sidebar-nav .has-arrow::after,
#main-wrapper[data-layout=vertical] .left-sidebar[data-sidebarbg=skin6] .sidebar-nav .has-arrow::after {
  border-color: #607d8b;
}

.page-titles {
  padding: 15px 30px;
  background: #fff;
  box-shadow: 1px 0 5px rgba(0, 0, 0, .1);
  margin-bottom: 15px;
}

.page-titles .text-themecolor {
  color: #1e88e5;
}

.page-titles .breadcrumb {
  padding: 0;
  margin: 0;
  background: 0 0;
  font-size: 14px;
}

.btn-list .btn {
  margin-bottom: 5px;
}

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 5px;
  line-height: 30px;
}

.btn-circle.btn-sm,
.btn-group-sm > .btn-circle.btn {
  width: 35px;
  height: 35px;
  padding: 8px 10px;
  font-size: 14px;
}

.btn-circle.btn-lg,
.btn-group-lg > .btn-circle.btn {
  width: 50px;
  height: 50px;
  padding: 14px 10px;
  font-size: 18px;
  line-height: 23px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 14px 15px;
  font-size: 24px;
}

.btn-rounded {
  border-radius: 60px;
  padding: 7px 18px;
}

.btn-rounded.btn-xs {
  padding: .25rem .5rem;
  font-size: 10px;
}

.btn-group-sm > .btn-rounded.btn,
.btn-rounded.btn-sm {
  padding: .25rem .5rem;
  font-size: 12px;
}

.btn-rounded.btn-md {
  padding: 12px 35px;
  font-size: 16px;
}

.btn-group-lg > .btn-rounded.btn,
.btn-rounded.btn-lg {
  padding: .75rem 1.5rem;
}

.btn-xs {
  padding: .25rem .5rem;
  font-size: 10px;
}

.btn .text-active {
  display: none;
}

.btn.active .text-active {
  display: inline-block;
}

.card {
  margin-bottom: 30px;
}

.card .card-subtitle {
  margin-bottom: 10px;
  font-weight: 300;
  color: #a1aab2;
}

.card .card-title {
  margin-bottom: 10px;
  font-weight: 400;
}

.card .card-actions {
  float: right;
}

.card .card-actions a {
  cursor: pointer;
  padding: 0 5px;
}

.card .card-header .card-title {
  margin-bottom: 0;
}

.card-group {
  margin-bottom: 30px;
}

.card-group .card {
  border-right: 1px solid #e9ecef;
}

.card-hover {
  -webkit-transition: all .25s ease;
  -o-transition: all .25s ease;
  -moz-transition: all .25s ease;
  transition: all .25s ease;
}

.card-hover:hover {
  webkit-transform: translateY(-4px) scale(1.01);
  -moz-transform: translateY(-4px) scale(1.01);
  -ms-transform: translateY(-4px) scale(1.01);
  -o-transform: translateY(-4px) scale(1.01);
  transform: translateY(-4px) scale(1.01);
  -webkit-box-shadow: 0 14px 24px rgba(62, 57, 107, .1);
  box-shadow: 0 14px 24px rgba(62, 57, 107, .1);
}

.draggable-cards .card-header {
  cursor: move;
}

.card-moved .card {
  background: #1e88e5;
  color: #fff;
}

.modal-title {
  margin-top: 0;
}

.modal-full-width {
  width: 95%;
  max-width: none;
}

.modal-top {
  margin: 0 auto;
}

.modal-right {
  position: absolute;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  margin: 0;
  background-color: #fff;
  align-content: center;
  transform: translate(25%, 0) !important;
}

.modal-right button.close {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.modal.show .modal-right {
  transform: translate(0, 0) !important;
}

.modal-bottom {
  display: flex;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
  margin: 0 auto;
  align-content: center;
}

.modal-colored-header {
  color: #fff;
  border-radius: 0;
}

.modal-colored-header .close {
  color: #fff !important;
}

.modal-filled {
  color: #fff;
}

.modal-filled .modal-header {
  background-color: rgba(255, 255, 255, .07);
}

.modal-filled .modal-footer,
.modal-filled .modal-header {
  border: none;
}

.modal-filled .close {
  color: #fff !important;
}

.nav-pills.custom-pills .nav-link {
  border-radius: 0;
  opacity: .7;
}

.nav-pills.custom-pills .nav-link.active {
  color: #009efb;
  background-color: transparent;
  border-bottom: 2px solid #009efb;
  opacity: 1;
}

.custom-range,
[type=search],
button.close {
  -webkit-appearance: none;
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.custom-range::-moz-range-thumb {
  -moz-appearance: none;
}

* {
  outline: 0;
}

body {
  overflow-x: hidden;
  margin: 0;
  color: #67757c;
  background: #eef5f9;
}

html {
  position: relative;
  min-height: 100%;
}

b,
strong {
  font-weight: 500;
}

a {
  color: #009efb;
}

a:focus,
a:hover {
  text-decoration: none;
  color: #1e88e5;
}

a.link {
  color: #455a64;
}

a.link:focus,
a.link:hover {
  color: #009efb;
}

.op-5 {
  opacity: .5;
}

html body .font-weight-medium {
  font-weight: 500;
}

html body .display-5 {
  font-size: 3rem;
}

html body .display-6 {
  font-size: 2.5rem;
}

html body .display-7 {
  font-size: 2rem;
}

ul.list-style-none {
  margin: 0;
  padding: 0;
}

ul.list-style-none li {
  list-style: none;
}

ul.list-style-none li a {
  display: block;
  padding: 8px 0;
  color: #67757c;
  text-decoration: none;
}

ul.list-style-none li a:hover {
  color: #009efb;
}

.round {
  line-height: 45px;
  width: 45px;
  height: 45px;
}

.round-lg {
  line-height: 65px;
  width: 60px;
  height: 60px;
  font-size: 30px;
}

ul.list-icons li a {
  color: #67757c;
}

ul.list-icons li a:hover {
  color: #1e88e5;
}

ul.list-icons li i {
  font-size: 13px;
}

.custom-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  background-size: auto;
}

.no-wrap td,
.no-wrap th {
  white-space: nowrap;
}

.v-middle td,
.v-middle th {
  vertical-align: middle;
}

.grid-structure .grid-container {
  background-color: #f2f4f8;
  margin-bottom: 10px;
  font-size: .8rem;
  padding: 10px 20px;
}

.progress-sm {
  height: 5px;
}

.progress-md {
  height: 8px;
}

.progress-lg {
  height: 12px;
}

.progress-xl {
  height: 15px;
}

.amp-pxl {
  position: relative;
}

.amp-pxl .ct-series-a .ct-bar {
  stroke: #1e88e5;
}

.amp-pxl .ct-series-b .ct-bar {
  stroke: #21c1d6;
}

.campaign {
  height: 266px;
  position: relative;
}

.campaign .ct-series-a .ct-area {
  fill-opacity: .2;
  fill: url(#gradient);
}

.campaign .ct-series-a .ct-line,
.campaign .ct-series-a .ct-point {
  stroke: #21c1d6;
  stroke-width: 2px;
}

.campaign .ct-series-b .ct-area {
  fill: #1e88e5;
  fill-opacity: .1;
}

.campaign .ct-series-b .ct-line,
.campaign .ct-series-b .ct-point {
  stroke: #1e88e5;
  stroke-width: 2px;
}

.campaign .ct-series-a .ct-point,
.campaign .ct-series-b .ct-point {
  stroke-width: 6px;
}

.add-ct-btn {
  right: 4px;
  top: -46px;
}

.little-profile .pro-img {
  margin-top: -80px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .profile-bg-height {
    max-height: 187px;
  }

  .blog-img-height {
    max-height: 238px;
  }
}

.profile-card .profile-img {
  max-height: 401px;
}

.blog-widget {
  margin-top: 30px;
}

.blog-widget .blog-image img {
  border-radius: 4px;
  margin-top: -45px;
  margin-bottom: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .2);
}

.stylish-table tbody tr {
  border-left: 4px solid transparent;
}

.stylish-table tbody tr.active,
.stylish-table tbody tr:hover {
  border-left: 4px solid #1e88e5;
}

.stylish-table tbody td small {
  line-height: 12px;
}

.campaign2 {
  position: relative;
}

.campaign2 .ct-series-a .ct-area {
  fill-opacity: .2;
  fill: url(#gradient);
}

.campaign2 .ct-series-a .ct-line,
.campaign2 .ct-series-a .ct-point {
  stroke: #21c1d6;
  stroke-width: 2px;
}

.campaign2 .ct-series-b .ct-area {
  fill: #1e88e5;
  fill-opacity: .1;
}

.campaign2 .ct-series-b .ct-line,
.campaign2 .ct-series-b .ct-point {
  stroke: #1e88e5;
  stroke-width: 2px;
}

.campaign2 .ct-series-a .ct-point,
.campaign2 .ct-series-b .ct-point {
  stroke-width: 6px;
}

.profile-card .profile-img {
  max-height: 380px;
}

.customtab li a.nav-link,
.profile-tab li a.nav-link {
  border: 0;
  padding: 15px 20px;
  color: #67757c;
  font-size: 1rem;
}

.customtab li a.nav-link.active,
.profile-tab li a.nav-link.active {
  border-bottom: 2px solid #1e88e5;
  color: #1e88e5;
}

.customtab li a.nav-link:hover,
.profile-tab li a.nav-link:hover {
  color: #1e88e5;
}

#visitfromworld path.jvectormap-region.jvectormap-element {
  stroke-width: 1px;
  stroke: #a1aab2;
}

.jvectormap-goback,
.jvectormap-zoomin,
.jvectormap-zoomout {
  background: #a1aab2;
}

.jvectormap-zoomin {
  top: 10px;
}

.jvectormap-zoomout {
  top: 40px;
}

.total-sales {
  position: relative;
}

.total-sales .chartist-tooltip {
  background: #2f3d4a;
}

.total-sales .ct-series-a .ct-bar {
  stroke: #1e88e5;
}

.total-sales .ct-series-b .ct-bar {
  stroke: #21c1d6;
}

.total-sales .ct-series-c .ct-bar {
  stroke: #fc4b6c;
}

.ct-chart {
  position: relative;
}

.ct-chart .ct-series-a .ct-slice-donut {
  stroke: #21c1d6;
}

.ct-chart .ct-series-b .ct-slice-donut {
  stroke: #f2f4f8;
}

.ct-chart .ct-series-c .ct-slice-donut {
  stroke: #1e88e5;
}

.social-profile {
  text-align: center;
  background: rgba(7, 10, 43, .8);
}

@media (max-width: 767.98px) {
  .social-profile-first {
    padding-top: 15% !important;
    max-height: 193px;
  }
}

.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
}

.error-box {
  position: fixed;
}

.error-box .error-body {
  padding-top: 5%;
}

.auth-wrapper {
  position: relative;
  min-height: 100vh;
}

.auth-wrapper .auth-box {
  box-shadow: 1px 0 20px rgba(0, 0, 0, .08);
  margin: 8% 0;
  max-width: 400px;
  width: 90%;
}

.auth-wrapper .auth-box.on-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.auth-wrapper #recoverform {
  display: none;
}

.email-app .list-group .list-group-item {
  background: 0 0;
}

.email-app .list-group .list-group-item .list-group-item-action {
  padding: 12px 15px;
  color: #67757c;
}

.email-app .list-group .list-group-item .list-group-item-action.active,
.email-app .list-group .list-group-item .list-group-item-action:hover {
  background: rgba(0, 0, 0, .03);
}

.email-app .email-table {
  table-layout: fixed;
}

.email-app .email-table .selected {
  background: #fff8e1;
}

.email-app .email-table .chb {
  width: 50px;
}

.email-app .email-table .time {
  width: 100px;
}

.email-app .email-table .clip,
.email-app .email-table .starred {
  width: 25px;
}

.email-app .email-table .user-image {
  width: 45px;
}

.email-app .email-table .user-name {
  width: 130px;
}

.email-app .email-table .user-name .m-b-0 {
  font-weight: 300;
}

.email-app .email-table .unread .m-b-0,
.email-app .email-table .unread .max-texts {
  font-weight: 500;
}

@media (max-width: 991.98px) {
  .email-table {
    min-width: 500px;
  }
}

form label {
  font-weight: 400;
  margin-bottom: 0;
}

.error .form-control {
  border-color: #fc4b6c;
}

.error .help-block {
  color: #fc4b6c;
}

.validate .form-control {
  border-color: #21c1d6;
}

.validate .help-block {
  color: #21c1d6;
}

@media (min-height: 33.875em) {
  .picker--opened .picker__frame {
    position: absolute;
    top: 30%;
    bottom: auto;
  }
}

.dtp div.dtp-date,
.dtp div.dtp-time,
.dtp table.dtp-picker-days tr > td > a.selected,
.dtp > .dtp-content > .dtp-date-view > header.dtp-header {
  background: #1e88e5;
}

.dtp .p10 > a {
  color: #fff;
}

.datepicker .day,
.datepicker .dow {
  padding: 5px 10px;
}

.docs-buttons .btn,
.docs-data .input-group {
  margin-bottom: 5px;
}

.scrollable {
  position: relative;
}

.form-material .form-group {
  overflow: hidden;
}

.form-material .form-control {
  background-color: rgba(0, 0, 0, 0);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  padding: 0;
  transition: background 0s ease-out 0s;
  height: calc(2.25rem + 2px);
}

.form-material .form-control,
.form-material .form-control.focus,
.form-material .form-control:focus {
  background-image: linear-gradient(#1e88e5, #1e88e5), linear-gradient(#e9ecef, #e9ecef);
  border: 0 none;
  border-radius: 0;
  box-shadow: none !important;
  float: none;
  background-position: bottom;
  padding: 0 12px;
}

.form-material .form-control.focus,
.form-material .form-control:focus {
  background-size: 100% 2px, 100% 1px;
  outline: 0 none;
  transition-duration: .3s;
}

.form-control-line .form-group {
  overflow: hidden;
}

.form-control-line .form-control {
  border: 0;
  border-radius: 0;
  padding-left: 0;
  border-bottom: 1px solid #e9ecef;
}

.form-control-line .form-control:focus {
  border-bottom: 1px solid #1e88e5;
}

.btn-file {
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}

.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  font-size: 23px;
  height: 100%;
  width: 100%;
  direction: ltr;
  cursor: pointer;
  border-radius: 0;
}

.fileinput .input-group-addon {
  border-right: 1px solid #e9ecef;
}

.fileinput .form-control {
  padding-top: 7px;
  padding-bottom: 5px;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: text;
}

.fileinput .thumbnail {
  overflow: hidden;
  display: inline-block;
  margin-bottom: 5px;
  vertical-align: middle;
  text-align: center;
}

.fileinput .thumbnail > img {
  max-height: 100%;
}

.fileinput .btn {
  vertical-align: middle;
}

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}

.fileinput-inline .fileinput-controls {
  display: inline;
}

.fileinput-filename {
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
}

.form-control .fileinput-filename {
  vertical-align: bottom;
}

.fileinput.input-group > * {
  position: relative;
  z-index: 2;
}

.fileinput.input-group > .btn-file {
  z-index: 1;
}

.form-control-danger,
.form-control-success,
.form-control-warning {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right .5625rem;
  -webkit-background-size: 1.125rem 1.125rem;
  background-size: 1.125rem 1.125rem;
}

.has-success .col-form-label,
.has-success .custom-control,
.has-success .form-check-label,
.has-success .form-control-feedback,
.has-success .form-control-label {
  color: #21c1d6;
}

.has-success .form-control {
  border-color: #21c1d6;
}

.has-warning .col-form-label,
.has-warning .custom-control,
.has-warning .form-check-label,
.has-warning .form-control-feedback,
.has-warning .form-control-label {
  color: #ffb22b;
}

.has-warning .form-control {
  border-color: #ffb22b;
}

.has-danger .col-form-label,
.has-danger .custom-control,
.has-danger .form-check-label,
.has-danger .form-control-feedback,
.has-danger .form-control-label {
  color: #fc4b6c;
}

.has-danger .form-control {
  border-color: #fc4b6c;
}

.floating-labels .form-control {
  box-shadow: none;
}

.floating-labels .form-group {
  position: relative;
}

.floating-labels .form-control {
  padding: 10px 10px 10px 0;
  display: block;
  border: none;
  font-family: Poppins, sans-serif;
  border-radius: 0;
  border-bottom: 1px solid #e9ecef;
}

.floating-labels .form-control:focus {
  box-shadow: none;
}

.floating-labels select.form-control > option {
  font-size: 14px;
}

.floating-labels .has-error .form-control {
  border-bottom: 1px solid #fc4b6c !important;
}

.floating-labels .has-warning .form-control {
  border-bottom: 1px solid #ffb22b !important;
}

.floating-labels .has-success .form-control {
  border-bottom: 1px solid #21c1d6 !important;
}

.floating-labels .form-control:focus {
  outline: 0;
  border: none;
}

.floating-labels label {
  color: #67757c;
  position: absolute;
  cursor: auto;
  top: 5px;
  transition: .2s ease all;
  -moz-transition: .2s ease all;
  -webkit-transition: .2s ease all;
}

.floating-labels .focused label {
  top: -20px;
  font-size: 12px;
  color: #343a40;
}

.floating-labels .bar {
  position: relative;
  display: block;
}

.floating-labels .bar:after,
.floating-labels .bar:before {
  content: " ";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #009efb;
  transition: .2s ease all;
  -moz-transition: .2s ease all;
  -webkit-transition: .2s ease all;
}

.floating-labels .bar:before {
  left: 50%;
}

.floating-labels .bar:after {
  right: 50%;
}

.floating-labels .form-control:focus ~ .bar:after,
.floating-labels .form-control:focus ~ .bar:before {
  width: 50%;
}

.floating-labels .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: .5;
}

.floating-labels .input-lg,
.floating-labels .input-lg ~ label {
  font-size: 24px;
}

.floating-labels .input-sm,
.floating-labels .input-sm ~ label {
  font-size: 16px;
}

.has-warning .bar:after,
.has-warning .bar:before {
  background: #ffb22b;
}

.has-success .bar:after,
.has-success .bar:before {
  background: #21c1d6;
}

.has-error .bar:after,
.has-error .bar:before {
  background: #fc4b6c;
}

.el-element-overlay .white-box {
  padding: 0;
}

.el-element-overlay .el-card-item .el-overlay-1 {
  cursor: default;
}

.el-element-overlay .el-card-item .el-overlay-1 img {
  height: auto;
  -webkit-transition: all .4s linear;
  transition: all .4s linear;
}

.el-element-overlay .el-card-item .el-overlay-1:hover img {
  -ms-transform: scale(1.2) translateZ(0);
  -webkit-transform: scale(1.2) translateZ(0);
}

.el-element-overlay .el-card-item .el-overlay-1 .el-info {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  text-decoration: none;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  transform: translateY(-50%) translateZ(0);
  -webkit-transform: translateY(-50%) translateZ(0);
  -ms-transform: translateY(-50%) translateZ(0);
}

.el-element-overlay .el-card-item .el-overlay-1 .el-info .el-item .el-link {
  padding: 12px 15px 10px;
}

.el-element-overlay .el-card-item .el-overlay-1 .el-info .el-item .el-link:hover {
  border-color: #009efb;
  background: #009efb;
}

.el-element-overlay .el-card-item .el-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, .7);
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.el-element-overlay .el-card-item .el-overlay-1:hover .el-overlay {
  opacity: 1;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.el-element-overlay .el-card-item .el-overlay-1 .scrl-dwn {
  top: -100%;
}

.el-element-overlay .el-card-item .el-overlay-1 .scrl-up {
  top: 100%;
  height: 0;
}

.el-element-overlay .el-card-item .el-overlay-1:hover .scrl-dwn {
  top: 0;
}

.el-element-overlay .el-card-item .el-overlay-1:hover .scrl-up {
  top: 0;
  height: 100%;
}

.gmaps,
.gmaps-panaroma {
  height: 300px;
}

.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: #e9ecef;
  border-radius: 4px;
}

.gmaps-overlay {
  display: block;
  background: #1e88e5;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  padding: 10px 20px;
}

.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
}

.gmaps-overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #1e88e5;
}

.gmaps-overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #1e88e5;
}

.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 10px;
  height: 10px;
  line-height: 10px;
}

.jvectormap-zoomout {
  top: 40px;
}

.price-label {
  position: absolute;
  top: -10px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100px;
  padding: 5px 10px;
}

.price-sign {
  font-size: 15px;
  position: absolute;
  top: 5px;
  margin-left: -10px;
}

.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-empty,
.dd-item,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

.dd-handle {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px;
  cursor: move;
  color: #979898;
  text-decoration: none;
  font-weight: 700;
  border: 1px solid #e5e5e5;
  background: #fafafa;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-handle:hover {
  color: #317eeb;
  background: #fff;
}

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: 0 0;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: 700;
}

.dd-item > button:before {
  content: "+";
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

.dd-empty,
.dd-placeholder {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f5f5f5;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .1);
}

.lobilists .lobilist {
  box-shadow: none;
}

.lobilists .lobilist.lobilist-primary {
  border-color: #e9ecef;
}

.lobilists .lobilist.lobilist-primary .lobilist-footer,
.lobilists .lobilist.lobilist-primary .lobilist-form-footer,
.lobilists .lobilist.lobilist-primary .lobilist-header {
  border-color: #7460ee;
  background-color: #7460ee;
}

.lobilists .lobilist.lobilist-danger {
  border-color: #e9ecef;
}

.lobilists .lobilist.lobilist-danger .lobilist-footer,
.lobilists .lobilist.lobilist-danger .lobilist-form-footer,
.lobilists .lobilist.lobilist-danger .lobilist-header {
  border-color: #fc4b6c;
  background-color: #fc4b6c;
}

.lobilists .lobilist.lobilist-info {
  border-color: #e9ecef;
}

.lobilists .lobilist.lobilist-info .lobilist-footer,
.lobilists .lobilist.lobilist-info .lobilist-form-footer,
.lobilists .lobilist.lobilist-info .lobilist-header {
  border-color: #1e88e5;
  background-color: #1e88e5;
}

.lobilists .lobilist.lobilist-success {
  border-color: #e9ecef;
}

.lobilists .lobilist.lobilist-success .lobilist-footer,
.lobilists .lobilist.lobilist-success .lobilist-header {
  border-color: #21c1d6;
  background-color: #21c1d6;
}

.lobilists .lobilist-footer,
.lobilists .lobilist-form-footer {
  border-color: #e9ecef !important;
  background: rgba(0, 0, 0, .02) !important;
}

.lobilists .btn-link {
  background: 0 0;
  border: 0;
  box-shadow: none;
  color: #343a40 !important;
}

.lobilists.single-line {
  height: 500px;
}

.lobilist,
.lobilists {
  position: relative;
}

.lobilists [type=checkbox]:checked,
.lobilists [type=checkbox]:not(:checked) {
  opacity: 1;
  position: relative;
}

.lobilists .lobilist-item {
  margin-bottom: 16px;
  padding-top: 5px;
}

.lobilists .lobilist-item-title {
  color: #343a40;
}

.lobilists .lobilist-item-description {
  font-style: normal;
  font-size: 13px;
}

.lobilists .lobilist-check {
  top: 10px;
}

.lobilists .lobilist-item-duedate {
  position: relative;
  font-size: 12px;
  left: 0;
}

.lobilists .lobilist-actions {
  top: 0;
}

.lobilist-actions .btn i {
  font-size: 15px;
  vertical-align: middle;
}

.timeline {
  position: relative;
  padding: 20px 0 20px;
  list-style: none;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 3px;
  margin-left: -1.5px;
  background-color: #e9ecef;
}

.timeline > .timeline-item {
  position: relative;
  margin-bottom: 20px;
}

.timeline > .timeline-item:after,
.timeline > .timeline-item:before {
  content: " ";
  display: table;
}

.timeline > .timeline-item:after {
  clear: both;
}

.timeline > .timeline-item > .timeline-panel {
  float: left;
  position: relative;
  width: 46%;
  padding: 20px;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 6px rgba(0, 0, 0, .05);
}

.timeline > .timeline-item > .timeline-panel:before {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 26px;
  right: -8px;
  border-top: 8px solid transparent;
  border-right: 0 solid #e9ecef;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #e9ecef;
}

.timeline > .timeline-item > .timeline-panel:after {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 27px;
  right: -7px;
  border-top: 7px solid transparent;
  border-right: 0 solid #fff;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #fff;
}

.timeline > .timeline-item > .timeline-badge {
  z-index: 10;
  position: absolute;
  top: 16px;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  border-radius: 50% 50% 50% 50%;
  text-align: center;
  font-size: 1.4em;
  line-height: 50px;
  color: #fff;
  overflow: hidden;
}

.timeline > .timeline-item.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > .timeline-item.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -8px;
  border-right-width: 8px;
  border-left-width: 0;
}

.timeline > .timeline-item.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -7px;
  border-right-width: 7px;
  border-left-width: 0;
}

.timeline-badge.primary {
  background-color: #7460ee;
}

.timeline-badge.success {
  background-color: #21c1d6;
}

.timeline-badge.warning {
  background-color: #ffb22b;
}

.timeline-badge.danger {
  background-color: #fc4b6c;
}

.timeline-badge.info {
  background-color: #1e88e5;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
  font-weight: 400;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-left:before {
  left: 30px;
}

.timeline-left > .timeline-item > .timeline-badge {
  left: 30px;
  top: 9px;
}

.timeline-left > .timeline-item > .timeline-panel {
  width: calc(100% - 80px);
}

.timeline-right:before {
  right: 30px;
  left: auto;
}

.timeline-right > .timeline-item > .timeline-badge {
  right: 5px;
  top: 9px;
  left: auto;
}

.timeline-right > .timeline-item > .timeline-panel {
  width: calc(100% - 80px);
}

.cd-horizontal-timeline .events a {
  padding-bottom: 6px;
  color: #009efb;
}

.cd-horizontal-timeline .events a.selected::after,
.cd-horizontal-timeline .filling-line {
  background: #009efb;
}

.cd-horizontal-timeline .events a.selected::after {
  border-color: #009efb;
}

.cd-horizontal-timeline .m-t-40 {
  margin-top: 40px !important;
}

.twitter-typeahead {
  width: 100%;
}

.twitter-typeahead .tt-menu {
  width: 100%;
  background: #fff;
  border: 1px solid #f2f4f8;
  border-radius: 5px;
  padding: .75rem 0;
}

.twitter-typeahead .tt-menu .tt-suggestion {
  padding: .25rem .75rem;
  cursor: pointer;
}

.twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #7460ee;
  color: #fff;
}

.twitter-typeahead .empty-message {
  padding: 5px 10px;
  text-align: center;
}

.twitter-typeahead .rtl-typeahead .tt-menu {
  text-align: right;
}

.twitter-typeahead .league-name {
  margin: 0 10px 5px;
  padding: 7px 5px 10px;
  border-bottom: 1px solid #e9ecef;
}

.scrollable-dropdown .twitter-typeahead .tt-menu {
  max-height: 80px;
  overflow-y: auto;
}

.bootstrap-maxlength {
  margin-top: .5rem;
}

.message-box .message-widget a:hover {
  background: #f2f4f8;
}

.message-box .message-widget a .user-img {
  width: 45px;
}

.message-box .message-widget a .user-img .profile-status {
  border: 2px solid #fff;
  height: 10px;
  left: 33px;
  position: absolute;
  top: -1px;
  width: 10px;
}

.message-box .message-widget a .user-img .online {
  background: #21c1d6;
}

.message-box .message-widget a .user-img .busy {
  background: #fc4b6c;
}

.message-box .message-widget a .user-img .away {
  background: #ffb22b;
}

.message-box .message-widget a .user-img .offline {
  background: #ffb22b;
}

.message-box .message-widget a .mail-desc,
.message-box .message-widget a .time {
  color: #67757c;
}

.right-part.chat-container,
.right-part.invoice-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-part.chat-container .chat-box-inner-part,
.right-part.invoice-box .chat-box-inner-part {
  width: 100%;
}

.left-part.list-of-user,
.left-part.user-chat-box {
  height: 100vh;
}

.chatting-box,
.invoiceing-box {
  display: none;
}

.chat-box .chat {
  display: none;
}

.chat-box .chat.active-chat {
  display: block;
}

.comment-widgets .comment-row {
  border-left: 3px solid transparent;
}

.comment-widgets .comment-row.active,
.comment-widgets .comment-row:hover {
  border-color: #1e88e5;
}

.comment-text.active .comment-footer .action-icons,
.comment-text:hover .comment-footer .action-icons {
  visibility: visible;
}

.comment-footer .action-icons {
  visibility: hidden;
}

.comment-footer .action-icons a {
  color: #a1aab2;
}

.comment-footer .action-icons a.active,
.comment-footer .action-icons a:hover {
  color: #1e88e5;
}

form p {
  margin-bottom: 10px;
  text-align: left;
}

form p:last-child {
  margin-bottom: 0;
}

[type=checkbox]:checked[class*=material-inputs],
[type=checkbox]:not(:checked)[class*=material-inputs] {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

[type=checkbox] + label {
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  line-height: 21px;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

[type=checkbox] + label:before,
[type=checkbox]:not(.filled-in) + label:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid #5a5a5a;
  border-radius: 1px;
  margin-top: 2px;
  transition: .2s;
}

[type=checkbox]:not(.filled-in) + label:after {
  border: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type=checkbox]:not(:checked):disabled + label:before {
  border: none;
  background-color: rgba(0, 0, 0, .26);
}

[type=checkbox].tabbed:focus + label:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, .1);
  background-color: rgba(0, 0, 0, .1);
}

[type=checkbox]:checked + label:before {
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #26a69a;
  border-bottom: 2px solid #26a69a;
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type=checkbox]:checked:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, .26);
  border-bottom: 2px solid rgba(0, 0, 0, .26);
}

[type=checkbox]:indeterminate + label:before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-right: 2px solid #26a69a;
  border-bottom: none;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type=checkbox]:indeterminate:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, .26);
  background-color: transparent;
}

[type=checkbox].filled-in + label:after {
  border-radius: 2px;
}

[type=checkbox].filled-in + label:after,
[type=checkbox].filled-in + label:before {
  content: " ";
  left: 0;
  position: absolute;
  transition: border .25s, background-color .25s, width .2s .1s, height .2s .1s, top .2s .1s, left .2s .1s;
  z-index: 1;
}

[type=checkbox].filled-in:not(:checked) + label:before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 20% 40%;
  transform-origin: 100% 100%;
}

[type=checkbox].filled-in:not(:checked) + label:after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #5a5a5a;
  top: 0;
  z-index: 0;
}

[type=checkbox].filled-in:checked + label:before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type=checkbox].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #26a69a;
  background-color: #26a69a;
  z-index: 0;
}

[type=checkbox].filled-in.tabbed:focus + label:after {
  border-radius: 2px;
  border-color: #5a5a5a;
  background-color: rgba(0, 0, 0, .1);
}

[type=checkbox].filled-in.tabbed:checked:focus + label:after {
  border-radius: 2px;
  background-color: #26a69a;
  border-color: #26a69a;
}

[type=checkbox].filled-in:disabled:not(:checked) + label:before {
  background-color: transparent;
  border: 2px solid transparent;
}

[type=checkbox].filled-in:disabled:not(:checked) + label:after {
  border-color: transparent;
  background-color: #bdbdbd;
}

[type=checkbox].filled-in:disabled:checked + label:before {
  background-color: transparent;
}

[type=checkbox].filled-in:disabled:checked + label:after {
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}

[type=radio]:checked[class*=material-inputs],
[type=radio]:not(:checked)[class*=material-inputs] {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

[type=radio]:checked + label,
[type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  line-height: 21px;
  font-size: 1rem;
  transition: .28s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[type=radio] + label:after,
[type=radio] + label:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  transition: .28s ease;
}

[type=radio].with-gap:checked + label:after,
[type=radio].with-gap:checked + label:before,
[type=radio]:checked + label:after,
[type=radio]:checked + label:before,
[type=radio]:not(:checked) + label:after,
[type=radio]:not(:checked) + label:before {
  border-radius: 50%;
}

[type=radio]:not(:checked) + label:after,
[type=radio]:not(:checked) + label:before {
  border: 2px solid #5a5a5a;
}

[type=radio]:not(:checked) + label:after {
  z-index: -1;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type=radio]:checked + label:before {
  border: 2px solid transparent;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap:checked + label:after,
[type=radio].with-gap:checked + label:before,
[type=radio]:checked + label:after {
  border: 2px solid #26a69a;
}

[type=radio].with-gap:checked + label:after,
[type=radio]:checked + label:after {
  background-color: #26a69a;
  z-index: 0;
}

[type=radio]:checked + label:after {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

[type=radio].with-gap:checked + label:after {
  -webkit-transform: scale(.5);
  transform: scale(.5);
}

[type=radio].tabbed:focus + label:before {
  box-shadow: 0 0 0 10px rgba(0, 0, 0, .1);
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap:disabled:checked + label:before {
  border: 2px solid rgba(0, 0, 0, .26);
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap:disabled:checked + label:after {
  border: none;
  background-color: rgba(0, 0, 0, .26);
}

[type=radio]:disabled:checked + label:before,
[type=radio]:disabled:not(:checked) + label:before {
  background-color: transparent;
  border-color: rgba(0, 0, 0, .26);
  animation: ripple .2s linear forwards;
}

[type=radio]:disabled + label {
  color: rgba(0, 0, 0, .26);
}

[type=radio]:disabled:not(:checked) + label:before {
  border-color: rgba(0, 0, 0, .26);
}

[type=radio]:disabled:checked + label:after {
  background-color: rgba(0, 0, 0, .26);
  border-color: #bdbdbd;
}

[type=checkbox] + label {
  padding-left: 26px;
  height: 16px;
  line-height: 21px;
  font-weight: 400;
}

[type=checkbox]:checked + label:before {
  top: -4px;
  left: -2px;
  width: 11px;
  height: 19px;
}

[type=checkbox]:checked.chk-col-red + label:before {
  border-right: 2px solid #fb3a3a;
  border-bottom: 2px solid #fb3a3a;
}

[type=checkbox]:checked.chk-col-pink + label:before {
  border-right: 2px solid #e91e63;
  border-bottom: 2px solid #e91e63;
}

[type=checkbox]:checked.chk-col-purple + label:before {
  border-right: 2px solid #7460ee;
  border-bottom: 2px solid #7460ee;
}

[type=checkbox]:checked.chk-col-deep-purple + label:before {
  border-right: 2px solid #673ab7;
  border-bottom: 2px solid #673ab7;
}

[type=checkbox]:checked.chk-col-indigo + label:before {
  border-right: 2px solid #3f51b5;
  border-bottom: 2px solid #3f51b5;
}

[type=checkbox]:checked.chk-col-blue + label:before {
  border-right: 2px solid #02bec9;
  border-bottom: 2px solid #02bec9;
}

[type=checkbox]:checked.chk-col-light-blue + label:before {
  border-right: 2px solid #03a9f4;
  border-bottom: 2px solid #03a9f4;
}

[type=checkbox]:checked.chk-col-cyan + label:before {
  border-right: 2px solid #00bcd4;
  border-bottom: 2px solid #00bcd4;
}

[type=checkbox]:checked.chk-col-teal + label:before {
  border-right: 2px solid #009688;
  border-bottom: 2px solid #009688;
}

[type=checkbox]:checked.chk-col-green + label:before {
  border-right: 2px solid #26c6da;
  border-bottom: 2px solid #26c6da;
}

[type=checkbox]:checked.chk-col-light-green + label:before {
  border-right: 2px solid #8bc34a;
  border-bottom: 2px solid #8bc34a;
}

[type=checkbox]:checked.chk-col-lime + label:before {
  border-right: 2px solid #cddc39;
  border-bottom: 2px solid #cddc39;
}

[type=checkbox]:checked.chk-col-yellow + label:before {
  border-right: 2px solid #ffe821;
  border-bottom: 2px solid #ffe821;
}

[type=checkbox]:checked.chk-col-amber + label:before {
  border-right: 2px solid #ffc107;
  border-bottom: 2px solid #ffc107;
}

[type=checkbox]:checked.chk-col-orange + label:before {
  border-right: 2px solid #ff9800;
  border-bottom: 2px solid #ff9800;
}

[type=checkbox]:checked.chk-col-deep-orange + label:before {
  border-right: 2px solid #ff5722;
  border-bottom: 2px solid #ff5722;
}

[type=checkbox]:checked.chk-col-brown + label:before {
  border-right: 2px solid #795548;
  border-bottom: 2px solid #795548;
}

[type=checkbox]:checked.chk-col-grey + label:before {
  border-right: 2px solid #9e9e9e;
  border-bottom: 2px solid #9e9e9e;
}

[type=checkbox]:checked.chk-col-blue-grey + label:before {
  border-right: 2px solid #607d8b;
  border-bottom: 2px solid #607d8b;
}

[type=checkbox]:checked.chk-col-black + label:before {
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
}

[type=checkbox]:checked.chk-col-white + label:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

[type=checkbox].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #26a69a;
  background-color: #26a69a;
  z-index: 0;
}

[type=checkbox].filled-in:checked + label:before {
  border-right: 2px solid #fff !important;
  border-bottom: 2px solid #fff !important;
}

[type=checkbox].filled-in:checked.chk-col-red + label:after {
  border: 2px solid #fb3a3a;
  background-color: #fb3a3a;
}

[type=checkbox].filled-in:checked.chk-col-pink + label:after {
  border: 2px solid #e91e63;
  background-color: #e91e63;
}

[type=checkbox].filled-in:checked.chk-col-purple + label:after {
  border: 2px solid #7460ee;
  background-color: #7460ee;
}

[type=checkbox].filled-in:checked.chk-col-deep-purple + label:after {
  border: 2px solid #673ab7;
  background-color: #673ab7;
}

[type=checkbox].filled-in:checked.chk-col-indigo + label:after {
  border: 2px solid #3f51b5;
  background-color: #3f51b5;
}

[type=checkbox].filled-in:checked.chk-col-blue + label:after {
  border: 2px solid #02bec9;
  background-color: #02bec9;
}

[type=checkbox].filled-in:checked.chk-col-light-blue + label:after {
  border: 2px solid #03a9f4;
  background-color: #03a9f4;
}

[type=checkbox].filled-in:checked.chk-col-cyan + label:after {
  border: 2px solid #00bcd4;
  background-color: #00bcd4;
}

[type=checkbox].filled-in:checked.chk-col-teal + label:after {
  border: 2px solid #009688;
  background-color: #009688;
}

[type=checkbox].filled-in:checked.chk-col-green + label:after {
  border: 2px solid #26c6da;
  background-color: #26c6da;
}

[type=checkbox].filled-in:checked.chk-col-light-green + label:after {
  border: 2px solid #8bc34a;
  background-color: #8bc34a;
}

[type=checkbox].filled-in:checked.chk-col-lime + label:after {
  border: 2px solid #cddc39;
  background-color: #cddc39;
}

[type=checkbox].filled-in:checked.chk-col-yellow + label:after {
  border: 2px solid #ffe821;
  background-color: #ffe821;
}

[type=checkbox].filled-in:checked.chk-col-amber + label:after {
  border: 2px solid #ffc107;
  background-color: #ffc107;
}

[type=checkbox].filled-in:checked.chk-col-orange + label:after {
  border: 2px solid #ff9800;
  background-color: #ff9800;
}

[type=checkbox].filled-in:checked.chk-col-deep-orange + label:after {
  border: 2px solid #ff5722;
  background-color: #ff5722;
}

[type=checkbox].filled-in:checked.chk-col-brown + label:after {
  border: 2px solid #795548;
  background-color: #795548;
}

[type=checkbox].filled-in:checked.chk-col-grey + label:after {
  border: 2px solid #9e9e9e;
  background-color: #9e9e9e;
}

[type=checkbox].filled-in:checked.chk-col-blue-grey + label:after {
  border: 2px solid #607d8b;
  background-color: #607d8b;
}

[type=checkbox].filled-in:checked.chk-col-black + label:after {
  border: 2px solid #000;
  background-color: #000;
}

[type=checkbox].filled-in:checked.chk-col-white + label:after {
  border: 2px solid #fff;
  background-color: #fff;
}

[type=radio]:not(:checked) + label {
  padding-left: 26px;
  height: 22px;
  line-height: 22px;
  font-weight: 400;
}

[type=radio]:checked + label {
  padding-left: 26px;
  height: 22px;
  line-height: 22px;
  font-weight: 400;
}

[type=radio].radio-col-red:checked + label:after {
  background-color: #fb3a3a;
  border-color: #fb3a3a;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-pink:checked + label:after {
  background-color: #e91e63;
  border-color: #e91e63;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-purple:checked + label:after {
  background-color: #7460ee;
  border-color: #7460ee;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-deep-purple:checked + label:after {
  background-color: #673ab7;
  border-color: #673ab7;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-indigo:checked + label:after {
  background-color: #3f51b5;
  border-color: #3f51b5;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-blue:checked + label:after {
  background-color: #02bec9;
  border-color: #02bec9;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-light-blue:checked + label:after {
  background-color: #03a9f4;
  border-color: #03a9f4;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-cyan:checked + label:after {
  background-color: #00bcd4;
  border-color: #00bcd4;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-teal:checked + label:after {
  background-color: #009688;
  border-color: #009688;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-green:checked + label:after {
  background-color: #26c6da;
  border-color: #26c6da;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-light-green:checked + label:after {
  background-color: #8bc34a;
  border-color: #8bc34a;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-lime:checked + label:after {
  background-color: #cddc39;
  border-color: #cddc39;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-yellow:checked + label:after {
  background-color: #ffe821;
  border-color: #ffe821;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-amber:checked + label:after {
  background-color: #ffc107;
  border-color: #ffc107;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-orange:checked + label:after {
  background-color: #ff9800;
  border-color: #ff9800;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-deep-orange:checked + label:after {
  background-color: #ff5722;
  border-color: #ff5722;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-brown:checked + label:after {
  background-color: #795548;
  border-color: #795548;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-grey:checked + label:after {
  background-color: #9e9e9e;
  border-color: #9e9e9e;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-blue-grey:checked + label:after {
  background-color: #607d8b;
  border-color: #607d8b;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-black:checked + label:after {
  background-color: #000;
  border-color: #000;
  animation: ripple .2s linear forwards;
}

[type=radio].radio-col-white:checked + label:after {
  background-color: #fff;
  border-color: #fff;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-red:checked + label:before {
  border: 2px solid #fb3a3a;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-red:checked + label:after {
  background-color: #fb3a3a;
  border: 2px solid #fb3a3a;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-pink:checked + label:before {
  border: 2px solid #e91e63;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-pink:checked + label:after {
  background-color: #e91e63;
  border: 2px solid #e91e63;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-purple:checked + label:before {
  border: 2px solid #7460ee;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-purple:checked + label:after {
  background-color: #7460ee;
  border: 2px solid #7460ee;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-deep-purple:checked + label:before {
  border: 2px solid #673ab7;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-deep-purple:checked + label:after {
  background-color: #673ab7;
  border: 2px solid #673ab7;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-indigo:checked + label:before {
  border: 2px solid #3f51b5;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-indigo:checked + label:after {
  background-color: #3f51b5;
  border: 2px solid #3f51b5;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-blue:checked + label:before {
  border: 2px solid #02bec9;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-blue:checked + label:after {
  background-color: #02bec9;
  border: 2px solid #02bec9;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-light-blue:checked + label:before {
  border: 2px solid #03a9f4;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-light-blue:checked + label:after {
  background-color: #03a9f4;
  border: 2px solid #03a9f4;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-cyan:checked + label:before {
  border: 2px solid #00bcd4;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-cyan:checked + label:after {
  background-color: #00bcd4;
  border: 2px solid #00bcd4;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-teal:checked + label:before {
  border: 2px solid #009688;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-teal:checked + label:after {
  background-color: #009688;
  border: 2px solid #009688;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-green:checked + label:before {
  border: 2px solid #26c6da;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-green:checked + label:after {
  background-color: #26c6da;
  border: 2px solid #26c6da;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-light-green:checked + label:before {
  border: 2px solid #8bc34a;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-light-green:checked + label:after {
  background-color: #8bc34a;
  border: 2px solid #8bc34a;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-lime:checked + label:before {
  border: 2px solid #cddc39;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-lime:checked + label:after {
  background-color: #cddc39;
  border: 2px solid #cddc39;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-yellow:checked + label:before {
  border: 2px solid #ffe821;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-yellow:checked + label:after {
  background-color: #ffe821;
  border: 2px solid #ffe821;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-amber:checked + label:before {
  border: 2px solid #ffc107;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-amber:checked + label:after {
  background-color: #ffc107;
  border: 2px solid #ffc107;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-orange:checked + label:before {
  border: 2px solid #ff9800;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-orange:checked + label:after {
  background-color: #ff9800;
  border: 2px solid #ff9800;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-deep-orange:checked + label:before {
  border: 2px solid #ff5722;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-deep-orange:checked + label:after {
  background-color: #ff5722;
  border: 2px solid #ff5722;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-brown:checked + label:before {
  border: 2px solid #795548;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-brown:checked + label:after {
  background-color: #795548;
  border: 2px solid #795548;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-grey:checked + label:before {
  border: 2px solid #9e9e9e;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-grey:checked + label:after {
  background-color: #9e9e9e;
  border: 2px solid #9e9e9e;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-blue-grey:checked + label:before {
  border: 2px solid #607d8b;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-blue-grey:checked + label:after {
  background-color: #607d8b;
  border: 2px solid #607d8b;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-black:checked + label:before {
  border: 2px solid #000;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-black:checked + label:after {
  background-color: #000;
  border: 2px solid #000;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-white:checked + label:before {
  border: 2px solid #fff;
  animation: ripple .2s linear forwards;
}

[type=radio].with-gap.radio-col-white:checked + label:after {
  background-color: #fff;
  border: 2px solid #fff;
  animation: ripple .2s linear forwards;
}
