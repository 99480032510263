body {
  background: #f0f2f5;
}
.header {
  display: flex;
  justify-content: space-between;
  color: wheat;
}
.header_left {
  display: flex;
  justify-content: space-between;
}
#logo img {
  position: relative;
  height: 34px;
  margin-right: 16px;
}
.ant-switch {
  background-color: rgba(100, 100, 100, 0.85);
}
.ant-switch.ant-switch-checked {
  background-color: #1890ff;
}

.slider_container {
  /* width: 1000px; */
  margin: 0 auto;
}

.ant-carousel .slick-slide {
  text-align: center;
  min-height: 600px;
  line-height: 16px;
  background: #f9f9f9;
  /* background: #364d79; */
  overflow: hidden;
  /* padding: 10px 20px; */
  /* margin: 0 -10px; */
  width: 100%;
}
.ant-carousel .slick-slide img {
  /* max-width: 900px; */
}
.ant-carousel .slick-slide h3 {
  color: #01c0c8; /*#1e88e5;*/
}
.slick-dots li {
  background: #364d79;
  /* height: 20px !important; */
}

td {
  padding: 10px;
}

.ant-progress-line {
  margin-bottom: 8px;
}

.ant-carousel .slick-slide {
  text-align: unset;
}

.accordion img {
  max-width: 100%;
}
.ant-layout {
  background-color: transparent;
}
.ant-layout-sider {
  background-color: #fff;
}
.ppt img {
  width: 100%;
}

.youtubeContainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  margin-bottom: 50px;
}

.youtubeContainer iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.yi-Ul {
  list-style: none;
  padding-left: 10px;
}
.ant-tag {
  cursor: pointer;
  margin-top: 8px;
  font-size: 14px;padding: 2px 7px;
}
.anticon {
  vertical-align: 0;
}
blockquote {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid #1890ff;
  display: inline-block;
  font-style: italic;
  font-size: 110%;
  padding: 12px 14px;
  margin-bottom: 15px;
  cursor: pointer;
  margin: 0 0 5px 0;
  position: relative;
  transition: font 0.3s ease;
  color: #000000;
}
blockquote h3 {
  color: #455a64;
  transition: color 0.3s ease;
}
blockquote::before {
  content: '';
  position: absolute;
  display: block;
  width: 0%;
  height: 0%;
  border: 1px solid #1890ff;

  top: 30%;
  left: 50%;
  /* margin: -1rem; */
  background-color: transparent;
  /* transition-property: width, height, top, left, background-image;
  transition-duration: 0.4s;
  transition: font 0.3s ease; */
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  z-index: 1;
  opacity: 0.0;
}

blockquote.isHighlighted {
  color: #000000;
  font-weight: bold;
  font-size: 125%;
  font-style: normal;
}
blockquote.isHighlighted h3 {
  color: #1890ff;
}
blockquote.isHighlighted::before {
  /* border-radius: 20%; */
  background-color: #49aaa0;
  border: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.4;
  background-image: linear-gradient(45deg, #8eb335 28%, #40a499 72%);
}

#withBlackGround {
  /* background: url(/ppt/bg1.jpg)no-repeat; */
  /* width: 100%;
	height: 100%; */
  background-size: 100% 100%;
  /* position: fixed; */
}
#withBlackGround.BGImage1 {
  background-image: url(/ppt/bg1.jpg);
}
#withBlackGround.BGImage2 {
  background-image: url(/ppt/bg2.jpg);
}
#withBlackGround.BGImage3 {
  background-image: url(/ppt/bg3.jpg);
}
#withBlackGround.BGImage4 {
  background-image: url(/images/car-long-road-field-sky-clouds.jpg);
}
#withBlackGround.BGImage5 {
  background-image: url(/ppt/bg5.jpg);
}
#withBlackGround.BGImage6 {
  background-image: url(/ppt/bg6.jpg);
}
#withBlackGround.BGImage7 {
  background-image: url(/ppt/bg7.jpg);
}
#withBlackGround.BGImage8 {
  background-image: url(/ppt/bg8.jpg);
}
#withBlackGround.BGImage9 {
  background-image: url(/ppt/bg9.jpg);
}
#withBlackGround.BGImage10 {
  background-image: url(/ppt/bg10.jpg);
}
#withBlackGround.BGImage11 {
  background-image: url(/ppt/bg11.jpg);
}


.popup_image {
  max-width: 1700px;
  /* max-height: 800px; */
  width: 100%;
}
.scroll_master {
  position: fixed;
  top: 66px;
  z-index: 99;
}
.ant-modal {
  top: 30px;
}
/* @media only screen and (max-width: 768px) { */
.main-content {
  padding: 0 10px;
  height: 90vh;
}
.main-content__left {
  padding: 0 0 0 1%;
  /* min-height: 480px; */
}
/* } */
@media only screen and (min-width: 1024px) {
  .main-content {
    padding: 0 50px;
    /* min-height: 780px; */
  }
  .main-content__left {
    margin: 0 0 0 3%;
    background: #fff;
  }
}

.ant-layout-header {
  padding: 0 10px 0 30px;
}
.centered_image {
  border: 1px solid #ccc;
  display: flex;
  margin: 0 auto;
}
.current_focus {
  text-decoration: underline;
  -webkit-text-decoration-color: salmon;
  text-decoration-color: salmon;
  -webkit-text-decoration-style: wavy;
  text-decoration-style: wavy;
  text-underline-position: under;
  background: #fff6ea;
  padding-bottom: 4px;
  color: rgb(85, 172, 238);
  font-size: 120%;
}

.ws_ul {
  cursor: pointer;
}
.ws_ul li {
  padding: 4px 0;
}
.ant-layout-footer {
  padding: 14px 50px;
}
.main_footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #464547;
  color: white;
}
.centet_block {
  text-align: center;
  margin: 0px auto 12px;
}
.ant-card {
  width: 100%;
}
.sent_mess_box {
  padding-bottom: 50px;
}
.ant-notification-notice-message {
  font-size: 14px;
}
.ant-notification-notice-description {
  font-size: 16px;
  background-color: #f6ffed;
    border: 1px solid #b7eb8f;
    padding: 8px 15px;border-radius: 4px;margin-left:0 !important;
    margin-top: 8px;;
}